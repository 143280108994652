<section class="fixed-margin-top-booking">
  <div class="container-fluid">
      <!-- <div class="form-group form-banner w-50 mb-4 mx-auto" >
        <div class="input-group mt-2">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 input-left-group opacity">
              <img src="../../assets/search.svg" width="30px" alt="Image">
            </span>
          </div>
          <input type="text" class="form-control input-right-group w-50"
            placeholder="Search Activities, Locations" [(ngModel)]="location" name="location"
            (keyup.enter)="onLocationChange()">
        </div>
    </div> -->
    <!-- <mat-chip-listbox class="mt-4">
      <mat-chip-option routerLink="ProfileEdit" routerLinkActive="active" [selected]="isRouteActive('/ProfileEdit')"
        [ngClass]="{'selected-chip': isRouteActive('/ProfileEdit')}">Account</mat-chip-option>
      <mat-chip-option routerLink="Bookings" routerLinkActive="active" [selected]="isRouteActive('/Bookings')"
        [ngClass]="{'selected-chip': isRouteActive('/Bookings')}">Bookings</mat-chip-option>
    </mat-chip-listbox> -->
  </div>

  <router-outlet></router-outlet>

</section>