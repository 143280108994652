import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent {
  @ViewChild("nameInput") nameInput!: ElementRef<HTMLElement>;;
  data: any;
  id!: number;
  email: string = '';
  firstname: string = '';
  lastname: string = '';
  phone: string = '';
  password: string | undefined;
  isSubmitting: boolean | undefined;
  currentUserID: number | null = null;
  inputsEnabled: boolean = false;
  updateProfileImage: boolean = false;
  profileSrc: string = "";
  profileUploadId: string = "";

  constructor(private http: HttpClient, private authService: AuthService, private snackBar: MatSnackBar, private router: Router) {
    this.authService.userInfo.subscribe(info=>{
      if(info === null){
        this.router.navigate(['/404-not-found']);
      }
    })
    this.authService.UserType.subscribe(type=>{
      if(type !== "user"){
        this.router.navigate(['/404-not-found']);
      }
    })
   }

  ngOnInit() {
    this.authService.userInfo.subscribe(data => {
      this.authService.getDataById(data.id).subscribe((userData: any) => {
        this.firstname = userData.users.firstname;
        this.lastname = userData.users.lastname;
        this.email = userData.users.email;
        this.phone = userData.users.phone
        this.id = userData.users.id;
        this.nameInput.nativeElement.focus();
        this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${this.id}`).subscribe((data: any) => {
          if (data.status === "SUCCESS" && data.uploads.length > 0) {
            let uploadid = data.uploads[0].id;
            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${uploadid}`).subscribe((value: any) => {
              if(value.status === "SUCCESS"){
                this.updateProfileImage = true;
                this.profileUploadId = uploadid;
                this.profileSrc = value.upload.thumbnail;
              }
            })
          }
        });
      })
    });
  }



  onSubmit() {
    let data = {
      id: this.id,
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
      phone: this.phone
    }
    this.authService.update(data).subscribe({
      next: (res: any) => {
        // this.authService.userInfo.next(data);
        this.isSubmitting = false;
        this.snackBar.open('Submitted Successfully', 'close', { duration: 3000, verticalPosition: 'top' });
        this.inputsEnabled = false;
      },
      error: (err: any) => {
        this.isSubmitting = false;
        this.snackBar.open('Some error occurs. '+ JSON.stringify(err.error.errors), 'Dismiss', { duration: 3000, verticalPosition: 'top' });
      }

    });

  }

  handleProfileChange(event: any): void {
    const file = event.target.files[0];
    let reader = new FileReader();

    if(reader["__zone_symbol__originalInstance"]) {
      reader = reader["__zone_symbol__originalInstance"];
    }

    reader.onload = () => {
      const base64String = reader.result as string;
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx:any = canvas.getContext('2d');
        const maxWidth = 2400;
        const maxHeight = 1800;
        const thumbnailSize = 350;
  
        let width = img.width;
        let height = img.height;
  
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
  
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
  
        canvas.width = width;
        canvas.height = height;
  
        ctx.drawImage(img, 0, 0, width, height);
  
        const compressedBase64String = canvas.toDataURL('image/jpeg', 1); // Adjust quality as needed

      // Create thumbnail
      const thumbnailCanvas = document.createElement('canvas');
      const thumbnailCtx:any = thumbnailCanvas.getContext('2d');

      thumbnailCanvas.width = thumbnailSize;
      thumbnailCanvas.height = thumbnailSize;

      thumbnailCtx.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);

      const thumbnailBase64String = thumbnailCanvas.toDataURL('image/jpeg', 1); 
      if (this.updateProfileImage) {
        let data = { id: this.profileUploadId, updatedBy: this.id, base64Data: base64String,thumbnail: thumbnailBase64String };
        this.http.post(environment.apiUrl + `/uploads/update`, data, { responseType: 'json' }).subscribe(async (data: any) => {
          if (data.status === "SUCCESS") {
            this.profileSrc = base64String;
            this.snackBar.open('Profile Image Updated successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
          } else {
            this.snackBar.open('Some error occured.', 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        });
      } else {
        let data = { createdBy: this.id, dataType: 'image', dataFor: 'userprofile', dataForId: this.id, data: base64String, thumbnail: thumbnailBase64String };
        this.http.post(environment.apiUrl + `/uploads/upload`, data, { responseType: 'json' }).subscribe(async (data: any) => {
          if (data.status === "SUCCESS") {
            this.profileSrc = base64String;
            this.snackBar.open('Profile Image added successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
          } else {
            this.snackBar.open('Some error occured.', 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        });
      }
    };
    img.src = reader.result as string;
  };
    reader.readAsDataURL(file);
  }


}
