<section class=" fixed-margin-top">
  <div class="  px-2 mx-3">
    <div class="mobile-screen  py-3">
      <div class="wrapper landing-carousel-height mx-auto ">
        <img src="/assets/boatBG.png" alt="image" class="image-src ">
        <h1 class="title position-absolute ">Advenzone</h1>
        <a href="javascript:void(0)" (click)="openLocation()"
          class="btn btn-success position-absolute landing-explore px-md-4 py-md-2 content">Explore
          <img src="/assets/explore-arrow.svg" width="20px" alt="explore"></a>
      </div>
    </div>

    <div class="row mt-md-5 mt-3">
      <div class="col-md-6 offset-md-1">
        <h1 class="name mb-1">Who are We</h1>
        <p class="content mb-1 text-justify">
          Known as the high-tech hub of India, Bangalore boasts a fast-paced life. But, there's a world of fun waiting
          for
          you, where you can take a step back, relax and enjoy the present that life is! trap in, for you are in for a
          wild ride brimming with joy and crazy happiness, with the 60+ rides at Wonderla Bengaluru!
        </p>
        <p class="content text-justify">
          Fun from the get-go is what we like and that's exactly what we deliver. The moment you step in, you will feel
          like you're in a whole other dimension. It's almost like paradise - all laughter, merrymaking and thrills! Get
          your dopamine level checker, coz it's gonna be FUN all day!
        </p>
      </div>
      <div class="col-md-5 p-2 justify-content-md-center d-md-flex">
        <img src="/assets/boatBG.png" alt="image" class="image-size">
      </div>
    </div>

    <div class="row mt-md-5 mt-3 choose-content">
      <div class="col-md-6">
        <div class="d-flex justify-content-center">
          <div class="d-flex align-items-start flex-column me-md-4 me-3">
            <img src="/assets/boatBG.png" alt="image" class="image">
            <img src="/assets/Rectangle.png" alt="image" class="image my-3">
          </div>
          <div class="d-flex align-items-start flex-column mt-4">
            <img src="/assets/manaliBg.png" alt="image" class="image">
            <img src="/assets/sample-6.png" alt="image" class="image my-3">
          </div>
        </div>
      </div>
      <div class="col-md-5 ">
        <h1 class="name mb-1">Why Choose us</h1>
        <p class="content text-justify">
          Known as the high-tech hub of India, Bangalore boasts a fast-paced life. But, there's a world of fun waiting
          for you, where you can take a step back, relax and enjoy the present that life is! trap in, for you are in for
          a wild ride brimming with joy and crazy happiness, with the 60+ rides at Wonderla Bengaluru
        </p>
        <p class="content text-justify">
          Fun from the get-go is what we like and that's exactly what we deliver. The moment you step in, you will feel
          like you're in a whole other dimension. It's almost like paradise - all laughter, merrymaking and thrills! Get
          your dopamine level checker, coz it's gonna be FUN all day!
        </p>
        <a href="javascript:void(0)" class="btn btn-success explore px-4 py-2 my-2" (click)="openLocation()">Explore
          <img src="/assets/explore-arrow.svg" width="20px" alt="explore"></a>
      </div>
      <div class="offset-md-1"></div>
    </div>

    <div class="mt-5 pt-4 pt-md-0">
      <h1 class="name d-flex justify-content-center">Easy Bookings</h1>
      <div class="row  px-md-4  px-2 activity-background mb-3 mx-auto ">
        <div class="col-md-4 mt-3 mt-md-0 text-center position-relative px-md-2 px-0">
          <div class="landing-activity">
            <img src="/assets/landing-rafting.svg" alt="landing-rafting" class="landing-icon">
          </div>
          <h3 class="activity-title mb-md-2 mb-1 ">Choose Activity</h3>
          <p class="px-md-4 px-5 activity-content">Fun from the get-go is what we like and that's exactly what we
            deliver.</p>
        </div>
        <div class="col-md-4 mt-3 mt-md-0 text-center position-relative px-md-2 px-0">
          <div class="landing-activity">
            <img src="/assets/landing-merchant.svg" alt="landing-merchant" class="landing-icon">
          </div>
          <h3 class="activity-title mb-md-2 mb-1">Choose Merchant</h3>
          <p class="px-md-4 px-5 activity-content">Fun from the get-go is what we like and that's exactly what we
            deliver.</p>
        </div>
        <div class="col-md-4 mt-3 mt-md-0 text-center position-relative px-md-2 px-0">
          <div class="landing-activity">
            <img src="/assets/landing-booking.svg" alt="landing-booking" class="landing-icon">
          </div>
          <h3 class="activity-title mb-md-2 mb-1">Book</h3>
          <p class="px-md-4 px-5  activity-content">Fun from the get-go is what we like and that's exactly what we
            deliver.</p>
        </div>
      </div>
    </div>
  </div>

    <div class="mt-md-5 mt-3">
      <h1 class="name d-flex justify-content-center ">Testimonials</h1>
      <div id="popular-spots-lists">
        <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt,'mouse',child, '')"
          (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
          (mousemove)="moveEvent($event, elemt, 'mouse', child)" (scroll)="onScroll($event,elemt, child)">
          <div class="child d-flex" #child>
            <div class="card landing-content ms-3 border-0" *ngFor="let review of  reviews,let i= index">
              <div class="card-body p-2">
                <div class="container px-2">
                  <p class="review-content">{{review.review}}</p>
                  <div class="landing-profile d-flex">
                    <img src="/assets/default-profile-pic.svg" *ngIf="!review.src" alt="landing-rafting"
                      class="rounded-circle me-2">
                    <img [src]="review.src" *ngIf="review.src" alt="landing-rafting" class="rounded-circle me-2">
                    <div class="mt-2 d-flex">
                      <ng-template #t let-fill="fill">
                        <span [style.color]="fill === 100 ? 'orange' : 'gray'">&#9733;</span>
                      </ng-template>
                      <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [(rate)]="review.star"></ngb-rating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-md-5 mt-3 mx-3">
      <div class="col-md-10 mx-auto px-md-3">
        <h1 class="name mb-1">Frequently Asked Questions (FAQs)</h1>
        <section class="mt-3">
          <div class="content  mx-2 ">
            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question">How does the booking process work?</h3>
              </div>
              <div class="col-2 mt-2 pointer  text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(0)" *ngIf="visibilityStates[0]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(0)" *ngIf="!visibilityStates[0]">
              </div>
            </div>
            <ul *ngIf="visibilityStates[0]">
              <li>Browse our website and select your desired adventure activity.</li>
              <li>Review the available options, including dates, times, and prices.
              </li>
              <li>Click on the "Book Now" button and follow the prompts to complete your booking.
              </li>
              <li>Make the required payment to secure your reservation.</li>
              <li>Once your booking is confirmed, you'll receive a confirmation email with all the details. </li>
            </ul>

            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question">What happens if I need to cancel or change my booking?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(1)" *ngIf="visibilityStates[1]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(1)" *ngIf="!visibilityStates[1]">
              </div>
            </div>
            <p *ngIf="visibilityStates[1]" class="text-justify">Cancellation and change policies vary depending on the
              specific adventure
              activity and the vendor. Please refer to the cancellation policy provided during the booking process or in
              the Terms and Conditions on our website. If you need assistance, reach out to our customer support team,
              and we'll guide you through the process.</p>

            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question">How do I pay for my adventure activity?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(2)" *ngIf="visibilityStates[2]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(2)" *ngIf="!visibilityStates[2]">
              </div>
            </div>
            <p *ngIf="visibilityStates[2]" class="text-justify">When booking through our website, you'll be prompted to
              make a payment for a
              percentage of the total cost to secure your booking. The remaining amount is usually paid directly to the
              vendor either before or after the activity, as specified by the vendor. Payment methods and details will
              be provided during the booking process.</p>

            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question">Are the adventure activities safe?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(3)" *ngIf="visibilityStates[3]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(3)" *ngIf="!visibilityStates[3]">
              </div>
            </div>
            <p *ngIf="visibilityStates[3]" class="text-justify">We prioritize your safety and work with trusted vendors
              who maintain high
              safety standards. Our partner vendors are experienced professionals who follow strict safety protocols and
              regulations. However, adventure activities inherently carry some risks. It's important to carefully read
              and follow the safety guidelines provided by the vendor and ensure that you meet any specified
              requirements or restrictions.</p>

            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question">What should I bring or wear for the adventure activities?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(4)" *ngIf="visibilityStates[4]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(4)" *ngIf="!visibilityStates[4]">
              </div>
            </div>
            <p *ngIf="visibilityStates[4]" class="text-justify">Each adventure activity may have specific
              recommendations or requirements.
              It's best to refer to the activity description or contact the vendor directly for detailed information. In
              general, it's advisable to wear comfortable and appropriate clothing, closed-toe shoes, and bring any
              necessary equipment specified by the vendor. Don't forget essentials like sunscreen, water, and a camera
              to capture your unforgettable moments!
            </p>

            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question" class="content-question">Can I book activities for a group or special
                  events?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(5)" *ngIf="visibilityStates[5]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(5)" *ngIf="!visibilityStates[5]">
              </div>
            </div>
            <p *ngIf="visibilityStates[5]" class="text-justify">Absolutely! Our platform allows you to book activities
              for groups, special
              occasions, team-building events, or other gatherings. During the booking process, you'll have the
              opportunity to indicate the number of participants or inquire about group packages. If you require any
              additional assistance or have specific requests, please reach out to our customer support team, and we'll
              be happy to assist you.
            </p>

            <div class="d-flex">
              <div class="col-10">
                <h3 class="content-question">How can I contact customer support?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  (click)="toggleContent(6)" *ngIf="visibilityStates[6]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                  (click)="toggleContent(6)" *ngIf="!visibilityStates[6]">
              </div>
            </div>
            <p *ngIf="visibilityStates[6]" class="text-justify">If you have any questions, concerns, or need assistance,
              our dedicated
              customer support team is here to help. You can reach us through the contact information provided on our
              website or by using the messaging system available on your user account. We strive to provide prompt and
              helpful responses to ensure your adventure experience is smooth and enjoyable.</p>
          </div>
        </section>
      </div>
    </div>

    <div class="mt-md-5 mt-3 mx-3">
      <h1 class="name d-flex justify-content-center">Follow us on Social Media</h1>
      <div class="d-flex  justify-content-center">
        <a href="https://www.facebook.com/AdvenzoneApp" target="_blank">
          <img src="/assets/fb.svg" width="52px" alt="fb" class="mx-3 my-2 shadow icon"></a>
        <a href="https://www.instagram.com/advenzone/" target="_blank">
          <img src="/assets/instagram.svg" width="52px" alt="instagram" class="mx-3 my-2 shadow icon"></a>
        <a href="https://www.linkedin.com/company/advenzone/" target="_blank">
          <img src="/assets/linkedIn.svg" width="52px" alt="linkedIn" class="mx-3 my-2 shadow icon"></a>
      </div>
    </div>

  <div class="row mt-md-5 mt-3 ">
    <div class="col-md-6 my-md-3 px-md-0 px-3">
      <div class="d-flex justify-content-md-end justify-content-center">
        <div class=" me-2">
          <img src="/assets/boatBG.png" alt="image" class="image-height">
        </div>
        <div class=" d-flex align-items-start flex-column">
          <img src="/assets/sample-6.png" alt="image" class="image-fit">
          <img src="/assets/Baga-Beach.png" alt="image" class="image-fit  mt-2">
        </div>
      </div>
      <div class="d-flex justify-content-md-end my-2 justify-content-center">
        <div class="d-flex align-items-start pe-2">
          <img src="/assets/manaliBg.png" alt="image" class="image-fit ps-md-2">
        </div>
        <div class="d-flex justify-content-end">
          <img src="/assets/Rectangle.png" alt="image" class="image-width ">
        </div>
      </div>
    </div>
    <div class="col-md-6 my-md-3 ps-md-2 px-md-0 px-3">
      <div class="d-flex justify-content-md-start justify-content-center">
        <div class=" me-2">
          <img src="/assets/boatBG.png" alt="image" class="image-height">
        </div>
        <div class=" d-flex align-items-start flex-column">
          <img src="/assets/sample-6.png" alt="image" class="image-fit">
          <img src="/assets/Baga-Beach.png" alt="image" class="image-fit  mt-2">
        </div>
      </div>
      <div class="d-flex justify-content-md-start my-2 justify-content-center">
        <div class="d-flex align-items-start pe-2">
          <img src="/assets/manaliBg.png" alt="image" class="image-fit ">
        </div>
        <div class="d-flex justify-content-center ">
          <img src="/assets/Rectangle.png" alt="image" class="image-width pe-2">
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>