<div mat-dialog-content class="confirm-container p-0  pb-0 ">
  <!-- <button type="button" class="btn-close p-4 mt-3 ms-2" (click)="closeSurvey()" aria-label="Close"></button> -->
  <div>
    <div class="container p-4   pb-0">
      <img src="/assets/new-logo.svg" class="header-logo" alt="logo"/>
    </div>

    <h1 mat-dialog-title class="default-font-family title d-flex  justify-content-center   mb-0 pb-1"> AdvenZone 
    </h1>
    <!-- <hr class="mx-0 mt-0 mb-0"> -->
    <div class=" p-3 pt-0 ">
      <div class="mt-2 ">
        <h1 class="default-font-family survey d-flex justify-content-center  mb-0"> User Satisfaction Survey</h1>
        <!-- <p class="name default-font-family d-flex justify-content-center  mb-0">How likely you will recommend us!</p> -->
      </div>
      <div class="border-line  mx-md-4 mx-2"></div>
      <p class="headingOne default-font-family p-md-4 p-2  mb-0 pt-3 pb-md-1">On a scale of 0 to 10, how likely are you to
        recommend Advenzone to your colleague or friend for their next Adventure activity?</p>

      <div class="ratingTable p-2 p-md-4 pt-md-2 pt-4 pb-md-1">
        <div class="column">
          <div class="choiceLabel default-font-family choiceName">Not at all likely</div>
          <div class="choiceLabel default-font-family choiceLast">Extremely Likely</div>
        </div>

        <div class="ratingRow">
          <div class="ratingTableCell likertRadioItem pointer" *ngFor="let value of [0,1,2,3,4,5,6,7,8,9]">
            <label class="radio">
              <input type="radio" class="likertRadioFaux" [value]="value" [checked]="selectedValue === value"
                (change)="onValueSelected(value)">
              <span class="custom-radio" [class.active]="selectedValue === value">
                <span class="ratingValue">{{ value }}</span>
              </span>
            </label>
          </div>
          <div class="ratingTableCell likertRadioItem pointer">
            <label class="radio">
              <input type="radio" class="likertRadioFaux" value="10" [checked]="selectedValue === '10'"
                (change)="onValueSelected('10')">
              <span class="custom-radio" [class.active]="selectedValue === '10'">
                <span class="ratingValue">10</span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="mt-2 py-3  pt-0 pb-0  default-font-family survey d-flex justify-content-center">
        <button class="btn btn-danger  text-light px-sm-5  py-2 fw-bold" (click)="submitFeedback()">Submit</button>
      </div>

    </div>
  </div>
</div>