import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(public http:HttpClient) { }

  getCoupon(locationId:any){
    let currentDate = new Date();
    let Timestamp = currentDate.getTime();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + `/coupons/currentcoupon?currentTimeStamp=${Timestamp}&locationId=${locationId}`)
        .subscribe((data: any) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
    });
  }

  Coupon(locationId:any,merchantId:any,activityId:any){
    let currentDate = new Date();
    let Timestamp = currentDate.getTime();
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + `/coupons/view-coupon-list`,{status:1,locationId, merchantId,activityId ,currentTimeStamp: Timestamp})
        .subscribe((data: any) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
    });
  }
}
