import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-merchant-agreement',
  templateUrl: './merchant-agreement.component.html',
  styleUrls: ['./merchant-agreement.component.css']
})
export class MerchantAgreementComponent {

  vendorName:string ="";
  currentDate:string= this.currentdate();

  constructor( @Inject(MAT_DIALOG_DATA) data: {vendorName:string, }, private merchantAgreementDialogRef: MatDialogRef<MerchantAgreementComponent>) {
    this.vendorName = data.vendorName;
  }

  closeMerchantAgreementDialog(){
    this.merchantAgreementDialogRef.close();
    
  }


  currentdate(){
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    var day = String(currentDate.getDate()).padStart(2, '0');
    
    var formattedDate = year + '-' + month + '-' + day;
    return formattedDate;

  }
}
