export const environment = {
    production: false,
    apiUrl: 'https://api.advenzone.amicodevelopment.net',
    mapbox: {
        accessToken: 'pk.eyJ1IjoiZGhhbmVzaGFtaWNvIiwiYSI6ImNsZjhsMmF1bTBpOWw0NG52eTgxNWRnODEifQ.Hsb9yfUwBVZLq2EfkOfLbw'
      },
    GOOGLE_CLIENT_ID:'189004330207-8ubso558a7ns63388uhgnl9fjjq1621b.apps.googleusercontent.com',
    //dev razor pay credientials
    razor_pay_key_id: 'rzp_test_oGAbdpnTphzePw',
    razor_pay_key_secret: 'XMzO74QzBVGBsZ7DMkFIDUlJ',
    //production razor pay credientials
    // razor_pay_key_id: 'rzp_live_5L9GI3Wje5ShCx',
    // razor_pay_key_secret: 'R16vjiw1XmdKng7ntBt8inR8'  

    //dev wp-category-id uncategorized
    wp_category_id:1
    //production wp-category-id advenzone
    // wp_category_id:2
};
