import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpComponent } from 'src/app/auth/sign-up/sign-up.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-guest-login-dialogue',
  templateUrl: './guest-login-dialogue.component.html',
  styleUrls: ['./guest-login-dialogue.component.css']
})
export class GuestLoginDialogueComponent {

  isLoggedIn: boolean = false;
  isSubmitting: boolean = false;
  guestLoginForm!: FormGroup;
  userAgent: string = "";
  disableGuestButton:boolean = true;
  phoneNumber:any="";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, public guestLoginDialogRef: MatDialogRef<GuestLoginDialogueComponent>, public dialog: MatDialog, private authService: AuthService, private snackBar: MatSnackBar, private router: Router) {
    var raftingDetails = localStorage.getItem('raftingDetails');
    var campingDetails = localStorage.getItem('campingDetails');
    var rentalDetails = localStorage.getItem('rentalDetails');
    var locationDetails = localStorage.getItem('location');
    var linkFrom = localStorage.getItem('linkFrom');
    var appToken = localStorage.getItem('appToken');
    var trekkingDetails = localStorage.getItem('trekkingDetails');
    var tourDetails = localStorage.getItem('tourDetails');
    localStorage.clear();
    if(raftingDetails && raftingDetails != ""){
    localStorage.setItem('raftingDetails',raftingDetails);
    }
    if(campingDetails && campingDetails != ""){
      localStorage.setItem('campingDetails',campingDetails);
      }
    if(rentalDetails && rentalDetails != ""){
      localStorage.setItem('rentalDetails',rentalDetails);
    }
    if(locationDetails && locationDetails != ""){
      localStorage.setItem('location',locationDetails);
    }
    if(linkFrom && linkFrom != ""){
      localStorage.setItem('linkFrom',linkFrom);
    }
    if(trekkingDetails && trekkingDetails != ""){
      localStorage.setItem('trekkingDetails',trekkingDetails);
    }
    if(tourDetails && tourDetails != ""){
      localStorage.setItem('tourDetails',tourDetails);
    }
    localStorage.setItem('appToken', appToken);
  }

  ngOnInit() {
    this.userAgent = window.navigator.userAgent;
    this.guestLoginForm = this.formBuilder.group(
      {
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        phone: new FormControl('', [Validators.required,this.validatePhoneNumber]),
        otp: new FormControl(''),
      });
  }

  onGuestLogin() {
    this.isSubmitting = true;
    if (this.guestLoginForm.invalid) {
      return;
    }
    if (this.guestLoginForm.valid) {
      this.phoneNumber = this.guestLoginForm.value.phone;
      this.http.post(environment.apiUrl + `/users/guestusercreate`, this.guestLoginForm.value, { responseType: 'json' }).pipe(
        catchError((_err: HttpErrorResponse) => {
          let error=[];
          let keys =Object.keys(_err.error.errors);
              keys.forEach(x=>{
                error.push(_err.error.errors[x][0]);
              })
          this.snackBar.open(error.join('\r\n'), 'close', { duration: 3000, verticalPosition: 'top' });
          throw new Error("Error"); 
        })
      ).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
             this.http.post(environment.apiUrl + `/users/phoneSendCode`,{phone:data.users.phone}, { responseType: 'json' }).subscribe((res:any)=>{
              if(res.status === "SUCCESS"){
                this.snackBar.open("OTP has sent to you phone number.", 'close', { duration: 3000, verticalPosition: 'top' });
                this.disableGuestButton= false;
              }
             })
        }}
        )
    }
  }

  
  guestLogin(){
    this.authService.guestLogin({phone:this.phoneNumber,phoneCode:this.guestLoginForm.value.otp}).subscribe({
      next: (res) => {
        this.guestLoginDialogRef.close();
        this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
      },
      error: (err) => {
        let error=[];
        let keys =Object.keys(err.error.errors);
            keys.forEach(x=>{
              error.push(err.error.errors[x][0]);
            })
        this.snackBar.open(error.join('\r\n'), 'close', { duration: 3000, verticalPosition: 'top' });
        }
      })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.guestLoginForm.controls;
  }


  closeGuestLoginDialog() {
    this.guestLoginDialogRef.close();
  }

  openSignInDialog() {
    if (!this.ifMobileApp()) {
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const queryParams = Object.fromEntries(searchParams.entries());
      // let queryParams = this.route.snapshot.queryParams;
      const currentUrl = this.router.url.split('?')[0];
      let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
      q = q !== '' ? q + '&dialogue=signupuser' : q + `dialogue=signupuser`
      window.location.href = `${currentUrl}?${q}`;
    } else {
      this.guestLoginDialogRef.close();
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '90vh',
        width: '50%',
        maxWidth: 'unset',
        panelClass: 'bg-sign-up-class'
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          panelClass: 'bg-sign-up-class'
          }
      }
      this.dialog.open(SignUpComponent, {
        ...sizes,
        disableClose: true,
        data: {

        },
      });
    }
  }

  openLoginDialogue() {
    if (!this.ifMobileApp()) {
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const queryParams = Object.fromEntries(searchParams.entries());
      // let queryParams = this.route.snapshot.queryParams;
      const currentUrl = this.router.url.split('?')[0];
      let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
      q = q !== '' ? q + '&dialogue=user' : q + `dialogue=user`
      window.location.href = `${currentUrl}?${q}`;
    } else {
      this.guestLoginDialogRef.close();
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '90vh',
        width: '50%',
        maxWidth: 'unset',
        panelClass: 'bg-login-class'
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          panelClass: 'bg-login-class'
        }
      }
      this.dialog.open(LoginDialogComponent, {
        ...sizes,
        disableClose: true,
        data: {

        },
      });
    }
  }


  ifMobileApp() {
    if (window.navigator.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  
  validatePhoneNumber(control: any) {
    const phoneNumber = control.value;
    const phoneNumberPattern = /^([6-9]\d{9})$/; // Regular expression for 10-digit number
    if (phoneNumber && !phoneNumber.match(phoneNumberPattern)) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }

}
