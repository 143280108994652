import { Component, Inject,  ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {  FormControl, FormBuilder, FormGroup, AbstractControl, Validators, FormArray  } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DateConversionService } from '../services/date-conversion.service';
import { FormatDateRangeService } from '../services/format-date-range.service';

declare var $: any;

@Component({
  selector: 'app-merchant-provided-by',
  templateUrl: './merchant-provided-by.component.html',
  styleUrls: ['./merchant-provided-by.component.css']
})
export class MerchantProvidedByComponent {

       
  merchantData: any;
  merchantActivities: any = [];
  merchantActivity:any={};
  activityId:any;
  activityDefined:boolean= false;
  activityName:string="";
  merchantId:string="";
  loadMoreMerchants: boolean = false;
  merchantPage: number = 1;
  name: string = "";
  userId: string = "";
  isSubmitting:boolean=false;
  variationForm!: FormGroup;
  timings:any = ["12:00 am","1:00 am","2:00 am","3:00 am","4:00 am","5:00 am","6:00 am", "7:00 am", "8:00 am", "9:00 am", "10:00 am", "11:00 am", "12:00 pm", "1:00 pm", "2:00 pm", "3:00 pm", "4:00 pm", "5:00 pm", "6:00 pm", "7:00 pm", "8:00 pm", "9:00 pm", "10:00 pm","11:00 pm"];
  newTimings:any =[];
  currentDate: string;
  currentDate1: string;
  dropDate:string;
  startDate:string='';
  variationid:any=[];
  variationValueId:any=[];
  variations:any=[];
  variationValues:any=[];
  category:string= '';
  adultsCount: number = 1;
  childrenCount:number = 0;
  totalGuest:number= 1;
  inventoryVariations:any=[];
  inventoryVariationValues:any=[];
  inventoryVariationValueIds:any=[];
  merchantinventories:any=[];
  merchantInventoryId:any="";
  allSlots:any=[];
  slots:any =[];
  months:any=[];

  @ViewChild('dialogContent') dialogContent: ElementRef | undefined;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private datePipe: DatePipe,private formatDateRangeService:FormatDateRangeService,  private dateConversionService: DateConversionService, public dialog: MatDialog, private auth: AuthService, public PopupProvidedByDialogRef: MatDialogRef<MerchantProvidedByComponent>, private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private snackBar: MatSnackBar) {
    this.merchantId = data.merchantid;
    if(data.activityid){
      this.activityId = data.activityid;
      this.activityDefined= true;
    }
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.name = info.firstname;
        this.userId = info.id;
      }
    })
    this.currentDate = this.getCurrentDate();
    this.dropDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    this.startDate = this.getCurrentDate();
  }

  ngOnInit() {
    this.variationForm = this.formBuilder.group({
      activityId:  new FormControl(this.activityId),
      date:  new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      guest: new FormControl('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]),
      variations: this.formBuilder.array([]),
      inventoryvariations: this.formBuilder.array([])
    });
    this.http.get(environment.apiUrl + `/merchants/merchant-activities?id=` +this.merchantId).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.merchantactivities = data.merchantactivities.filter((x:any)=> x.merchantactivitystatus == "1");
          if(this.activityId){
            this.merchantActivities = data.merchantactivities;
            this.activityName = data.merchantactivities.find((x:any)=> x.activityid === this.activityId).activityname + '('+data.merchantactivities.find((x:any)=> x.activityid === this.activityId).addressline1  + ')'
            this.variationForm.get('activityId')?.setValue(this.activityId);
            this.onActivityChange()
          }else{
           data.merchantactivities =  this.removeDuplicates(data.merchantactivities, "activityid");
           this.merchantActivities = data.merchantactivities;
          }
        }
      }
    });
   
   
  }

  get variationsArrayControls() {
    return (this.variationForm.get('variations') as FormArray).controls;
  }

  get inventoryvariationsArrayControls() {
    return (this.variationForm.get('inventoryvariations') as FormArray).controls;
  }

  closeProvidedByDialog() {
    this.PopupProvidedByDialogRef.close();
  }


  getCurrentDate(): string {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd')!;
  }

  getNextDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Adding 1 day to the current date
    return this.datePipe.transform(currentDate, 'yyyy-MM-dd')!;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.variationForm.controls;
  }

  bookMerchant(id: any, activityid: any, merchantid:any) {
    this.isSubmitting = true;
    if (this.variationForm.invalid) {
      if (this.dialogContent && this.dialogContent.nativeElement) {
        this.dialogContent.nativeElement.scrollTop = 0;
      }
      return;
    }
    let data:any = this.variationForm.value;
    const startDate:any = new Date(data.date);
    const endDate:any = new Date(data.checkoutdate);
    const timeDiff = endDate - startDate;
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    (Number(daysDiff) != 0)? data.days= daysDiff:"";
    data.variations.forEach((x:any, index:any)=>{
      x.variationvalue = this.variationValues[index].find((y:any)=> y.variationvalues.id == x.variationvalueid).variationvalues.value
    })
    if (this.name) {
      this.PopupProvidedByDialogRef.close();
      this.router.navigate(['/book-merchant/camping'], { queryParams: { merchantId: merchantid, merchantactivityid:id, activityId: activityid, type: "User", data: JSON.stringify(data)} })
    } else {
      this.PopupProvidedByDialogRef.close();
      const isMobile = window.innerWidth < 768;
      let sizes={
        height:'90vh',
        width:'50%',
        maxWidth:'unset', 
        panelClass: 'bg-login-class'
      };
      if(isMobile){
        sizes={
          width:'100%',  
          maxWidth:'unset',
          height:'100%', 
          panelClass: 'bg-login-class'
        }
      }
      this.dialog.open(LoginDialogComponent, {
        ...sizes,
       disableClose: true,
       data: {
  
       },
     });
    }

  }

  loadMerchantActivity(merchantid: any, activityid: any) {
    let src: any = [];
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.post(environment.apiUrl + `/merchantactivities/list`, { merchantId: merchantid, activityId: activityid, status: "1" }).toPromise();
        if (data.status === "SUCCESS") {
          resolve(data.merchantactivities[0]);
        } else {
          reject(src);
        }
      } catch (error) {
        reject(src);
      }
    });
  }


  isPastDateTime(timing: string, type:string): boolean {
    const currentDateTime = moment();
    if(type == 'time'){
      const selectedDateTime = moment(`${this.currentDate} ${timing}`, 'YYYY-MM-DD h:mm a');
      return selectedDateTime.isBefore(currentDateTime);
    }else{
      const selectedDateTime = moment(`${this.dropDate} ${timing}`, 'YYYY-MM-DD h:mm a');
      return selectedDateTime.isBefore(currentDateTime);
    }
   
  }

  async searchMerchants(){
    this.isSubmitting = true;
    if (this.variationForm.invalid) {
      return;
    }
    this.merchantData = [];
    let data:any={};
    data.activityId= this.variationForm.value.activityId;
    data.variations=[];
    this.variationForm.value.variations.forEach((x:any)=>{
      data.variations.push({variationtypeid : x.variationid, variationvalueid : x.variationvalueid})
    })
      this.http.post(environment.apiUrl + `/merchantactivities/filter-activites-merchant`, data).subscribe(async (data: any) => {
        {
          if (data.status === "SUCCESS") {
            this.merchantActivity = data.merchantactivities.find((x:any)=> x.merchantid === this.merchantId);
            if(!this.merchantActivity){
              this.snackBar.open('No merchants provide this activity.', 'close', { duration: 3000, verticalPosition: 'top' });
              return;
            }
            if(this.category === "rafting" || this.category === "activity"){
              let data:any = this.variationForm.value;
              data.variations.forEach((x:any, index:any)=>{
                x.variationvalue = this.variationValues[index].find((y:any)=> y.variationvalues.id == x.variationvalueid).variationvalues.value
              })
              this.PopupProvidedByDialogRef.close();
              this.router.navigate(['/book-merchant/rafting'], { queryParams: { merchantId: this.merchantId, merchantactivityid:this.merchantActivity.id, activityId: this.variationForm.value.activityId, type: "Merchant", data: JSON.stringify(data)} })
            }
            if(this.category === "camping"){
              if (!this.variationForm.invalid) {
                const checkoutDate = new Date(this.variationForm.value.checkoutdate);
                let date = new Date();
                if (this.variationForm && this.variationForm.get('date')?.value) {
                  const dateFormControlValue = this.variationForm.get('date')?.value;
                  date = new Date(dateFormControlValue);
                }
          
                if (isNaN(checkoutDate.getTime()) || checkoutDate < date) {
                  this.snackBar.open('Check-In date should be lesser than Check-Out date.', 'close', { duration: 2000, verticalPosition: 'top' },);
                  return;
                }
              }
              let data:any = this.variationForm.value;
              const startDate:any = new Date(data.date);
              const endDate:any = new Date(data.checkoutdate);
              const timeDiff = endDate - startDate;
              const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
              (Number(daysDiff) != 0)? data.days= daysDiff: data.days= 1;
              data.variations.forEach((x:any, index:any)=>{
                x.variationvalue = this.variationValues[index].find((y:any)=> y.variationvalues.id == x.variationvalueid).variationvalues.value
              })
              if(this.merchantinventories.length > 0 && this.variations.length >0){
                let filteredData = [];
                    filteredData = this.merchantinventories.filter(item => {
                        return this.variations.some(desiredVar =>
                            item.inventoryvariations.some(variation =>
                                variation.variationid === Number(desiredVar.variationid) &&
                                variation.variationvalueid === Number(desiredVar.variationvalueid)
                            )
                        );
                    });
                    if(filteredData.length > 0){
                      this.merchantInventoryId = filteredData[0].id;
                      let availableDate= true;
                      let bookingsDates=[];
                      let dates=this.getDates(new Date(this.variationForm.value.date), new Date(this.variationForm.value.checkoutdate));
                      if(dates.length == 1){
                        bookingsDates= dates;
                      }else{
                        for(let i=0; i<dates.length-1; i++){
                          bookingsDates.push(dates[i])
                        }
                      }
                      if(bookingsDates.length > 0){
                        for(let i=0; i<bookingsDates.length;i++){
                          let data:any= await this.http.post(environment.apiUrl + `/merchantinventories/checkAvailability`, {bookingDate:bookingsDates[i], merchantInventoryId:this.merchantInventoryId}).toPromise();
                          if(data.status === "SUCCESS"){
                            (data.availability == 0)? availableDate = true : '';
                          }
                        }
                      }
                      if(!availableDate){
                          this.snackBar.open('The selected camping is unavailable on this date. Please consider choosing different date.', 'close', { duration: 3000, verticalPosition: 'top' },);
                        return;
                      }
                    }
              }
              this.PopupProvidedByDialogRef.close();
              this.router.navigate(['/book-merchant/camping'], { queryParams: { merchantId: this.merchantId, merchantactivityid:this.merchantActivity.id, activityId: this.variationForm.value.activityId, type: "Merchant", data: JSON.stringify(data)} })
            }
            if(this.category === "bikerental"){
              if (!this.variationForm.invalid) {
                const dropdownDate = new Date(this.variationForm.value.dropdowndate);
                let date = new Date();
                if (this.variationForm && this.variationForm.get('date')?.value) {
                  const dateFormControlValue = this.variationForm.get('date')?.value;
                  date = new Date(dateFormControlValue);
                }
                if (isNaN(dropdownDate.getTime()) || dropdownDate < date) {
                  this.snackBar.open('Pickup Date should be lesser than Dropdown Date.', 'close', { duration: 2000, verticalPosition: 'top' },);
                  return;
                }
               
                if(new Date(dropdownDate).getTime() === new Date(date).getTime()){
                  const combinedPickupDateTimeString = this.variationForm.value.date + ' ' + this.variationForm.value.time;
                  const combinedDropDownDateTimeString = this.variationForm.value.dropdowndate + ' ' + this.variationForm.value.dropdowntime;
                  if(new Date(combinedDropDownDateTimeString)< new Date(combinedPickupDateTimeString)){
                    this.snackBar.open('Pickup Time should be lesser than Dropdown Time.', 'close', { duration: 2000, verticalPosition: 'top' },);
                    return;
                  }
                }
              }
              let data:any = this.variationForm.value;
              data.variations.forEach((x:any, index:any)=>{
                x.variationvalue = this.variationValues[index].find((y:any)=> y.variationvalues.id == x.variationvalueid).variationvalues.value
              })
              data.inventoryvariations.forEach((x:any)=>{
                x.variationvalueid = data.variations.find((y:any)=> y.variationid == x.variationid).variationvalueid
              })
              data.inventoryvariations.forEach((x:any, index:any)=>{
                x.variationvalue = this.variationValues[index].find((y:any)=> y.variationvalues.id == x.variationvalueid).variationvalues.value
              })
              if(data.inventoryvariations.length > 0){
                let merchantInventory:any= await this.http.get(environment.apiUrl + `/merchantinventories/viewMerchantsInventory?activityId=${this.variationForm.value.activityId}&merchantId=${this.merchantId}`).toPromise();
                let filteredData = [];
                if (merchantInventory.merchantinventories.length > 0) {
                    filteredData = merchantInventory.merchantinventories.filter(item => {
                        return data.inventoryvariations.every(desiredVar =>
                            item.inventoryvariations.some(variation =>
                                variation.variationid === Number(desiredVar.variationid) &&
                                variation.variationvalueid === Number(desiredVar.variationvalueid)
                            )
                        );
                    });
                }
                if(filteredData.length > 0){
                    data.merchantinventoryid = filteredData[0].id;
                    let bookingsDates:any =[];
                    let dates = this.getDates(new Date(this.variationForm.value.date), new Date(this.variationForm.value.dropdowndate));
                      bookingsDates = dates;
                      this.variationForm.get('guest').setValue(bookingsDates.length);
                      data.guest = bookingsDates.length;
                      let availability = true;
                      let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkavailabilitydates`, { bookingDate: bookingsDates, merchantInventoryId: filteredData[0].id }).toPromise();
                      if (inventoryRes.status === "SUCCESS") {
                        if (!inventoryRes.availability) {
                          availability = false;
                        }
                      }
                      if(!availability){
                        this.snackBar.open('Please choose different date.', 'close', { duration: 2000, verticalPosition: 'top' },);
                        return;
                      }
                }else{
                   data.merchantinventoryid = '';
                }
              }else{
                data.merchantinventoryid = '';
              }
              this.PopupProvidedByDialogRef.close();
              this.router.navigate(['/book-merchant/scooty-rental'], { queryParams: { merchantId: this.merchantId, merchantactivityid:this.merchantActivity.id, activityId: this.variationForm.value.activityId, type: "Merchant", data: JSON.stringify(data)} })
            }
            if(this.category ===  'trekking' || this.category ===  'tour'){
              let data:any = this.variationForm.value;
              data.variations.forEach((x:any, index:any)=>{
                x.variationvalue = this.variationValues[index].find((y:any)=> y.variationvalues.id == x.variationvalueid).variationvalues.value
              })
              if(this.merchantinventories.length > 0){
                this.merchantInventoryId = this.merchantinventories[0].id;
                const { startDate, endDate } = this.dateConversionService.convertToDateRange(this.variationForm.value.slot);
                try{
                  let data:any= await this.http.post(environment.apiUrl + `/merchantinventories/checkAvailability`, {bookingDate:startDate, merchantInventoryId:this.merchantInventoryId, endDate:endDate}).toPromise();
                  if(data.status === "SUCCESS"){
                    if(this.category ===  'trekking'){
                      if(data.availability == 0){
                        this.snackBar.open('The selected trekking is unavailable on this slot. Please consider choosing different slot.', 'close', { duration: 3000, verticalPosition: 'top' },);
                        return;
                      }else if((data.availability - this.variationForm.value.guest) < 0){
                        this.snackBar.open(`The selected trekking is available only for ${data.availability}.`, 'close', { duration: 3000, verticalPosition: 'top' },);
                        return;
                      }
                    }else{
                      if(data.availability == 0){
                        this.snackBar.open('The selected tour is unavailable on this slot. Please consider choosing different slot.', 'close', { duration: 3000, verticalPosition: 'top' },);
                        return;
                      }
                    }
                  }
                }catch(err){
                  console.log(err)
                }
              }
              const { startDate, endDate } = this.dateConversionService.convertToDateRange(this.variationForm.value.slot);
              let rangeData:any = await this.http.get(environment.apiUrl + `/range/actualprice?merchantActivityId=${this.merchantActivity.id}&date=${startDate}`).toPromise();
              if (rangeData.status === "SUCCESS") {
                let amount =rangeData.price;
                if(amount == 0){
                  this.snackBar.open('Activity is not available in this slot. Please choose different slot.', 'close', { duration: 3000, verticalPosition: 'top' },);
                  return;
                }
              }
              this.PopupProvidedByDialogRef.close();
              this.router.navigate(['/book-merchant/package-tour'], { queryParams: { merchantId: this.merchantId, merchantactivityid:this.merchantActivity.id, activityId: this.variationForm.value.activityId, type: "Merchant", category:this.category, data: JSON.stringify(data)} })
            } 
          }
        }
    })
    
  }

  removeDuplicates(arr:any, prop:any) {
    return arr.filter((obj:any, index:any, self:any) =>
      index === self.findIndex((o:any) => o[prop] === obj[prop])
    );
  }

  onActivityChange(){
    let activityId = this.variationForm.value.activityId;
    if(activityId != ""){
      try {
        this.http.get(environment.apiUrl + `/range/merchant-minprice-by-activityid-date?activityId=${this.variationForm.value.activityId}&merchantId=${this.merchantId}&date=${this.getCurrentDate()}`).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            if(Number(data.min_price) == 0){
              this.currentDate ="";
              this.currentDate1 ="";
              this.startDate ="";
              this.dropDate ="";
            }else{
              this.currentDate = this.getCurrentDate();
              this.dropDate = this.getCurrentDate();
              this.currentDate1 = this.getNextDate();
              this.startDate = this.getCurrentDate();
            }
          }
        });
      } catch (error) {
        console.log(error)
      }
      const keys = Object.keys(this.variationForm.controls);
      keys.forEach(x=>{
        if(x != "activityId" && x != "variations" && x != "inventoryvariations"){
          this.variationForm.removeControl(x);
        }
      });
      const variationsArray = this.variationForm.get('variations') as FormArray;
      while (variationsArray.length !== 0) {
        variationsArray.removeAt(0);
      }
      const inventoryVariationsArray = this.variationForm.get('inventoryvariations') as FormArray;
      while (inventoryVariationsArray.length !== 0) {
        inventoryVariationsArray.removeAt(0);
      }
      let category = this.merchantActivities.find((x:any)=> x.activityid === activityId)?.category;
      if(category === "bikerental"){
        this.variationForm.addControl('date', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('time', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('dropdowndate', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('dropdowntime', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('guest', this.formBuilder.control('1', Validators.required));
      }else if(category === "camping"){
        this.variationForm.addControl('date', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('checkoutdate', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('guest', this.formBuilder.control('1', Validators.required));
        this.variationForm.addControl('adult', this.formBuilder.control('1', Validators.required));
        this.variationForm.addControl('children', this.formBuilder.control(''));
      }else if(category === "rafting"){
        this.variationForm.addControl('date', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('time', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('guest', this.formBuilder.control('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]));
      }else if(category === "trekking" || category === "tour"){
        this.variationForm.addControl('month', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('slot', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('guest', this.formBuilder.control('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]));
      }
      else{
        this.variationForm.addControl('date', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('time', this.formBuilder.control('', Validators.required));
        this.variationForm.addControl('guest', this.formBuilder.control('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]));
      }
      this.category = category;
      this.variationid=[];
      this.variationValueId=[];
      this.variations =[];
      this.variationValues =[];
      this.http.get(environment.apiUrl + `/merchantactivities/merchant-activity-variation-details?activityid=${activityId}&merchantid=${this.merchantId}`).subscribe(async (data: any) => {
        if(data.status === "SUCCESS"){
          data.activityVariationDetails.forEach((x:any)=>{
            x.merchants.forEach((y:any)=>{
              if(!this.variationid.includes(y.variationtypeid)){
                this.variationid.push(y.variationtypeid);
              }
            //  let index= this.variationid.indexOf(y.variationtypeid);
            //  if(index != -1){
            //   if(this.variationValueId[index] != undefined){
            //     (!this.variationValueId[index].includes(y.variationvalueid))?this.variationValueId[index].push(y.variationvalueid):""
            //   }else{
            //     this.variationValueId[index] =[];
            //     this.variationValueId[index].push(y.variationvalueid)
            //   }
            //  }
            })
         })
         if(this.variationid.length >0){
          let defaultVariationsData=[], sortedDefaultVariationsData=[];
          for (let i = 0; i < this.variationid.length; i++) {
            const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${this.variationid[i]}`).toPromise();
            defaultVariationsData.push(data.variations)
          }
          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation == '0' || a.parentvariation == null) {
                return -1;
              } else if (b.parentvariation == '0' || b.parentvariation == null) {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          sortedDefaultVariationsData = sortByParentVariation(defaultVariationsData);
          const customSort = (a, b)=> {
            const aIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === a.id);
            const bIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === b.id);
  
            if ((a.parentvariation == "0" || a.parentvariation == null) && !aIdInAnyParentVariation) {
              if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
              return 0; // Both have parentvariation as 0 or null, and id does not exist in any parentvariation, no change in order
              } else {
              return 1; // Only a has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
              }
            } else if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
              return -1; // Only b has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
            } else {
              return a.parentvariation.localeCompare(b.parentvariation);
            }
            }
  
            sortedDefaultVariationsData = sortedDefaultVariationsData.sort(customSort);
            let sortedDefaultVariationsid=[];
              for(let i=0; i< sortedDefaultVariationsData.length; i++){
                sortedDefaultVariationsid.push(sortedDefaultVariationsData[i].id);
              }
              this.variationid = sortedDefaultVariationsid;
              data.activityVariationDetails.forEach((x:any)=>{
                x.merchants.forEach((y:any)=>{
                    let index= this.variationid.indexOf(y.variationtypeid.toString());
                    if(index != -1){
                      if(this.variationValueId[index] != undefined){
                        (!this.variationValueId[index].includes(y.variationvalueid.toString()))?this.variationValueId[index].push(y.variationvalueid.toString()):""
                      }else{
                        this.variationValueId[index] =[];
                        this.variationValueId[index].push(y.variationvalueid.toString())
                      }
                 }
                })
             })
          const variationsPromises = this.variationid.map(async (x: any) => {
            try {
              const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
                return data;
            } catch (error) {
              throw error;
            }
          });
          
          try {
             this.variations = await Promise.all(variationsPromises);
             for(let i=0; i<this.variations.length; i++){
              if(this.variations[i].variations.parentvariation == 0 || this.variations[i].variations.parentvariation == null){
                const variationValue = this.variationValueId[i].map(async (y: any) => {
                  try {
                      const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
                      return data;
                    } catch (error) {
                      throw error;
                    }
                });
                let values= await Promise.all(variationValue)
                this.variationValues.push(values)
              }else{
                this.variationValues.push([]);
              }
             }
             const variationArray = this.variationForm.get('variations') as FormArray;
              this.variations.forEach((x:any)=>{
                variationArray.push(
                  this.formBuilder.group({
                    variationid:new FormControl(x.variations.id),
                    type:new FormControl(x.variations.type),
                    variationvalueid:new FormControl('', Validators.required),
                    parentvariation: new FormControl(x.variations.parentvariation)
                  }))
              })
            } catch (error) {
            console.error("An error occurred:", error);
          }
         }
  
        }
      })
     //inventory vartations
     this.inventoryVariations=[];
     this.inventoryVariationValueIds=[];
     this.inventoryVariationValues=[];
     if(category === "bikerental"){
     this.http.get(environment.apiUrl + `/merchantinventories/viewInventory?activityId=${activityId}&merchantId=${this.merchantId}`).subscribe(async (data: any) => {
       if(data.status === "SUCCESS"){
         if(data.merchantinventories.length> 0){
           let inventoryVariationIds=[];
           data.merchantinventories.forEach((x:any)=>{
             if(!inventoryVariationIds.includes(x.variationid)) inventoryVariationIds.push(x.variationid);
             this.inventoryVariationValueIds.push(x.variationvalueid);
           })
           const inventoryVariationRequests = inventoryVariationIds.map((x: any) => {
             return this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
           });
     
           const loadVariationRequests = await Promise.all(inventoryVariationRequests);
           loadVariationRequests.forEach((x: any) => {
             this.inventoryVariations.push(x.variations)
           })
     
           const sortByParentVariation = (data) => {
             const sortedData = [...data];
             sortedData.sort((a, b) => {
               if (a.parentvariation === '0') {
                 return -1;
               } else if (b.parentvariation === '0') {
                 return 1;
               } else {
                 return a.parentvariation.localeCompare(b.parentvariation);
               }
             });
             return sortedData;
           };
           this.inventoryVariations = sortByParentVariation(this.inventoryVariations);
           const inventoryvariationsArray = this.variationForm.get('inventoryvariations') as FormArray;
           this.inventoryVariations.forEach((x:any)=>{
             inventoryvariationsArray.push(
               this.formBuilder.group({
                 variationid:new FormControl(x.id),
                 type:new FormControl(x.type),
                 variationvalueid:new FormControl(''),
               }))
           })
             let valueRes:any= await this.http.post(environment.apiUrl + `/variationvalues/list`, { variationid: this.inventoryVariations[0].id, count: 500, status: 1 }).toPromise();
             if(valueRes.status === "SUCCESS"){
               let values=[];
               valueRes.variationvalues.forEach((x:any)=>{
                 if(this.inventoryVariationValueIds.includes(x.id)) values.push(x);
               })
               this.inventoryVariationValues.push(values);
             }
           this.inventoryVariations.forEach((x:any, index:any) => {
             if (index !== 0) {
               this.inventoryVariationValues.push([]);
             }
           });
         }
       }
     })
     }
     if(category === "camping"){
      this.loadMerchantInventories();
     }
     if(category === 'trekking'){
      this.loadMerchantInventories();
      this.http.get(environment.apiUrl + `/merchantactivities/merchant-packagetour-slots?category=trekking&merchantId=${this.merchantId}&activityId=${activityId}`).subscribe(async (data: any) => {
        if(data.status === "SUCCESS"){
            data.slots =data.slots.map(x=>  this.formatDateRangeService.formatDateRange(x));
            const months = data.slots.map(dateRange => {
              const month = dateRange.split(' ')[1];
              return month;
            });
            this.months = [...new Set(months)];
            this.allSlots = data.slots;
            this.slots = data.slots;
        }
      })
     }
     if(category === 'tour'){
      this.loadMerchantInventories();
      this.http.get(environment.apiUrl + `/merchantactivities/merchant-packagetour-slots?category=tour&merchantId=${this.merchantId}&activityId=${activityId}`).subscribe(async (data: any) => {
        if(data.status === "SUCCESS"){
            data.slots =data.slots.map(x=>  this.formatDateRangeService.formatDateRange(x));
            const months = data.slots.map(dateRange => {
              const month = dateRange.split(' ')[1];
              return month;
            });
            this.months = [...new Set(months)];
            this.allSlots = data.slots;
            this.slots = data.slots;
        }
      })
     }
     setTimeout(()=>{
      this.loadDatePricing();
     },500)
    }
  }

  changeMonths(){
    this.variationForm.get('slot').setValue('');
    this.slots = this.allSlots.filter(slot => {
      const monthPart = slot.split(' ')[1];
      return monthPart == this.variationForm.value.month;
    });
  }
  
  changeInventoryVariation(column: any) {
    let variationValueId = this.variationForm.value.inventoryvariations[column]?.variationvalueid;
    const inventoryvariationsArray = this.variationForm.get('inventoryvariations') as FormArray;
    const formGroup = inventoryvariationsArray.at(column +1) as FormGroup;
    if (formGroup) {
      formGroup.patchValue({
        variationvalueid: '',
      });
    }
    if (variationValueId != "") {
      this.http.post(environment.apiUrl + `/variationvalues/list`, { parentVariationvalueId: variationValueId, count: 500, status: 1 }).subscribe(async (res: any) => {
        {
          if (res.status === "SUCCESS") {
            let values=[];
            res.variationvalues.forEach((x:any)=>{
              if(this.inventoryVariationValueIds.includes(x.id)) values.push(x);
            })
            this.inventoryVariationValues[column + 1] = values;
          }
        }
      })
    }else{
      this.inventoryVariationValues[column + 1] = [];
    }
  }

  increaseValue(type:any) {
    if (type == "adult"  && this.adultsCount < 10) {
      this.adultsCount++;
      this.totalGuest++;
    }
    if (type == "children"  && this.childrenCount < 10) {
      this.childrenCount++;
      this.totalGuest++;
    }
  }

  decreaseValue(type:any) {
    if (type == "adult"  && this.adultsCount > 1) {
      this.adultsCount--;
      this.totalGuest--;
    }
    if (type == "children"  && this.childrenCount > 0) {
      this.childrenCount--;
      this.totalGuest--;
    }
  }

  loadMerchantInventories(){
    this.merchantinventories=[];
    this.http.get(environment.apiUrl + `/merchantinventories/viewMerchantsInventory?activityId=${this.variationForm.value.activityId}&merchantId=${this.merchantId}`).subscribe(async (data: any) => {
      if(data.status === "SUCCESS"){
        if(data.merchantinventories.length> 0){
           this.merchantinventories = data.merchantinventories;
        }
      }
    })
 }

  formatDate(date:any) {
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const day = String(date.getDate()).padStart(2, '0');
   return `${year}-${month}-${day}`;
 }
 
  getDates(startDate:any, endDate:any) {
   const dateArray = [];
   let currentDate = new Date(startDate);
   while (currentDate <= endDate) {
     dateArray.push(this.formatDate(currentDate));
     currentDate.setDate(currentDate.getDate() + 1);
   }
 
   return dateArray;
 }

  changeRentalDefaultVariation(column: any) {
    let variationid = this.variationForm.value.variations[column]?.variationid;
    let parentVariation = this.variationForm.value.variations[column+1]?.parentvariation;
    let variationValueId = this.variationForm.value.variations[column]?.variationvalueid;
    if (parentVariation == variationid) {
      const variationsArray = this.variationForm.get('variations') as FormArray;
      const formGroup = variationsArray.at(column + 1) as FormGroup;
      if (formGroup) {
        formGroup.patchValue({
          variationvalueid: '',
        });
      }
      if (variationValueId != "") {
        this.http.post(environment.apiUrl + `/variationvalues/list`, { parentVariationvalueId: variationValueId, count: 500, status: 1 }).subscribe(async (res: any) => {
          {
            if (res.status === "SUCCESS") {
              let values = [];
              res.variationvalues.forEach((x: any) => {
                if (this.variationValueId[column+1]?.includes(x.id)) values.push({ status: "SUCCESS", variationvalues:x});
              })
              this.variationValues[column + 1] = values;
            }
          }
        })
      } else {
        this.variationValues[column + 1] = [];
      }
    }
  }

  loadDatePricing(){
    if (typeof $ !== 'undefined' && $.fn.datepicker) {
      // Use jQuery Datepicker
      $('.datepicker').datepicker({
        minDate: new Date(this.startDate),
        dateFormat: 'yy-mm-dd',
        changeMonth: true,
        changeYear: true,
        onSelect: (dateText: any) => {
          this.currentDate = dateText;
        }
      });
      $('.datepicker1').datepicker({
        minDate: new Date(this.startDate),
        dateFormat: 'yy-mm-dd',
        changeMonth: true,
        changeYear: true,
        onSelect: (dateText: any) => {
          this.dropDate = dateText;
        }
      });

      let content_this = this;
        $(".datepicker").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center;'><img src='/assets/loader.svg' width=25px alt='loader' /></span>");
                  try {
                    content_this.http.get(environment.apiUrl + `/range/merchant-minprice-by-activityid-date?activityId=${content_this.variationForm.value.activityId}&merchantId=${content_this.merchantId}&date=${date}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
            }

          });
        });
        $(".datepicker1").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                  try {
                    content_this.http.get(environment.apiUrl + `/range/merchant-minprice-by-activityid-date?activityId=${content_this.variationForm.value.activityId}&merchantId=${content_this.merchantId}&date=${date}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
            }
          });
        });
    } else {
      console.error('jQuery or jQuery UI Datepicker not available');
    }
  }

  preventToggleDefault(event: MouseEvent): void {
    event.preventDefault();
  }
  
}
