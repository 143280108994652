
<div mat-dialog-content class=" p-0 m-0">
  <div>

  <button type="button" class="btn-close " (click)="closeContactUsDialog()" aria-label="Close" style="position: absolute;
  z-index: 67;
  top: 7%;
  left: 4%;background-color: white;
  padding: 7px;
"></button>

  <div *ngIf="url"  >
      <iframe   [src]="url" class="iframe-src">
      </iframe>
  </div>
  <div *ngIf="imageUrl " >
      <img  [src]="imageUrl" alt="Image"  class="imageSrc" >
  </div>
    
</div>
</div>
