
  <ng-container [ngSwitch]="icon">
    <svg *ngSwitchCase="'likeIcon'" width="17" height="17" viewBox="0 0 64 64" [ngStyle]="{'fill': fill}" xmlns="http://www.w3.org/2000/svg">
  <path  d="M13 28H3C1.34312 28 0 29.3431 0 31V61C0 62.6569 1.34312 64 3 64H13C14.6569 64 16 62.6569 16 61V31C16 29.3431 14.6569 28 13 28ZM8 59C6.34312 59 5 57.6569 5 56C5 54.3431 6.34312 53 8 53C9.65687 53 11 54.3431 11 56C11 57.6569 9.65687 59 8 59ZM48 10.1815C48 15.4835 44.7537 18.4575 43.8404 22H56.5558C60.7304 22 63.9804 25.4683 63.9999 29.2623C64.0104 31.5045 63.0566 33.9184 61.57 35.4119L61.5563 35.4256C62.7858 38.3428 62.5859 42.4302 60.3927 45.3592C61.4779 48.5961 60.3841 52.5723 58.345 54.7039C58.8823 56.9036 58.6255 58.7757 57.5765 60.2829C55.0252 63.9484 48.702 64 43.3549 64L42.9992 63.9999C36.9634 63.9977 32.0235 61.8001 28.0543 60.0342C26.0596 59.1469 23.4516 58.0484 21.4729 58.012C20.6554 57.997 20 57.3299 20 56.5122V29.791C20 29.391 20.1602 29.0071 20.4447 28.7259C25.3965 23.8329 27.5257 18.6525 31.5844 14.587C33.4349 12.733 34.1079 9.9325 34.7585 7.22425C35.3144 4.91162 36.4771 0 39 0C42 0 48 1 48 10.1815Z"></path>
</svg>
<svg *ngSwitchCase="'likeIconRating'" width="15" height="15" viewBox="0 0 64 64" [ngStyle]="{'fill': fill}" xmlns="http://www.w3.org/2000/svg">
  <path  d="M13 28H3C1.34312 28 0 29.3431 0 31V61C0 62.6569 1.34312 64 3 64H13C14.6569 64 16 62.6569 16 61V31C16 29.3431 14.6569 28 13 28ZM8 59C6.34312 59 5 57.6569 5 56C5 54.3431 6.34312 53 8 53C9.65687 53 11 54.3431 11 56C11 57.6569 9.65687 59 8 59ZM48 10.1815C48 15.4835 44.7537 18.4575 43.8404 22H56.5558C60.7304 22 63.9804 25.4683 63.9999 29.2623C64.0104 31.5045 63.0566 33.9184 61.57 35.4119L61.5563 35.4256C62.7858 38.3428 62.5859 42.4302 60.3927 45.3592C61.4779 48.5961 60.3841 52.5723 58.345 54.7039C58.8823 56.9036 58.6255 58.7757 57.5765 60.2829C55.0252 63.9484 48.702 64 43.3549 64L42.9992 63.9999C36.9634 63.9977 32.0235 61.8001 28.0543 60.0342C26.0596 59.1469 23.4516 58.0484 21.4729 58.012C20.6554 57.997 20 57.3299 20 56.5122V29.791C20 29.391 20.1602 29.0071 20.4447 28.7259C25.3965 23.8329 27.5257 18.6525 31.5844 14.587C33.4349 12.733 34.1079 9.9325 34.7585 7.22425C35.3144 4.91162 36.4771 0 39 0C42 0 48 1 48 10.1815Z"></path>
</svg>

<svg *ngSwitchCase="'fireIcon'"  width="24" height="18" viewBox="0 0 48 64"   [ngStyle]="{'fill': fill}"     xmlns="http://www.w3.org/2000/svg" >
  <path d="M24 64C37.256 64 48 56 48 42C48 36 46 26 38 18C39 24 33 26 33 26C36 16 28 2 16 0C17.428 8 18 16 8 24C3 28 0 34.916 0 42C0 56 10.744 64 24 64ZM24 60C17.372 60 12 56 12 49C12 46 13 41 17 37C16.5 40 20 42 20 42C18.5 37 22 29 28 28C27.284 32 27 36 32 40C34.5 42 36 45.456 36 49C36 56 30.628 60 24 60Z" />
  </svg>

<svg *ngSwitchCase="'fireIconRating'"  width="17" height="17" viewBox="0 0 48 64"   [ngStyle]="{'fill': fill}" xmlns="http://www.w3.org/2000/svg" >
  <path d="M24 64C37.256 64 48 56 48 42C48 36 46 26 38 18C39 24 33 26 33 26C36 16 28 2 16 0C17.428 8 18 16 8 24C3 28 0 34.916 0 42C0 56 10.744 64 24 64ZM24 60C17.372 60 12 56 12 49C12 46 13 41 17 37C16.5 40 20 42 20 42C18.5 37 22 29 28 28C27.284 32 27 36 32 40C34.5 42 36 45.456 36 49C36 56 30.628 60 24 60Z" />
  </svg>
</ng-container>

