<button type="button" class="btn-close mt-2 ms-2" (click)="closeForgetPasswordDialog()" aria-label="Close"></button>
<h1 mat-dialog-title id="form-login" class="text-center" style="text-align: center;" class="full-screen-dialog">Forget Password</h1>
<div mat-dialog-content class="overflow-auto">
    
    <form class="h-100" (submit)="onSubmit()" [formGroup]="forgetpasswordForm">
        <!-- Email input -->
        <div class="form-outline mb-2 mt-2 ">
            <label for="form2Example1" class="w-100 text-center">{{heading}}</label>
            <input type="email" id="form2Example1" formControlName="email" class="form-control email mt-2"
                placeholder=" Email address" [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }"/>
                <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']">Email is required</div>
                    <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                  </div>
        </div>
        <div class="text-center mt-3">
        <button type="submit" class="login-btn btn text-light mb-2 ">Submit</button>
        </div>
     </form>   
</div>
