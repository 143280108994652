<div  *ngIf="!displayForm" >
<div mat-dialog-content class="overflow-auto p-md-4" style="background-color: #f4f1f1;">
  <h2 class="mb-1">Experience a Great Adventure</h2>
  <h4>Looking for some Thrill?</h4>
  <form class="h-100" (submit)="submitForm()" [formGroup]="locationForm">
    <div class="row" >
      <div class="col-md-9 col-12">
        <div class="input-group fetch-location" (focusin)="highlightInputGroup()"
        (focusout)="removeHighlightInputGroup()">
          <span class="input-group-text" id="basic-addon1"><img src="/assets/new-logo.svg" width="20px" alt="logo"></span>
            <select name="" class="form-control no-focus-outline border-none" formControlName="location" aria-describedby="basic-addon1" (keyup.enter)="submitForm()" aria-label="Username"
           id="">
            <option value="" disabled selected hidden>Choose Destination</option>
            <option [value]="stringifyLocation(location)" *ngFor="let location of locations">{{location.locationname}}
            </option>
          </select>    
    </div>
        <div *ngIf="isSubmitting && f['location'].errors" class="invalid-feedback">
          <div *ngIf="f['location'].errors['required']">This field is required.</div>
        </div>
      </div>
      <div class="col-md-3 col-12 text-center">
        <button type="submit" class="btn search-btn px-3 h-100 mt-md-0 mt-2">Jump</button>
      </div>
    </div>
  </form>
</div>
</div>