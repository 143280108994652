import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MerchantProfileCardComponent } from '../cards/merchant-profile-card/merchant-profile-card.component';
import { OtherActivitiesCardComponent } from '../cards/other-activities-card/other-activities-card.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivityCampingCardComponent } from '../cards/activity-camping-card/activity-camping-card.component';
import { ExploreActivitieCardComponent } from '../cards/explore-activitie-card/explore-activitie-card.component';




@NgModule({
  declarations: [
    MerchantProfileCardComponent,
    OtherActivitiesCardComponent,
    ActivityCampingCardComponent,
    ExploreActivitieCardComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSelectModule,
    SvgIconModule,
    MatTooltipModule,
  ],
  exports:[
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSelectModule,
    SvgIconModule,
    MatTooltipModule,
    MerchantProfileCardComponent,
    OtherActivitiesCardComponent,
    ActivityCampingCardComponent,
    ExploreActivitieCardComponent,
  ]
})
export class SharedModule { }
