<!-- <h1 mat-dialog-title id="form-login" class="text-center" style="text-align: center;" class="full-screen-dialog">Login to
  AdvenZone</h1> -->
<div mat-dialog-content class="overflow-auto">
  <button type="button" class="btn-close mt-3 ms-2" (click)="closeGuestLoginDialog()" aria-label="Close"></button>
  <div class="logo d-block d-md-none my-5">
    <img src="/assets/new-logo.svg" alt="Logo">
  </div>
  <form class="h-100 mx-3 mx-md-5" (submit)="onGuestLogin()" [formGroup]="guestLoginForm">
    <!-- 2 column grid layout with text inputs for the first and last names -->
    <div class="row mb-0 mt-3">
      <div class="col pe-1">
        <div class="form-outline ">
          <input type="text" id="form3Example1" class="form-control inputbox" formControlName="firstName"
            placeholder="First name" [ngClass]="{ 'is-invalid': isSubmitting && f['firstName'].errors }" />
          <div *ngIf="isSubmitting && f['firstName'].errors" class="invalid-feedback ms-2">
            <div *ngIf="f['firstName'].errors['required']">
              First name is required
            </div>
          </div>
        </div>
      </div>
      <div class="col ps-1">
        <div class="form-outline ">
          <input type="text" id="form3Example2" class="form-control inputbox" formControlName="lastName"
            placeholder="Last name" [ngClass]="{ 'is-invalid': isSubmitting && f['lastName'].errors }" />
          <div *ngIf="isSubmitting && f['lastName'].errors" class="invalid-feedback ms-2">
            <div *ngIf="f['lastName'].errors['required']">
              Last name is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-outline">
          <input type="text" id="form2Example1" class="form-control inputbox"
            [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }" formControlName="phone"
            placeholder="Phone Number"   maxlength="10" oninput="this.value = this.value.replace(/[^0-9 ]/,'')"/>
          <div *ngIf="isSubmitting && f['phone'].errors" class="invalid-feedback ms-2">
            <div *ngIf="f['phone'].errors['required']">Phone Number is required</div>
            <div *ngIf="f['phone'].errors['invalidPhoneNumber']">Invalid Phone number.</div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="text-center">
          <button type="submit" class="btn create-btn btn-block  text-light text-center">Send
              OTP</button>
        </div>
      </div>
    </div>
    <div class="form-outline mb-2 mt-3" [ngClass]="{'d-none':disableGuestButton}">
      <input type="text" class="form-control inputbox" formControlName="otp"  maxlength="4" oninput="this.value = this.value.replace(/[^0-9 ]/,'')" placeholder="OTP" />
    </div>

    <div class=" text-center mt-2 ">
      <button type="button" [disabled]="disableGuestButton" class="btn create-btn btn-block w-100 mt-2 text-light text-center" (click)="guestLogin()">Continue as
          Guest</button>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <hr class="hr-or">
      <span class="mx-2 text-dark">or</span>
      <hr class="hr-or">
    </div>
    <div class="my-3 text-center">
      <a class="text-decoration-none btn create-btn text-light text-center pointer" (click)="openSignInDialog()">Create Account</a>
    </div>
    <div class="my-3 text-center">
      <p class="already-account">Already have an Account? <a class="text-decoration-none pointer text-dark" (click)="openLoginDialogue()">Login</a></p>
    </div>
  </form>
   
</div>