import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-new-provided-by',
  templateUrl: './new-provided-by.component.html',
  styleUrls: ['./new-provided-by.component.css']
})
export class NewProvidedByComponent {

title:any="";
merchantData:any="";
categoryName:string="";
userId:string="";
providedMerchantData:any={};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog, private auth: AuthService, public NewProvidedByDialogRef: MatDialogRef<NewProvidedByComponent>, private http: HttpClient, private router: Router, private snackBar: MatSnackBar) {
    this.merchantData = data.merchantData;
    if(data.category === "bikerental"){
      this.categoryName = "Bike Rentals";
    }
    this.title = this.categoryName + " in " + this.merchantData.city;
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.userId = info.id;
      }
    })
  }

  ngOnInit(){

  }


  closeProvidedByDialog() {
    this.NewProvidedByDialogRef.close();
  }

  openMerchant(id:any){

  }

}
