import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { SignUpComponent } from '../auth/sign-up/sign-up.component';
import { ContactUsFormComponent } from '../contact-us-form/contact-us-form.component';
import { PartnerLoginDialogueComponent } from '../auth/partner-login-dialogue/partner-login-dialogue.component';
import { DataService } from '../data.service';
import { GuestLoginDialogueComponent } from '../auth/guest-login-dialogue/guest-login-dialogue.component';
import { FetchLocationComponent } from '../location/fetch-location/fetch-location.component';
import { filter } from 'rxjs';
import { MerchantLoginDialogueComponent } from '../auth/merchant-login-dialogue/merchant-login-dialogue.component';
import { SurveyPopupComponent } from '../survey-popup/survey-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements AfterViewInit {
  userInfo: any = null;
  name: any;
  userId: any;
  profileSrc: any;
  UserType: any = null;
  userAgent: string = "";
  bookingSource: string;
  locationName: string = "";
  linkFrom: any = "";
  placeholders: string[] = ["Search 'Rafting'", "Search 'Camping'", "Search 'Rentals'"];
  currentPlaceholderIndex = 0;
  currentPlaceholder: string;
  availableLocations: any = [];
  selectedLocation: string = "";
  routeChangeCalled: boolean = false;
  allLocations: any[] = [];
  filteredLocations: any[] = [];
  inAppBrowserRef: any;
  currentLocationId: string;
  displayForm:boolean = false;
  homeNavigation:string ="/";
  locationInputFrom:string="";
  

  constructor(private http: HttpClient, public dialog: MatDialog, private route: ActivatedRoute, private elementRef: ElementRef, public auth: AuthService, public router: Router, public dataservice: DataService, private cdr: ChangeDetectorRef,private renderer: Renderer2) {
    this.bookingSource = localStorage.getItem('bookingType') || '';
    // this.bookingSource = localStorage.getItem('bookingType') == null ? "" : localStorage.getItem('bookingType');
    this.auth.userName.subscribe(name => {
      this.name = name;
    })
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.auth.UserType.subscribe(type => {
          this.UserType = type;
          if (type === "user") {
            this.name = info.firstname;
            this.userId = info.id;
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${this.userId}`).subscribe((data: any) => {
              if (data.status === "SUCCESS" && data.uploads.length > 0) {
                let uploadid = data.uploads[0].id;
                this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${uploadid}`).subscribe((value: any) => {
                  if (value.status === "SUCCESS") {
                    this.profileSrc = value.upload.thumbnail;
                  }
                })
              }
            });
            if (info.changepassword) {
              const isMobile = window.innerWidth < 768;
              let sizes = {
                height: 'fit-content',
                width: '50%',
                maxWidth: 'unset',
              };
              if (isMobile) {
                sizes = {
                  width: '100%',
                  maxWidth: 'unset',
                  height: '100%',
                }
              }
              this.dialog.open(NewPasswordComponent, {
                ...sizes,
                disableClose: true,
                data: {
                  userId: this.userId, userType: this.UserType
                },
              });
            }
          }
          if (type === "merchant") {
            this.name = info.name;
            this.userId = info.id;
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${this.userId}`).subscribe((data: any) => {
              if (data.status === "SUCCESS" && data.uploads.length > 0) {
                let uploadid = data.uploads[0].id;
                this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${uploadid}`).subscribe((value: any) => {
                  if (value.status === "SUCCESS") {
                    this.profileSrc = value.upload.thumbnail;
                  }
                })
              }
            });
            this.http.post(environment.apiUrl + `/merchantpartners/listmerchantpartner`, { merchantId: this.userId, status: 1 }).subscribe((data: any) => {
              {
                if (data.status === "SUCCESS") {
                  this.dataservice.notification = data.merchantpartners;
                }
              }
            })
            if (info.changepassword) {
              const isMobile = window.innerWidth < 768;
              let sizes = {
                height: 'fit-content',
                width: '50%',
                maxWidth: 'unset',
              };
              if (isMobile) {
                sizes = {
                  width: '100%',
                  maxWidth: 'unset',
                  height: '100%',
                }
              }
              this.dialog.open(NewPasswordComponent, {
                ...sizes,
                disableClose: true,
                data: {
                  userId: this.userId, userType: this.UserType
                },
              });
            }
          } if (type === "partner") {
            this.name = info.name;
            this.userId = info.id;
            if (info.changepassword) {
              const isMobile = window.innerWidth < 768;
              let sizes = {
                height: 'fit-content',
                width: '50%',
                maxWidth: 'unset',
              };
              if (isMobile) {
                sizes = {
                  width: '100%',
                  maxWidth: 'unset',
                  height: '100%',
                }
              }
              this.dialog.open(NewPasswordComponent, {
                ...sizes,
                disableClose: true,
                data: {
                  userId: this.userId, userType: this.UserType
                },
              });
            }
          }
        })
      }
    })
  }

  ngAfterViewInit() {
    if (!this.routeChangeCalled) {
      this.routeChangeCalled = true;
      this.onRouteChangeAfterview();
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/profile-management/bookings') {
        }
      }
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (!this.routeChangeCalled) {
        this.routeChangeCalled = true;
        this.onRouteChange(event);
      }
    });
    // setTimeout(() => {
      let location: any = localStorage.getItem('location');
      this.http.post(environment.apiUrl + `/locations/list`, {sortColumn: 'locationname', sort: 'ASC', count: 500 }, { responseType: 'json' }).subscribe(async (data: any) => {
        if (location != null && location != undefined && location != "") {
          this.selectedLocation = JSON.parse(location).id;
          this.cdr.detectChanges();
        }
        this.availableLocations = data.locations;
        this.allLocations.push(this.availableLocations)
      })
      if (location == null || location == undefined || location == "") {
        // setTimeout(() => {
        // if(!this.router.url.includes('bookings') && !this.router.url.includes('GenerateQrCode') && !this.router.url.includes('agentbooking') && !this.router.url.includes('partneractivities')  && !this.router.url.includes('partnerbooking') && (!this.bookingSource || this.UserType == 'partner') &&   this.auth.viewHeader&& this.linkFrom != 'merchant'){
        //   this.searchLocation(event);
        // }
        // });
      } else {
        let locationDetails = JSON.parse(location);
        this.locationName = locationDetails.locationname;
        this.dataservice.changelocationSource(location);
        this.homeNavigation = '/home/location/'+locationDetails.id +'/'+locationDetails.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
      }
    // },2000)

    this.route.queryParams.subscribe(params => {
      this.linkFrom = params['linkfrom'];
      if (localStorage.getItem('linkFrom') == 'merchant') {
        this.linkFrom = 'merchant';
      }
    })

    this.dataservice.currentLocationSource.subscribe((location) => {
      if (location != null && location != "") {
        let parsedLocation =  JSON.parse(location)
        this.locationName = parsedLocation.locationname;
        this.selectedLocation = parsedLocation.id;
        this.cdr.detectChanges();
        this.homeNavigation = '/home/location/'+parsedLocation.id +'/'+parsedLocation.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
      }
      this.route.queryParams.subscribe(async (params) => {
        if (params['q']) {
          this.location = params['q'];
        }
      })
    })
    this.userAgent = window.navigator.userAgent;
    switch (this.getParameterByName('dialogue')) {
      case 'user':
        if (!this.userId) {
          this.openLoginDialog();
        }
        break;
      case 'signupuser':
        if (!this.userId) {
          this.openSignInDialog();
        }
        break;
      case 'contactus':
        this.openContactUsForm();
        break;
      case 'partner':
        this.loginPartner();
        break;
    }

    this.currentPlaceholder = this.placeholders[0]; // Initial placeholder

    setInterval(() => {
      this.getNextPlaceholder();
    }, 2000);
  }

  // getDataFromApi(location: string): void {
  //   // /locations/search

  //   console.log(location, "location");
  //   // const filterValue =this.emailForm.get(controlName).value.toLowerCase();
  //   // if (!filterValue) {
  //   //   this.filteredOptions = [];
  //   //   return;
  //   // }
  //   // this.http.get(environment.apiUrl +`/locations/search`, {responseType: 'json' }).subscribe({
  //   //   next: (data: any) => {
  //   //     console.log("data",data);

  //   //   }})
  // }
  // filterLocations(searchText: string): void {
  //   this.filteredLocations = this.availableLocations.filter(
  //     loc => loc.locationname.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   console.log(this.filteredLocations);

  // }

  // selectLocation(location: any): void {
  //   this.selectedLocation = location.id;
  //   this.location = location.locationname;
  //   this.filteredLocations = [];
  //   console.log(location);

  // }

  // clearFilteredLocations(): void {
  //   setTimeout(() => {
  //     this.filteredLocations = [];
  //   }, 200);
  //   console.log( this.filteredLocations );

  // }
  // populateAllLocations(): void {

  //   this.filteredLocations =this.allLocations; // Copy allLocations to filteredLocations initially
  //   console.log( this.filteredLocations);

  // }

  // filterLocations(searchText: string): void {
  //   if (!searchText) {
  //     this.filteredLocations = this.allLocations; // Reset to show all locations when input is empty
  //     console.log( this.filteredLocations);
  //     return;
  //   }

  //   this.filteredLocations = this.allLocations.filter(
  //     loc => loc.locationname.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   console.log( this.filteredLocations);
  // }
  onRouteChangeAfterview() {
    let location: any = localStorage.getItem('location');
    if (location == null || location == undefined || location == "") {
      setTimeout(() => {
       let url = window.location.pathname;
        if (!url.includes('landing-page') && !url.includes('blogs') && !url.includes('paynow') && !url.includes('home/location') && !url.includes('bookings') && !url.includes('generate-qr-code') && !url.includes('agentbooking') && !url.includes('partneractivities') && !url.includes('partnerbooking') && (!this.bookingSource || this.UserType == 'partner') && this.auth.viewHeader && this.linkFrom != 'merchant') {
          this.searchLocation();
        }
      });
    }
  }

  onRouteChange(event: NavigationEnd) {
    let location: any = localStorage.getItem('location');
    if (location == null || location == undefined || location == "") {
      setTimeout(() => {
        if (!event.url.includes('landing-page') && !event.url.includes('blogs') && !event.url.includes('paynow') && !event.url.includes('home/location') && !event.url.includes('bookings') && !event.url.includes('generate-qr-code') && !event.url.includes('agentbooking') && !event.url.includes('partneractivities') && !event.url.includes('partnerbooking') && (!this.bookingSource || this.UserType == 'partner') && this.auth.viewHeader && this.linkFrom != 'merchant') {
          this.searchLocation();
        }
      });
    }
  }

  getNextPlaceholder() {
    this.currentPlaceholderIndex = (this.currentPlaceholderIndex + 1) % this.placeholders.length;
    this.currentPlaceholder = this.placeholders[this.currentPlaceholderIndex];

  }

  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  openSocialMedia(url: any) {
    const windowName = 'CustomWindow';
    if (this.ifMobileApp()) {
      this.inAppBrowserRef = (window as any).cordova.InAppBrowser.open(url, '_system', 'location=yes');
    } else {
      window.open(url, windowName, 'width=800,height=600');
    }
  }

  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  openProfile() {
    if (!this.ifMobileApp()) {
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const queryParams = Object.fromEntries(searchParams.entries());
      const currentUrl = this.router.url.split('?')[0];
      let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
      q = q !== '' ? q + '&dialogue=user' : q + `dialogue=user`
      window.location.href = `${currentUrl}?${q}`;
    } else {
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '90vh',
        width: '40%',
        maxWidth: 'unset',
        panelClass: 'bg-login-class'
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          panelClass: 'bg-login-class'
        }
      }
      this.dialog.open(LoginDialogComponent, {
        ...sizes,
        disableClose: true,
        data: {

        },
      });
    }
  }


  openLoginDialog() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-login-class'
      }
    }
    this.dialog.open(LoginDialogComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }
  openSignInDialog() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '50%',
      maxWidth: 'unset',
      panelClass: 'bg-sign-up-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-sign-up-class'
      }
    }
    this.dialog.open(SignUpComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  navigateToHome() {
    sessionStorage.clear();
  }

  signout() {
    this.auth.logout();
    window.location.href = '/index.html';
    // this.router.navigate(['/index.html'])
    // this.router.navigate(['/home']).then(() => {
    // if(!this.ifMobileApp()){
    //   window.location.reload();
    // }else{

    // }
    // });
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const webNavbarCollapse = this.elementRef.nativeElement.querySelector('.navbar-collapse.web');
    if (webNavbarCollapse !== null && !webNavbarCollapse.contains(target) && !webNavbarCollapse.isSameNode(target)) {
      webNavbarCollapse.classList.remove('show');
    }
    const navbarCollapse = this.elementRef.nativeElement.querySelector('.navbar-collapse.app');
    if (navbarCollapse !== null && !navbarCollapse.contains(target) && !navbarCollapse.isSameNode(target)) {
      navbarCollapse.classList.remove('show');
    }
  }

  // navbarHidden: boolean = false; // Flag to track if the navbar should be hidden

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event: any) {
  //   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  //   // Set the navbarHidden flag based on the scroll position
  //   this.navbarHidden = scrollPosition > 0;
  // }


  openContactUsForm() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(ContactUsFormComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  location: string | undefined;
  onLocationChange() {
    if(this.selectedLocation ==  ""){
      this.locationInputFrom = this.location;
      this.searchLocation();
      return
    }
    if (this.location && this.location.trim() !== '') {
      this.http.post(environment.apiUrl + `/usersearch/create`, { userId: (this.userId) ? this.userId : 0, page: 'home', searchData: this.location }, { responseType: 'json' }).subscribe(async (data: any) => { })
      this.router.navigate(['/search'], { queryParams: { q: this.location } });
    } else {
      this.router.navigate(['/search']);
    }
  }


  highlightInputGroup(para: any) {
    const inputGroup: any = document.querySelector(`.input-group.${para}`);
    inputGroup.classList.add('highlight-input-group');
  }

  removeHighlightInputGroup(para: any) {
    const inputGroup: any = document.querySelector(`.input-group.${para}`);
    inputGroup.classList.remove('highlight-input-group');
  }


  loginPartner() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
      panelClass: 'bg-partner-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-partner-class'
      }
    }
    this.dialog.open(PartnerLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }
  async getLocation(){
    const url = window.location.href;
    if (url.includes('/location')) {
      const parts = url.split('/');
      const locationId = parts[parts.indexOf('location') + 1];

      const storedLocation = localStorage.getItem('location');
      if (storedLocation || storedLocation === undefined || storedLocation === null) {
        this.fetchLocationData(locationId);
        this.displayForm = true;
        this.applyBackdropClass();
      } else {
        this.displayForm = false;
          try {
            const response: any = await this.http.post(environment.apiUrl + `/locations/list`, {
              sortColumn: 'locationname',
              sort: 'ASC',
              count: 500
            }, { responseType: 'json' }).toPromise();
        
            this.location = response.locations;
          } catch (error) {
            console.error('Error fetching locations:', error);
          }
        
      }
    }

  }
  fetchLocationData(id: string): void {
    this.http.get(environment.apiUrl + `/locations/get?id=${id}`).subscribe(async (data: any) => {
      if (data.location && data.location.id) {
        localStorage.setItem('location', JSON.stringify(data.location));
        this.dataservice.changelocationSource(JSON.stringify(data.location));
        let locationDetails = data.location;
        this.homeNavigation = '/home/location/'+locationDetails.id +'/'+locationDetails.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
        this.displayForm = true; 
        this.applyBackdropClass();
      } else{
        this.displayForm = false;
      }
    });
  }

  private  applyBackdropClass() {
    const overlayBackdrop = document.querySelector('.cdk-overlay-backdrop');
    if (overlayBackdrop) {
      if (this.displayForm) {
        this.renderer.addClass(overlayBackdrop, 'custom-backdrop-hide');
        this.renderer.removeClass(overlayBackdrop, 'cdk-overlay-backdrop-showing');
      }
       else {
        this.renderer.removeClass(overlayBackdrop, 'custom-backdrop-hide');
        this.renderer.addClass(overlayBackdrop, 'cdk-overlay-backdrop-showing');
        const htmlElement = this.renderer.selectRootElement('html', true);
        this.renderer.removeClass(htmlElement, 'cdk-global-scrollblock');
        // this.renderer.addClass(htmlElement, 'pointer');

      }
    }
  }
  searchLocation(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: 'fit-content',
      maxWidth: 'unset',
      position: { top: '15%' }
    };
    if (isMobile) {
      sizes = {
        height: 'fit-content',
        width: '90%',
        maxWidth: 'unset',
        position: { top: '15%' }
      }
    }
    this.getLocation()
    this.dialog.open(FetchLocationComponent, {
      ...sizes,
      disableClose: true,
      data: {
        displayForm: this.displayForm,
        locationInputFrom:this.locationInputFrom
      },
    });
    this.locationInputFrom ="";
  }

  stingifyLocation(location: any) {
    return JSON.stringify(location);
  }

  stopClickPropagation(event: Event): void {
    event.stopPropagation();
  }

  changeSearchLocation() {
    localStorage.setItem('location', JSON.stringify(this.availableLocations.find((x: any) => x.id == this.selectedLocation)));
    this.dataservice.changelocationSource(JSON.stringify(this.availableLocations.find((x: any) => x.id == this.selectedLocation)));
    let locationDetails = this.availableLocations.find((x: any) => x.id == this.selectedLocation);
    if(locationDetails.status != 1){
      this.homeNavigation = '/home/location/'+locationDetails.id +'/'+locationDetails.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    }
    if (!this.router.url.includes('search')) {
      sessionStorage.clear();
      let location = this.availableLocations.find((x: any) => x.id == this.selectedLocation);
      if(location.status != 1){
        this.router.navigate(['/service-unavailable']);
      }else{
      this.router.navigate(['/home/location/'+location.id+'/'+location.locationname.trimEnd().toLowerCase().replaceAll(" ", "-")]);
      }
    }
  }
 
  guestLogin() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-guest-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-guest-login-class'
      }
    }
    this.dialog.open(GuestLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  openMerchantLoginDialogue() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-merchant-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-merchant-login-class'
      }
    }
    this.dialog.open(MerchantLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

}
