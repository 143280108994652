<button type="button" class="btn-close mt-3 ms-3" (click)="closeProvidedByDialog()" aria-label="Close"></button>
<h1 mat-dialog-title class="text-center mb-sm-3 mb-0 full-screen-dialog">{{title}}</h1>
<div mat-dialog-content class="overflow-auto">
  <!-- Provided by -->
  <h1 class="title p-sm-3 p-2 mb-0"> Provided by</h1>
  <h1 class="text-center mt-3" *ngIf="merchantData?.length === 0">No merchants provide this activity.</h1>
  <div *ngIf="merchantData?.length > 0">
    <div class="childs " *ngFor="let data of  merchantData;  let i = index">
      <div class="row mt-3 px-sm-4">
        <div class="col-md-6">
          <div class="card border-0 shadow h-100" style="border-radius: 25px;">
            <div class="card-body p-2">
              <div class="row h-100">
                <div class="col-6">
                  <div (click)="openMerchant(data.id)" class="w-100 h-100">
                    <img class="card-img-divs" src="assets/default-profile-pic.svg" *ngIf="!data.src[0]" alt="profile"/>
                    <img class="card-img-divs" [src]="data.src[0]" *ngIf="data.src[0]" alt="profile"/>
                  </div>
                </div>
                <div class="col-6">
                  <div class="ms-0">
                    <div class="d-flex justify-content-between align-items-center mt-2">
                      <h6 class="my-1 name text-truncate">{{data.name}}</h6>
                      <div class="d-flex align-items-center ms-1">
                        <!-- <ngb-rating [max]="1" [rate]="1"></ngb-rating> -->
                        <img src="/assets/home/like_icon.svg" class="like-icon" alt="like icon">
                        <h3 class="mb-0 ms-2 avg-review fw-bold">{{data.rating}}</h3>
                      </div>
                    </div>
                    <div class="mb-3 mt-2 location-name"><img src="assets/location-default.svg" alt="location"><span class="ms-2 text-break">{{data.city }}</span></div>
                    <div class="price"><img src="assets/rupee-symbol.svg" alt="rupee"><span class="ms-2 fw-bold">{{data.price}}</span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3 me-1">
                      <button class="btn book-btn text-white px-4" (click)="bookMerchant(data.id, activityId)">Book Now</button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-md-0 mt-3">
          <div class="card border-0 shadow h-100" style="border-radius: 25px;">
            <div class="card-body p-3 align-items-center d-flex">
              <table>
                <tr>
                  <th class="activity-title"><img class="me-2 " style="width: 25px;"
                      src="../../assets/time.svg" alt="time">Activity Time</th>
                  <td class="me-5 p-3 activity-details">{{data.timefrom}} <span *ngIf="data.timefrom !== data.timeto">- {{ data.timeto }}</span></td>
                </tr>
                <tr>
                  <th class="activity-title"><img class="me-2" style="width: 25px;"
                      src="../../assets/duration.svg" alt="duration">Activity Duration</th>
                  <td class="me-5 p-3 activity-details">{{data.duration}}</td>
                </tr>
                <tr>
                  <th class="activity-title"><img class="me-2" style="width: 25px;" src="../../assets/reach.svg" alt="reach">How to
                    Reach</th>
                  <td class="me-5 p-3 "><img src="../../assets/car.svg" alt="car"><img class="ms-2" src="../../assets/bus.svg" alt="bus"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loadMoreMerchants" class="mt-1 mb-2 d-flex align-items-center flex-column">
      <div (click)="showMoreMerchants()" class="pointer">
        <h5 class="mb-2" style="width: fit-content;">Show more</h5>
        <img src="../../assets/down-arrow.svg" class="d-block mx-auto" style="width:fit-content;" alt="downArrow" />
      </div>
  </div>
  </div>