<div #mapboxContainer></div>

<section class="fixed-margin-top" id="all">
  <div class="position-relative">
    <!-- <div class="form-group  w-50 form-banner mx-auto">
    <div class="input-group mt-2">
      <div class="input-group-prepend">
        <span class="input-group-text p-1 input-left-group opacity">
          <img src="../../assets/search.svg" width="30px" alt="Image">
        </span>
      </div>
      <input type="text" class="form-control input-right-group w-50 opacity" placeholder="Search  Activities, Locations"
        [(ngModel)]="location" name="location" (keyup.enter)="onLocationChange()">
    </div>
  </div> -->
    <div class="w-100 mt-2" *ngIf="hasCoupon">
      <div class="mx-auto d-flex flat-discount-card py-2 justify-content-center align-items-center">
        <img src="/assets/discount-percentage.svg" alt="discount">
        <div>
          <h6 class="mb-0 ms-2 flat-discount text-white">Flat {{couponPercentage}}% Off</h6>
          <h6 class="text-white ms-2 discount-applied mb-0">Auto Applied at Checkout</h6>
        </div>
      </div>
    </div>
    <!-- Icons -->
    <div id="list-catogory h-auto" class="mt-md-4 mt-1">
      <div class="parent" #elemt0 (mousedown)="startDragging($event, false, elemt0,'mouse', child0,'')"
        (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
        (mousemove)="moveEvent($event, elemt0,'mouse', child0)">

        <div class="child  d-flex justify-content-center " #child0>
          <div class="category rounded-pill px-sm-4 px-3  py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            [class.active-category]="active_category === 'rafting'" *ngIf="!indexLoading && indexData.rafting > 0"
            (click)="showPhase('rafting')">
            <div class="text-nowarp d-grid align-items-center gap-2"><img src="/assets/activity.svg"
                *ngIf="active_category !== 'landing-page'" [class.active-category-icon]="active_category === 'rafting'"
                class="icon mx-auto d-block" alt="icon">
              <img src="/assets/rafting.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="mb-0 text-nowarp">Rafting</span>
            </div>
          </div>
          <div class="category rounded-pill px-sm-4 px-3 py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            [class.active-category]="active_category === 'bike-rental'" *ngIf="!indexLoading && indexData.bikerental >0"
            (click)="showPhase('bike-rental')">
            <div class="text-nowarp  d-grid align-items-center gap-2">
              <img src="/assets/bike-rental-icon.svg" [class.active-category-icon]="active_category === 'bike-rental'"
                *ngIf="active_category !== 'landing-page'" class="icon mx-auto  d-block" alt="icon">
              <img src="/assets/bike-rental.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="mb-0 text-nowarp">Bike Rentals</span>
            </div>
          </div>
          <div class="category rounded-pill px-sm-4 px-3 py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            [class.active-category]="active_category === 'camping'" *ngIf="!indexLoading && indexData.camping >0"
            (click)="showPhase('camping')">
            <div class="text-nowarp  d-grid align-items-center gap-2"><img src="/assets/camping-icon.svg"
                *ngIf="active_category !== 'landing-page'" class="icon mx-auto d-block"
                [class.active-category-icon]="active_category === 'camping'" alt="icon">
              <img src="/assets/camping.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="mb-0 text-nowarp">Camping</span>
            </div>
          </div>
          <div class="category rounded-pill px-sm-4 px-3 py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            [class.active-category]="active_category === 'trekking'" *ngIf="!indexLoading && indexData.trekking >0"
            (click)="showPhase('trekking')">
            <div class="text-nowarp  d-grid align-items-center gap-2"><img src="../../assets/home/trekking-1.svg"
                [class.active-category-icon]="active_category === 'trekking'" *ngIf="active_category !== 'landing-page'"
                class="icon mx-auto  d-block" alt="icon">
              <img src="/assets/trekking.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="mb-0 text-nowarp">Trekking</span>
            </div>
          </div>
          <div class="category rounded-pill px-sm-4 px-3 py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            [class.active-category]="active_category === 'tour'" *ngIf="!indexLoading && indexData.tour >0"
            (click)="showPhase('tour')">
            <div class="text-nowarp  d-grid align-items-center gap-2"><img src="/assets/bike-tour-icon.svg"
                *ngIf="active_category !== 'landing-page'" [class.active-category-icon]="active_category === 'tour'"
                class="icon mx-auto   d-block" alt="icon">
              <img src="/assets/bike-tour.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="mb-0 text-nowarp">Bike Tours</span>
            </div>
          </div>
          <div class=" category rounded-pill px-sm-4 px-3 py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            [class.active-category]="active_category === 'quickactivities'"
            *ngIf="!indexLoading && indexData.quickactivities >0" (click)="showPhase('quickactivities')">
            <div class="text-nowarp  d-grid align-items-center gap-2"><img src="/assets/home/quickActivities.svg"
                *ngIf="active_category !== 'landing-page'" class="icon mx-auto  d-block" alt="icon"
                [class.active-category-icon]="active_category === 'quickactivities'" /> <img
                src="/assets/quick-adventures.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="mb-0 text-nowarp">Quick Adventures</span>
            </div>
          </div>
          <div class="category rounded-pill px-3 py-1" [class.px-sm-4]="active_category !== 'landing-page'"
            *ngIf="!indexLoading && indexData.topadventurists>0"
            [class.active-category]="active_category === 'topAdventurists'" (click)="showPhase('topAdventurists')"
            style="display: inline-block;">
            <div class="text-nowarp  d-grid align-items-center gap-2" style="display: inline-block;"><img
                src="/assets/quick-activity.svg" class="icon mx-auto  d-block"
                *ngIf="active_category !== 'landing-page'"
                [class.active-category-icon]="active_category === 'topAdventurists'" alt="icon"
                style="display: inline-block;">
              <img src="/assets/top-adventurists.svg" *ngIf="active_category === 'landing-page'"
                class="icon-landing mb-0 mx-auto d-block" alt="icon" />
              <span class="text-nowarp" style="display: inline-block;">Top Adventurists</span>
            </div>
          </div>
        </div>
      </div>
    </div>
   <!-- Filters -->
    <div class="w-100 d-flex justify-content-center pt-2" *ngIf="active_category === 'rafting'">
      <form class="rafting-form mx-md-0 mx-2" (submit)="searchRaftingMerchants()" [formGroup]="raftingForm">
        <div class="row mx-auto  p-1" [ngClass]="{'shadow': showShadow}">
          <div class="position-relative text-center px-0  size">
            <div class="d-flex form-control input-bg-color " (click)="toggleDateField()" *ngIf="!showDateField">
              <img src="../../assets/filter.svg" alt="filter" class="position-absolute filter">
              <label for="" class="labelname fw-bold ms-2">Filter</label>
            </div>
            <div class="d-flex " *ngIf="showDateField">
              <img src="../../assets/filter.svg" alt="filter" class=" d-flex  position-absolute filter">
              <label for="" class="labelname fw-bold ms-2 colum">Filter</label>
            </div>
            <img src="../../assets/arrow-up1.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="arrow" *ngIf="showDateField" (click)="toggleDateField()">
            <img src="../../assets/down-arrow.svg" id="downArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="downArrow" *ngIf="!showDateField" (click)="toggleDateField()">
          </div>
          <div [class.hidden]="!showDateField" class="row mx-auto ms-0  p-3 pt-0">
            <div class="border-lines  auto"></div>
            <div class="col-6 col-md-3">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Date</label>
                <input type="text" class="form-control d-flex text-center input-bg-color datepicker" (keydown)="preventToggleDefault($event)"
                  [ngClass]="{ 'is-invalid': isSubmittingRafting && r['date'].errors }" formControlName="date"
                  placeholder="Date" [min]="startDate" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
                <div *ngIf="isSubmittingRafting && r['date'].errors" class="invalid-feedback">
                  <div *ngIf="r['date'].errors['required']">Date is required</div>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Distance</label>
                <select name="book-time" id="distance" class="form-control text-center input-bg-color"
                  (change)="onChangeRaftingValues()" formControlName="distance"
                  [ngClass]="{ 'is-invalid': isSubmittingRafting && r['distance'].errors }"
                  (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option value="9 Km">9 Km</option>
                  <option value="16 Km">16 Km</option>
                  <option value="26 Km">26 Km</option>
                  <option value="35 Km">35 Km</option>
                </select>
                <div *ngIf="isSubmittingRafting && r['distance'].errors" class="invalid-feedback">
                  <div *ngIf="r['distance'].errors['required']">Distance is required.</div>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Time</label>
                <select name="book-time" id="book-time" class="form-control text-center input-bg-color"
                  formControlName="time" [ngClass]="{ 'is-invalid': isSubmittingRafting && r['time'].errors }"
                  (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}
                  </option>
                </select>
                <div *ngIf="isSubmittingRafting && r['time'].errors" class="invalid-feedback">
                  <div *ngIf="r['time'].errors['required']">Time is required</div>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Guests</label>
                <input type="text" class="form-control text-center input-bg-color"
                  [ngClass]="{ 'is-invalid': isSubmittingRafting && r['guest'].errors }" formControlName="guest"
                  placeholder="Total adults" (click)="preventToggle($event)" />
                <div *ngIf="isSubmittingRafting && r['guest'].errors" class="invalid-feedback">
                  <div *ngIf="r['guest'].errors['pattern']">Guests should not be Zero & not be greater than 9.</div>
                  <div *ngIf="r['guest'].errors['required']">No of adult is required</div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <!-- <button type="reset" class="btn reset-btn px-5 mx-2">Reset</button> -->
              <button type="submit" class="btn search-btn fw-bold px-md-5 border-0">Search</button>
            </div>
          </div>
        </div>

      </form>
    </div>
    <div class="w-100 d-flex justify-content-center pt-2" *ngIf="active_category === 'bike-rental'">
      <form (submit)="searchRentalMerchants()" class="rental-form mx-md-0 mx-2" [formGroup]="rentalForm">
        <!-- <div class="row mx-3  justify-content-center">
        <ng-container  formArrayName="inventoryvariations" >
          <div class="col-md-3 col-12" *ngFor="let inventoryVariation of rentalInventoryVariationsArrayControls; let i = index" [formGroupName]="i">
            <div class="form-group mt-2">
              <label class="ms-3 fw-bold text-truncate" for="" >{{inventoryVariations[i].type}}</label>
              <select  class="form-control rounded-pill input-bg-color  text-center" [attr.variationid]="inventoryVariations[i].id" (change)="changeInventoryVariation(i)"
                formControlName="variationvalueid" [ngClass]="{ 'is-invalid': isSubmittingBikerental && inventoryVariation.get('variationvalueid')?.errors?.['required'] }">
                <option value="">Select</option>
                <option *ngFor="let variationValue of inventoryVariationValues[i]" [value]="variationValue.id">{{ variationValue.value }}</option>
              </select>
              <div *ngIf="isSubmittingBikerental && inventoryVariation.get('variationvalueid')?.errors?.['required']" class="invalid-feedback">
                <div *ngIf="inventoryVariation.get('variationvalueid')?.errors?.['required']">This field is required</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div> -->

        <div class="row mx-auto  p-1" [ngClass]="{'shadow': showShadow}">
          <div class="position-relative text-center px-0 size ">
            <div class="d-flex form-control input-bg-color " (click)="toggleDateField()" *ngIf="!showDateField">
              <img src="../../assets/filter.svg" alt="filter" class="position-absolute filter">
              <label for="" class="labelname fw-bold ms-2">Filter</label>
            </div>
            <div class="d-flex " *ngIf="showDateField">
              <img src="../../assets/filter.svg" alt="filter" class=" d-flex  position-absolute filter">
              <label for="" class="labelname fw-bold ms-2 colum">Filter</label>
            </div>
            <img src="../../assets/arrow-up1.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="arrow" *ngIf="showDateField" (click)="toggleDateField()">
            <img src="../../assets/down-arrow.svg" id="downArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="downArrow" *ngIf="!showDateField" (click)="toggleDateField()">
          </div>
          <div [class.hidden]="!showDateField" class="row mx-auto ms-0  p-3 pt-0">
            <div class="border-lines  auto"></div>
            <div class="col-md-3 col-6">
              <div class="form-group mt-2">
                <label class="ms-2 fw-bold text-truncate" for="">Pickup Date</label>
                <input type="text" class="form-control text-center input-bg-color datepicker" (keydown)="preventToggleDefault($event)"
                  [ngClass]="{ 'is-invalid': isSubmittingBikerental && br['date'].errors }" formControlName="date"
                  placeholder="Date" [min]="startDate" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
                <div *ngIf="isSubmittingBikerental && br['date'].errors" class="invalid-feedback">
                  <div *ngIf="br['date'].errors['required']">Pickup Date is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="form-group mt-2">
                <label class="ms-2 fw-bold  text-truncate" for="">Pickup Time</label>
                <select name="book-time" class="form-control text-center input-bg-color" formControlName="time"
                  [ngClass]="{ 'is-invalid': isSubmittingBikerental && br['time'].errors }"
                  (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}</option>
                </select>
                <div *ngIf="isSubmittingBikerental && br['time'].errors" class="invalid-feedback">
                  <div *ngIf="br['time'].errors['required']">Pickup Time is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="form-group mt-2">
                <label class="ms-2 fw-bold text-truncate" for="">Drop Off Date</label>
                <input type="text" class="form-control text-center input-bg-color datepicker1" (keydown)="preventToggleDefault($event)"
                  [ngClass]="{ 'is-invalid': isSubmittingBikerental && br['dropdowndate'].errors }"
                  formControlName="dropdowndate" placeholder="Date" [min]="startDate" [(ngModel)]="dropDate"
                  (click)="preventToggle($event)" />
                <div *ngIf="isSubmittingBikerental && br['dropdowndate'].errors" class="invalid-feedback">
                  <div *ngIf="br['dropdowndate'].errors['required']">Drop Off Date is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="form-group mt-2">
                <label class="ms-2 fw-bold  text-truncate" for="">Drop Off Time</label>
                <select name="book-time" class="form-control text-center input-bg-color" formControlName="dropdowntime"
                  [ngClass]="{ 'is-invalid': isSubmittingBikerental && br['dropdowntime'].errors }"
                  (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let timing of newTimings" [disabled]="isPastDateTimeDrop(timing)">{{ timing }}
                  </option>
                </select>
                <div *ngIf="isSubmittingBikerental && br['dropdowntime'].errors" class="invalid-feedback">
                  <div *ngIf="br['dropdowntime'].errors['required']">Drop Off Time is required</div>
                </div>
              </div>
            </div>
            <ng-container formArrayName="variations">
              <div class="col-md-3 col-6" *ngFor="let variation of rentalVariationsArrayControls; let i = index"
                [formGroupName]="i">
                <div class="form-group mt-2">
                  <label class="ms-2 fw-bold text-truncate" for="">{{variations[i].variations.type}}</label>
                  <select class="form-control input-bg-color text-center"
                    [attr.variationid]="variations[i].variations.id" (change)="changeRentalDefaultVariation(i)"
                    formControlName="variationvalueid"
                    [ngClass]="{ 'is-invalid': isSubmittingBikerental && variation.get('variationvalueid')?.errors?.['required'] }"
                    (click)="preventToggle($event)">
                    <option value="">Select</option>
                    <option *ngFor="let variationValue of variationValues[i]"
                      [value]="variationValue.variationvalues.id">
                      {{
                      variationValue.variationvalues.value }}</option>
                  </select>
                  <div *ngIf="isSubmittingBikerental && variation.get('variationvalueid')?.errors?.['required']"
                    class="invalid-feedback">
                    <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">This field is required</div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="text-center mt-3">
              <!-- <button type="reset" class="btn reset-btn px-5 mx-2">Reset</button> -->
              <button type="submit" class="btn search-btn fw-bold px-md-5 border-0">Search</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="w-100  d-flex justify-content-center pt-2" *ngIf="active_category === 'camping'">
      <form (submit)="searchCamping()" class="camping-form  mx-md-0" [formGroup]="campingForm">
        <div class="row mx-auto  p-1" [ngClass]="{'shadow': showShadow}">
          <div class="position-relative text-center px-0  size">
            <div class="d-flex form-control input-bg-color " (click)="toggleDateField()" *ngIf="!showDateField">
              <img src="../../assets/filter.svg" alt="filter" class="position-absolute filter">
              <label for="" class="labelname fw-bold ms-2">Filter</label>
            </div>
            <div class="d-flex " *ngIf="showDateField">
              <img src="../../assets/filter.svg" alt="filter" class=" d-flex  position-absolute filter">
              <label for="" class="labelname fw-bold ms-2 colum">Filter</label>
            </div>
            <img src="../../assets/arrow-up1.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="arrow" *ngIf="showDateField" (click)="toggleDateField()">
            <img src="../../assets/down-arrow.svg" id="downArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="downArrow" *ngIf="!showDateField" (click)="toggleDateField()">
          </div>
          <div [class.hidden]="!showDateField" class="row mx-auto ms-0 pb-3 px-md-3 px-1 pt-0">
            <div class="border-lines  auto"></div>
            <div class="row  mx-md-3 px-1 justify-content-center">

              <div class="col-md-4 col-4 px-md-2 px-1">
                <div class="form-group mt-2">
                  <label class="ms-2 fw-bold" for="">Check-In</label>
                  <input type="text" class="form-control px-md-2 px-1 text-center input-bg-color datepicker" (keydown)="preventToggleDefault($event)"
                    [ngClass]="{ 'is-invalid': isSubmittingCamping && ca['date'].errors }" formControlName="date"
                    placeholder="Date" [min]="startDate" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
                  <div *ngIf="isSubmittingCamping && ca['date'].errors" class="invalid-feedback">
                    <div *ngIf="ca['date'].errors['required']">Check-In is required</div>
                    <div *ngIf="ca['date'].errors['invalidCheckout']">Check-In should be less than Check-out.</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-4 px-md-2 px-1">
                <div class="form-group mt-2">
                  <label class="ms-2 fw-bold" for="book-date">Check-Out</label>
                  <input type="text" class="form-control px-md-2 px-1 text-center input-bg-color datepicker1" (keydown)="preventToggleDefault($event)"
                    [ngClass]="{ 'is-invalid': isSubmittingCamping && ca['checkoutdate'].errors }"
                    formControlName="checkoutdate" placeholder="Date" [min]="startDate" [(ngModel)]="currentDate1"
                    (click)="preventToggle($event)" />
                  <div *ngIf="isSubmittingCamping && ca['checkoutdate'].errors" class="invalid-feedback">
                    <div *ngIf="ca['checkoutdate'].errors['required']">Check-out is required</div>
                    <div *ngIf="ca['checkoutdate'].errors['invalidCheckout']">Check-out should be greater than Check-in.
                    </div>
                  </div>
                </div>
              </div>
              <ng-container formArrayName="variations">
                <div class="col-md-4 col-4 px-md-2 px-1"
                  *ngFor="let variation of campingVariationsArrayControls; let i = index" [formGroupName]="i">
                  <div class="form-group mt-2">
                    <label class="ms-2 fw-bold" for="">{{variations[i].variations.type}}</label>
                    <select class="form-control px-md-2 px-0 text-center input-bg-color"
                      [attr.variationid]="variations[i].variations.id" formControlName="variationvalueid"
                      [ngClass]="{ 'is-invalid': isSubmittingCamping && variation.get('variationvalueid')?.errors?.['required'] }"
                      (click)="preventToggle($event)">
                      <option value="">Select</option>
                      <option *ngFor="let variationValue of variationValues[i]"
                        [value]="variationValue.variationvalues.id">
                        {{
                        variationValue.variationvalues.value }}</option>
                    </select>
                    <div *ngIf="isSubmittingCamping && variation.get('variationvalueid')?.errors?.['required']"
                      class="invalid-feedback">
                      <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">This field is required</div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row mx-md-3 px-1 justify-content-center">
              <div class="col-4 px-md-2 px-1">
                <div class="form-group mt-2">
                  <label class="ms-2 fw-bold" for="">Adults</label>
                  <div class="input-group input-bg-color border-0">
                    <span class="input-group-btn my-auto ps-2 pointer" (click)="decreaseValue('adult')"
                      (click)="preventToggle($event)">
                      <img class="dec-img" src="/assets/minus.svg" width="20px" alt="minus" />
                    </span>
                    <input type="text" class="form-control input-number text-center font-size-14px" readonly
                      formControlName="adult" [(ngModel)]="adultsCount" min="1" max="10">
                    <span class="input-group-btn  my-auto pe-2 pointer" (click)="increaseValue('adult')"
                      (click)="preventToggle($event)">
                      <img class="plus-img" src="/assets/plus.svg" width="20px" alt="plus" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-4 px-md-2 px-1">
                <div class="form-group mt-2">
                  <label class="ms-2 fw-bold" for="">Children</label>
                  <div class="input-group input-bg-color border-0">
                    <span class="input-group-btn my-auto ps-2 pointer" (click)="decreaseValue('children')"
                      (click)="preventToggle($event)">
                      <img class="dec-img" src="/assets/minus.svg" width="20px" alt="minus" />
                    </span>
                    <input type="text" class="form-control input-number text-center font-size-14px" readonly
                      formControlName="children" [(ngModel)]="childrenCount" min="1" max="10">
                    <span class="input-group-btn  my-auto pe-2 pointer" (click)="increaseValue('children')"
                      (click)="preventToggle($event)">
                      <img class="plus-img" src="/assets/plus.svg" width="20px" alt="plus" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-4 px-md-2 px-1">
                <div class="form-group mt-2">
                  <label class="ms-2 fw-bold" for=""> Total Guests</label>
                  <input type="text" class="form-control  text-center input-bg-color guest-user-select"
                    style="background-color: white;" readonly formControlName="guest" [(ngModel)]="totalGuest"
                    (click)="preventToggle($event)" />
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <!-- <button type="reset" class="btn reset-btn px-5 mx-2">Reset</button> -->
              <button type="submit" class="btn search-btn fw-bold px-md-5 border-0">Search</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="w-100 d-flex justify-content-center pt-2 " *ngIf="active_category === 'trekking'">
      <form (submit)="submitTrekking()" class="trekking-form mx-md-0 mx-2" [formGroup]="trekkingForm">
        <div class="row mx-auto  p-1" [ngClass]="{'shadow': showShadow}">
          <div class="position-relative text-center px-0 size">
            <div class="d-flex form-control input-bg-color " (click)="toggleDateField()" *ngIf="!showDateField">
              <img src="../../assets/filter.svg" alt="filter" class="position-absolute filter">
              <label for="" class="labelname fw-bold ms-2">Filter</label>
            </div>
            <div class="d-flex " *ngIf="showDateField">
              <img src="../../assets/filter.svg" alt="filter" class=" d-flex  position-absolute filter">
              <label for="" class="labelname fw-bold ms-2 colum">Filter</label>
            </div>
            <img src="../../assets/arrow-up1.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="arrow" *ngIf="showDateField" (click)="toggleDateField()">
            <img src="../../assets/down-arrow.svg" id="downArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="downArrow" *ngIf="!showDateField" (click)="toggleDateField()">
          </div>
          <div [class.hidden]="!showDateField" class="row mx-auto ms-0  p-3 pt-0">
            <div class="border-lines  auto"></div>
            <div class="row" [class.hidden]="!loadingMonths && months.length == 0" >
            <div class="col-5 ">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Month</label>
                <select name="" class="form-control  input-bg-color  text-center " (change)="changeMonths()"
                  formControlName="month" (click)="preventToggle($event)">
                  <option value="">Month</option>
                  <option *ngFor="let month of months">{{ month }}</option>
                </select>
              </div>
            </div>

            <div class="col-7 ">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Available Slots</label>
                <select name="" class="form-control  input-bg-color  text-center "
                  [ngClass]="{ 'is-invalid': isSubmittingTrekking && f['slot'].errors }" formControlName="slot"
                  (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let slot of slots">{{ slot }}</option>
                </select>
                <div *ngIf="isSubmittingTrekking && f['slot'].errors" class="invalid-feedback">
                  <div *ngIf="f['slot'].errors['required']">Slot is required</div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <!-- <button type="reset" class="btn reset-btn px-5 mx-2">Reset</button> -->
              <button type="submit" class="btn search-btn fw-bold px-md-5 border-0">Search</button>
            </div>
          </div>
          <h3 class="text-center" [class.hidden]="!loadingMonths && months.length !=0">Currently no slots available!</h3>
          </div>
        </div>
      </form>
    </div>
    <div class="w-100  d-flex justify-content-center pt-2" *ngIf="active_category === 'tour'">
      <form (submit)="submitTour()" class="trekking-form mx-md-0 mx-2" [formGroup]="biketourForm">
        <div class="row mx-auto  p-1" [ngClass]="{'shadow': showShadow}">
          <div class="position-relative text-center px-0 size">
            <div class="d-flex form-control input-bg-color " (click)="toggleDateField()" *ngIf="!showDateField">
              <img src="../../assets/filter.svg" alt="filter" class="position-absolute filter">
              <label for="" class="labelname fw-bold ms-2">Filter</label>
            </div>
            <div class="d-flex " *ngIf="showDateField">
              <img src="../../assets/filter.svg" alt="filter" class=" d-flex  position-absolute filter">
              <label for="" class="labelname fw-bold ms-2 colum">Filter</label>
            </div>
            <img src="../../assets/arrow-up1.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="arrow" *ngIf="showDateField" (click)="toggleDateField()">
            <img src="../../assets/down-arrow.svg" id="downArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="downArrow" *ngIf="!showDateField" (click)="toggleDateField()">
          </div>
          <div [class.hidden]="!showDateField" class=" mx-auto ms-0  p-3 pt-0">
            <div class="border-lines  auto"></div>
            <div class="row" [class.hidden]="!loadingMonths && months.length == 0">
            <div class="col-5 ">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Month</label>
                <select name="" class="form-control  input-bg-color  text-center " (change)="changeMonths()"
                  formControlName="month" (click)="preventToggle($event)">
                  <option value="">Month</option>
                  <option *ngFor="let month of months">{{ month }}</option>
                </select>
              </div>
            </div>
            <div class="col-7 ">
              <div class="form-group mt-2">
                <label class="fw-bold ms-2" for="">Available Slots</label>
                <select name="" class="form-control  input-bg-color  text-center "
                  [ngClass]="{ 'is-invalid': isSubmittingBikeTour && bt['slot'].errors }" formControlName="slot"
                  (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let slot of slots">{{ slot }}</option>
                </select>
                <div *ngIf="isSubmittingBikeTour && bt['slot'].errors" class="invalid-feedback">
                  <div *ngIf="bt  ['slot'].errors['required']">Slot is required</div>
                </div>
              </div>
            </div>
            <!-- <ng-container formArrayName="variations">
            <div class="col-md-4 col-4 px-md-2 px-1"
              *ngFor="let variation of bikeTourVariationsArrayControls; let i = index" [formGroupName]="i">
              <div class="form-group mt-2">
                <label class="ms-2 fw-bold" for="">{{variations[i].variations.type}}</label>
                <select class="form-control px-md-2 px-0 text-center input-bg-color"
                  [attr.variationid]="variations[i].variations.id" formControlName="variationvalueid"
                  [ngClass]="{ 'is-invalid': isSubmittingBikeTour && variation.get('variationvalueid')?.errors?.['required'] }">
                  <option value="">Select</option>
                  <option *ngFor="let variationValue of variationValues[i]" [value]="variationValue.variationvalues.id">
                    {{
                    variationValue.variationvalues.value }}</option>
                </select>
                <div *ngIf="isSubmittingBikeTour && variation.get('variationvalueid')?.errors?.['required']"
                  class="invalid-feedback">
                  <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">This field is required</div>
                </div>
              </div>
            </div>
          </ng-container> -->

            <div class="text-center mt-3">
              <!-- <button type="reset" class="btn reset-btn px-5 mx-2">Reset</button> -->
              <button type="submit" class="btn search-btn fw-bold px-md-5 border-0">Search</button>
            </div>
          </div>
          <h3 class="text-center" [class.hidden]="!loadingMonths && months.length != 0">Currently no slots available!</h3>
          </div>
        </div>
      </form>
    </div>
    <div class="w-100 d-flex justify-content-center pt-2" *ngIf="active_category === 'quickactivities'">
      <form class="rafting-form mx-md-0 mx-3" (submit)="submitquickActivities()" [formGroup]="activitiesForm">
        <div class="row mx-auto  p-1" [ngClass]="{'shadow': showShadow}">

          <div class="position-relative text-center px-0 size">
            <div class="d-flex form-control input-bg-color " (click)="toggleDateField()" *ngIf="!showDateField">
              <img src="../../assets/filter.svg" alt="filter" class="position-absolute filter">
              <label for="" class="labelname fw-bold ms-2">Filter</label>
            </div>
            <div class="d-flex " *ngIf="showDateField">
              <img src="../../assets/filter.svg" alt="filter" class=" d-flex  position-absolute filter">
              <label for="" class="labelname fw-bold ms-2 colum">Filter</label>
            </div>
            <img src="../../assets/arrow-up1.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="arrow" *ngIf="showDateField" (click)="toggleDateField()">
            <img src="../../assets/down-arrow.svg" id="downArrow" class="ms-4 pointer up-arrow position-absolute"
              alt="downArrow" *ngIf="!showDateField" (click)="toggleDateField()">
          </div>
          <div [class.hidden]="!showDateField" class="row mx-auto ms-0  p-3 pt-0">
            <div class="border-lines  auto"></div>
            <div class="row mx-1 w-100  justify-content-center">
              <div class="col-6 col-md-4">
                <div class="form-group mt-2">
                  <label class="fw-bold ms-2" for="">Date</label>
                  <input type="text" class="form-control d-flex text-center input-bg-color datepicker" (keydown)="preventToggleDefault($event)"
                    formControlName="date" placeholder="Date" [min]="startDate" [(ngModel)]="currentDate" />

                </div>
              </div>

              <div class="col-6 col-md-4">
                <div class="form-group mt-2">
                  <label class="fw-bold ms-2" for="">Time</label>
                  <select name="book-time" id="book-time" class="form-control text-center input-bg-color"
                    formControlName="time">
                    <option value="">Select</option>
                    <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
            <div class="row mx-1 w-100  justify-content-center">
              <div class="col-md-6  col-12 ">
                <div class="form-group mt-2 position-relative dropdown">
                  <label class="fw-bold ms-2" for="activitySelect">Activities</label>
                  <select name="" id="activitySelect" class="input-bg-color text-center form-select form-control"
                    [ngClass]="{ 'is-invalid': isSubmittingActivities && a['activities'].errors }"
                    formControlName="activities" (change)="changeActivities($event.target.value)">
                    <option value="">select</option>
                    <option *ngFor="let activity of quickActivityTypes" [value]="activity.activitytypeid">
                      {{activity.activitytypemastername}}</option>
                  </select>
                  <div *ngIf="isSubmittingActivities && a['activities'].errors" class="invalid-feedback">
                    <div *ngIf="a['activities'].errors['required']">Activities is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <!-- <button type="reset" class="btn reset-btn px-5 mx-2">Reset</button> -->
              <button type="submit" class="btn search-btn fw-bold px-md-5 border-0">Search</button>
            </div>
          </div>

        </div>
      </form>
    </div>
   
    <!-- landing-page -->
    <div class="landing-page mb-4" *ngIf="active_category === 'landing-page'">
      <div class=" pt-0 px-md-5 ms-md-4 px-3" >
        <!-- for mobile -->
        <ngb-carousel class="landing-carousel-height mobile-carousel">
          <ng-template *ngFor="let data of landingPageImages" ngbSlide (click)="$event.stopPropagation()">
            <div class="wrapper">
              <img [src]="data.src" alt="image" class="image-data-src" >
              <a href="javascript:void(0)" *ngIf="data.url && data.url != ''"
                class="btn btn-success position-absolute landing-explore" (click)="openUrl(data.url)"
                style="right:10%;">Explore <img src="/assets/explore-arrow.svg" width="20px" alt="explore"></a>
            </div>
          </ng-template>
        </ngb-carousel>
        <!-- for desktop -->
        <div class="pt-0 desktop-carousel" >
          <ng-container *ngIf="landingPageImages.length > 0" >
            <!-- Case for 1 image -->
            <div *ngIf="landingPageImages.length === 1" style="height: 80vh;">
              <img [src]="landingPageImages[0].src" alt="image" class="image-height">
            </div>
            <!-- Case for 2 images -->
            <div *ngIf="landingPageImages.length === 2" class="grid-container-item" >
              <div *ngFor="let image of landingPageImages">
              <img [src]="image.src" alt="image"class="image-height">
              </div>
            </div>
            <!-- Case for 3 images -->
            <div  *ngIf="landingPageImages.length === 3" class="grid-container-three">
            <div *ngFor="let image of landingPageImages" class="items-grid">
              <img [src]="image.src" alt="image"class="image-height" >
              </div>
            </div>
            <!-- Case for 4 images -->
            <ng-container *ngIf="landingPageImages.length === 4" >
              <div class="grid-container-four">
                <div class="grid-item">
                <img [src]="landingPageImages[0].src" alt="image" class="image-fit" >
                <img [src]="landingPageImages[1].src" alt="image" class="image-fit ">
              </div>
              <div class="grid-item">
                <img [src]="landingPageImages[2].src" alt="image" class="image-fit">
                <img [src]="landingPageImages[3].src" alt="image" class="image-fit ">
              </div>
              </div>
            </ng-container>
            <!-- Case for 5 images -->
            <ng-container *ngIf="landingPageImages.length === 5 || landingPageImages.length > 5">
              <!-- grid -->
              <div class="grid-container">
                <div class="item1">
                  <img [src]="landingPageImages[0].src" alt="image" class="image-fit" >
                </div>
                <div class="item2">
                  <div class="grid-container-item p-0 ">
                    <img [src]="landingPageImages[1].src" alt="image" class="image-fit">
                    <img [src]="landingPageImages[2].src" alt="image" class="image-fit ">
                    <img [src]="landingPageImages[3].src" alt="image" class="image-fit">
                    <img [src]="landingPageImages[4].src" alt="image" class="image-fit ">
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="mb-3 row ms-md-4 px-md-5 ms-2">
          <h1 class="title p-sm-3 p-2 mt-md-2  mb-0 " >About {{locationname}} </h1>
          <div class="col-md-8 ps-md-3 px-2 px-md-0 pe-4">
            <p class=" px-0 " [innerHTML]="sanitizedDescription"></p>
          </div>
          <div class="col-md-4 px-2 pe-3">
            <div id="map" class="map pointer" (click)="dataService.openMap(latitude, longitude)">
            </div>
          </div>
      </div>
      <div class=" m-auto pt-3 pt-md-0 row px-md-5" *ngIf="landingPageData.length > 0">
        <div class="col-7  p-0">
          <h1 class="title p-sm-3 p-2 mt-md-2 ms-2 mb-0" for="activitySortby">Explore Activities</h1>
        </div>
        <div class="col-5 ps-0 p-sm-3 p-2 ">
          <div class="dropdown d-flex justify-content-end" >
            <button class="input-bg-color text-center form-select text-truncate ps-5 sort-dropdown-button" (click)="click_sort_by()" type="button"
              id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"> <img
                src="../../assets/home/SortIcon.svg" alt="SortIcon" class="sorticon">Sort
            </button>
            <ul class="dropdown-menu" id="sort_by_menu" style="cursor: context-menu;z-index: 4;" aria-labelledby="dropdownMenuButton">
              <li><a class="dropdown-item"[class.active]="selectedSortBy === 'Price - low to high'"
                  (click)="changeSortby('Price - low to high')">Price - low to high</a></li>
              <li><a class="dropdown-item" [class.active]="selectedSortBy === 'Price - high to low'"
                  (click)="changeSortby('Price - high to low')">Price - high to low</a></li>
              <li><a class="dropdown-item" [class.active]="selectedSortBy === 'Rating - high to low'"
                  (click)="changeSortby('Rating - high to low')">Rating - high to low</a></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- landing data -->
      <!-- <div class="row  m-auto px-md-5 mx-4">
        <div class="col-12 col-md-3 p-2" *ngFor="let data of  landingPageData; let j= index">
          <app-explore-activitie-card [data]="data" [exploreData]="dataFromPage"></app-explore-activitie-card>
         
       
       </div>
      </div> -->
      <div  class=" m-auto px-md-5 " *ngIf="raftingData && raftingData.length>0">
        <div class="d-flex justify-content-between mx-3">
          <div class="under-border">
              <h1 class="title py-2 mb-0 px-0 pointer px-md-4"  (click)="showPhase('rafting')">Explore River Rafting</h1>
          </div>
          <img src="../../assets/home/uparrow.png" alt="uparrow" class="showmore mt-md-3  mt-2 pointer m-md-4  m-2 "  (click)="showPhase('rafting')">
        </div>
        <div class="row ms-md-3 mt-2 mx-3 px-md-4">
          <div class="col-12 col-md-4 p-2" *ngFor="let data of  raftingData  | slice:0:3; let j= index" >
            <app-explore-activitie-card [data]="data" [exploreData]="dataFromPage"></app-explore-activitie-card>
          </div>
        </div>
      </div>
      <div  class=" m-auto px-md-5 " *ngIf="campingData && campingData.length>0">
        <div class="d-flex justify-content-between mx-3">
          <div class="under-border">
              <h1 class="title py-2 mb-0 px-0 px-md-4 pointer" (click)="showPhase('camping')">Explore Campings</h1>
          </div>
          <img src="../../assets/home/uparrow.png" alt="uparrow" class="showmore mt-md-3  mt-2 pointer m-md-4  m-2"  (click)="showPhase('camping')">
        </div>
        <div class="row ms-md-3 mt-2 mx-3 px-md-4" >
          <div class="col-12 col-md-4 p-2" *ngFor="let data of  campingData| slice:0:3; let j= index">
            <app-activity-camping-card [data]="data" [exploreData]="dataPage"></app-activity-camping-card>
          </div>
        </div>
      </div>
      <div  class=" m-auto px-md-5" *ngIf="bikeRentalData && bikeRentalData.length>0">
        <div class="d-flex justify-content-between mx-3">
          <div class="under-border" >
              <h1 class="title py-2 mb-0 px-0 px-md-4 pointer" (click)="showPhase('bike-rental')">Explore Bike Rentals</h1>
          </div>
          <img src="../../assets/home/uparrow.png" alt="uparrow" class="showmore mt-md-3  mt-2 pointer m-md-4  m-2"  (click)="showPhase('bike-rental')">
        </div>
        <div class="row ms-md-3 mt-2 mx-3 px-md-4">
          <div class="col-12 col-md-4 p-2" *ngFor="let data of  bikeRentalData| slice:0:3; let j= index" >
            <app-explore-activitie-card [data]="data" [exploreData]="dataFromPage" [categoryData]="categoryData"></app-explore-activitie-card>
          </div>
        </div>
      </div>
      <div  class=" m-auto px-md-5 " *ngIf="trekkingData && trekkingData.length>0">
        <div class="d-flex justify-content-between mx-3">
          <div class="under-border">
              <h1 class="title py-2 mb-0 px-0 pointer px-md-4"  (click)="showPhase('trekking')">Explore Trekkings</h1>
          </div>
          <img src="../../assets/home/uparrow.png" alt="uparrow" class="showmore mt-md-3  mt-2 pointer m-md-4  m-2"  (click)="showPhase('trekking')">
        </div>
   
        <div class="row ms-md-3 mt-2 mx-3 px-md-4">
          <div class=" col-12 col-md-4 p-2 "  *ngFor="let data of  trekkingData| slice:0:3; let j= index">
            <app-explore-activitie-card [data]="data" [exploreData]="dataFromPage"></app-explore-activitie-card>
          </div>
        </div>
      </div>
      <div  class=" m-auto px-md-5 " *ngIf="bikeTourData && bikeTourData.length>0">
        <div class="d-flex justify-content-between mx-3">
          <div class="under-border" >
              <h1 class="title py-2 mb-0 px-0 px-md-4 pointer" (click)="showPhase('tour')">Explore Bike Tours</h1>
          </div> 
          <img src="../../assets/home/uparrow.png" alt="uparrow" class="showmore mt-md-3  mt-2 pointer m-md-4  m-2"  (click)="showPhase('tour')">
        </div>
        <div class="row ms-md-3 mt-2 mx-3 px-md-4">
          <div class=" col-12 col-md-4 p-2 "  *ngFor="let data of  bikeTourData| slice:0:3; let j= index">
            <app-explore-activitie-card [data]="data"[exploreData]="dataFromPage" ></app-explore-activitie-card>
          </div>
        </div>
      </div>
      <div  class=" m-auto px-md-5 " *ngIf="quickActivitiesData && quickActivitiesData.length>0">
        <div class="d-flex justify-content-between mx-3">
          <div class="under-border">
              <h1 class="title py-2 mb-0 px-0 px-md-4 pointer"  (click)="showPhase('quickactivities')">Explore Quick Activities</h1>
          </div>
          <img src="../../assets/home/uparrow.png" alt="uparrow" class="showmore  mt-md-3 mt-2 pointer m-md-4  m-2"  (click)="showPhase('quickactivities')">
        </div>
        <div class="row ms-md-3 mt-2 mx-3 px-md-4">
          <div class="col-12 col-md-4 p-2" *ngFor="let data of  quickActivitiesData| slice:0:3; let j= index" >
            <app-explore-activitie-card [data]="data" [exploreData]="dataFromPage"></app-explore-activitie-card>
          </div>
        </div>
      </div>
    </div>
    <!--rafting -->
    <div class="rafting-section mb-4" *ngIf="active_category === 'rafting' && !raftingLoading && raftingData.length>0">
      <div *ngIf="!showFilteredRafting">
        <div class="row mobile-screen m-auto">
            <div class="col-12 col-md-6 my-1" *ngFor="let data of  raftingData; let j= index">
               <app-other-activities-card [data]="data"></app-other-activities-card>
            </div>
        </div>
      </div>
      <div *ngIf="showFilteredRafting && !loadingprovidedByRafting" class="px-3">
        <!-- <div class="mx-auto mobile-screen mt-2">
          <ngb-carousel *ngIf="filteredRaftingData[0].src" class="carousel-filter-height mt-3 mt-md-5 ">
            <ng-template *ngFor="let src of filteredRaftingData[0].src" ngbSlide (click)="$event.stopPropagation()">
              <div class="">
                <img [src]="src" alt="image" class="imageSrc">
              </div>
            </ng-template>
          </ngb-carousel> -->
          <div class="mx-auto mobile-screen">
            <ngb-carousel *ngIf="filteredRaftingData[0].src" class="carousel-height mt-0 mt-md-5">
              <ng-template *ngFor="let src of filteredRaftingData[0].src" ngbSlide (click)="$event.stopPropagation()">
                <div class="wrapper">
                  <img [src]="src" alt="image" class="imageSrc">
                </div>
              </ng-template>
            </ngb-carousel>
          <div class="my-2">
            <h1 class="title default-font-family pt-sm-3 mb-0">River Rafting
              <span
                *ngIf="filteredRaftingData[0].raftingkm !== '' && filteredRaftingData[0].raftingkm !== null">{{filteredRaftingData[0].raftingkm}}</span>
            </h1>
            <div class="my-3">
              <p class="container text-muted px-0 description" [innerHTML]="filteredRaftingData[0].description"></p>
            </div>
          </div>
          <div>
            <div id="activitymap" class="map pointer"
              (click)="dataService.openMap(filteredRaftingData[0].latitude, filteredRaftingData[0].longitude)"></div>
          </div>
        </div>
        <h1 class="mx-auto w-50 mobile-screen default-font-family title mt-2">Provided By</h1>
        <div class="mx-auto w-50 mobile-screen">
          <div class="mb-2 mt-3 mx-auto rafting-provided" *ngFor="let data of  providedByRafting;  let i = index">
            <div class="card border-0 shadow h-100" style="border-radius: 25px;">
              <div class="card-body p-2">
                <div class="row h-100">
                  <div class="col-6">
                    <div (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)" class="w-100 h-100 pointer position-relative">
                      <img class="card-img-divs" src="assets/default-profile-pic.svg" *ngIf="!data.shopsrc[0]"
                        alt="profile" />
                      <img class="card-img-divs" [src]="data.shopsrc[0]" *ngIf="data.shopsrc[0]" alt="profile" />
                     <div  *ngIf="data.hasCoupon" >
                        <div  class="position-absolute offer ">
                          <label  class="pointer px-2 offer-text">OFFER AVAILABLE</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 ps-1 ps-md-3">
                    <div class="ms-0">
                      <div class="d-flex justify-content-between align-items-center mt-2">
                        <h6 class="my-1 shopname default-font-family text-truncate pointer"
                          (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)">{{data.merchantshopname}} <img
                            src="assets/gg_link.svg" width="20px" class="location-icon" alt="gg_link"></h6>
                      </div>
                      <div class="activity-title text-truncate"
                        (click)="dataService.openMap(data.latitude, data.longitude)"
                        style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" class="location-icon"
                          width="30px" alt="location"> <span
                          class="text-decoration "><u>{{data.distanceAway}}</u></span>
                        <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
                      </div>
                      <div class="d-flex align-items-center"><img src="assets/rupee-symbol.svg" alt="rupee"><span
                          class="ms-2 default-font-family price">{{data.price}}</span></div>
                      <div class="d-flex align-items-center mt-3 me-1">
                        <button class="btn book-btn text-white px-4"
                          (click)="bookRaftingMerchant(data.id, data.activityid, data.merchantid)">Book
                          Now</button>
                        <div class="d-inline-flex align-items-baseline ms-1">
                          <!-- <ngb-rating [max]="1" [rate]="1"></ngb-rating> -->
                          <!-- <img src="/assets/star.gif" width="40px" alt="star"> -->

                          <img src="/assets/home/like_icon.svg" alt="like icon" class="like-icon ms-3">
                          <h3 class="mb-0 ms-2 avg-review fw-bold ">{{data.rating}}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <hr class=" px-5">
                  <table class="w-100 ">
                    <tr>
                      <td class="activity-title w-50"><img class="me-2 " style="width: 25px;"
                          src="/assets/time.svg">Activity Time</td>
                      <td class="me-5 p-md-3 p-2 rafting-details default-font-family">{{data.timefrom}} - {{ data.timeto
                        }}</td>
                    </tr>
                    <tr>
                      <td class="activity-title w-50"><img class="me-2" style="width: 25px;" alt="managed"
                          src="/assets/managed-by.svg">Managed by </td>
                      <td class="p-md-3 d-flex align-items-center gap-2 me-2 ">
                        <img class="rounded-circle profile-pic pointer" (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)"
                          alt="profile" src="assets/default-profile-pic.svg" *ngIf="!data.src[0]" />
                        <img class="rounded-circle profile-pic pointer" (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)"
                          [src]="data.src[0]" *ngIf="data.src[0]" alt="profile" />
                        <h3 class="mb-0  rafting-details default-font-family pointer"
                          (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)">{{data.merchantname}} <img
                            src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink"></h3>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loadingprovidedByRafting && showFilteredRafting">
        <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
          <img src="/assets/skeleton-provided-by.svg" class="w-100" alt="skeleton">
        </div>
      </div>
    </div>
    <div *ngIf="active_category === 'rafting' && raftingLoading">
      <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
        <img src="/assets/skeleton-card.svg" class="w-100" alt="skeleton">
      </div>
    </div>
    <!-- quickactivities -->
    <div class="activity-section mb-4" *ngIf="active_category === 'quickactivities'">
      <div *ngIf="!showFilteredActivities">
        <div class="row mobile-screen m-auto">
          <div class="col-12 col-md-6 my-1" *ngFor="let data of  quickActivitiesData; let j= index">
             <app-other-activities-card [data]="data"></app-other-activities-card>
          </div>
        </div>
      </div>

      <div *ngIf="showFilteredActivities " class="row mobile-screen m-auto my-3">
        <div class="col-12 col-md-6 my-1" *ngFor="let data of  filteredActivitiesData; let j= index">
          <app-other-activities-card [data]="data"></app-other-activities-card>
        </div>
      </div>
    </div>
    <div *ngIf="active_category === 'quickactivities' && quickActivitiesLoading">
      <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
        <img src="/assets/skeleton-provided-by.svg" class="w-100" alt="skeleton">
      </div>
    </div>
    <!--bike-rental -->
    <div class="rental-section mb-4"
      *ngIf="active_category === 'bike-rental' && !filterRentalMerchantsloading && !rentalLoading">
      <div *ngIf="showFilteredRentalMerchants">
        <!-- <div class="row mobile-screen m-auto">
      <ng-container *ngFor="let data of  bikeRentalData; let j= index">
        <div class="p-3  col-md-6 col-12">
          <div class="d-flex justify-content-between align-items-center mb-1"> 
          <h1 class="activity-name mb-0 text-truncate">{{data.shopname}}</h1>
          <div class="d-flex gap-1 me-3 align-items-center">
            <img src="/assets/star.gif" width="40px" alt="">
            <h4 class="mb-0 text-gray fw-bold">{{data.rating}}</h4>
          </div>
          </div>
          <div>
            <div class="position-relative pointer" (click)="searchMerchantBikes(data.id)">
              <img class="w-100 activity-img"
                [src]="data.shopsrc.length > 0 ? data.shopsrc[0] : 'assets/default-profile-pic.svg'" alt="">
              <img class="position-absolute info"
                (click)="openInfoDialog($event, data.description, data.id, 'bikerental')"
                src="../../assets/home/info.png" alt="">
            </div>
            <div class="my-2 me-3 d-flex justify-content-between">
              <div>
                <h4 class="mb-1 fw-bold">Managed by</h4>
                <div class="activity-details text-truncate pointer" (click)="openMerchantPage(data.id, $event)">
                  <img src="/assets/user-profile.svg" width="25px" class="location-icon" alt="">
                  <span>{{data.name}}</span>
                  <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="">
                </div>
                <div class="activity-title text-truncate" (click)="dataService.openMap(data.latitude, data.longitude)"  style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" class="location-icon" width="30px"
                  class="location-icon" alt=""> <span class="text-decoration "><u>{{data.distanceAway}}</u></span>
                <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="">
              </div>
              </div>
              <div>
                <span class="text-secondary">Starts From</span>
                <h2 class="mb-0 text-gray fw-bold">₹{{data.price}}</h2>
                <span class="text-secondary">Per Vehicle</span>
              </div>
            </div>
          </div>
          <div class="border-line m-auto"></div>
        </div>
      </ng-container>
      </div> -->
      </div>
      <div class="m-auto mobile-screen mt-3" *ngIf="showFilteredRentalMerchants  && !showFilteredBikes">
        <h1 class="mx-auto search-result mb-0 provided-by-content px-2 title">Provided By</h1>
        <div class="row  m-auto provided-by-content">
          <div class="mb-2 mt-3 mx-auto" *ngFor="let data of  filteredRentalMerchants;  let i = index">
            <div class="card border-0 shadow h-100" style="border-radius: 25px;">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-6">
                    <div (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)" class="w-100 pointer position-relative">
                      <img class="card-img-divs" src="assets/default-profile-pic.svg" *ngIf="!data.shopsrc[0]"
                        alt="profile" />
                      <img class="card-img-divs" [src]="data.shopsrc[0]" *ngIf="data.shopsrc[0]" alt="profile" />
                      <div  *ngIf="data.hasCoupon">
                        <div class="position-absolute offer">
                          <label class="pointer px-2 offer-text mobile-offer-text">OFFER AVAILABLE</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 ps-1 ps-md-3">
                    <div class="ms-0">
                      <div class="d-flex justify-content-between align-items-center mt-2">
                        <h6 class="my-1 shopname default-font-family text-truncate pointer"
                          (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)">{{data.merchantshopname}} <img
                            src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink"></h6>
                      </div>
                      <div class="activity-title text-truncate my-2"
                        (click)="dataService.openMap(data.merchantlatitude, data.merchantlongitude)"
                        style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" class="location-icon"
                          width="30px" alt="location"> <span
                          class="text-decoration "><u>{{data.distanceAway}}</u></span>
                        <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
                      </div>
                      <div class="d-flex align-items-center"><img src="assets/rupee-symbol.svg" alt="rupee"><span
                          class="ms-2 default-font-family price">{{data.price}}</span></div>
                      <div class="d-flex align-items-center mt-3 me-1">
                        <button class="btn book-btn text-white px-4"
                          (click)="bookMerchantRental(data.id, data.activityid, data.merchantid)">Book
                          Now</button>
                        <div class="d-inline-flex align-items-baseline ms-2">
                          <!-- <img src="/assets/star.gif" width="40px" alt="star"> -->
                          <img src="/assets/home/like_icon.svg" alt="like icon" class="like-icon">
                          <h3 class="mb-0 ms-2 avg-review fw-bold">{{data.avgReview}}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div >
                  <hr class=" px-5">
                  <div class="row w-100">
                    <div class="col-6 my-auto">
                      <h6 class="activity-title mb-0"><img class="me-2" style="width: 25px;" alt="managed"
                          src="/assets/managed-by.svg">Managed by </h6>
                    </div>
                    <div class="col-6">
                      <div class="d-flex align-items-center gap-2">
                        <img class="rounded-circle profile-pic pointer" (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)"
                          alt="profile" src="assets/default-profile-pic.svg" *ngIf="!data.src[0]" />
                        <img class="rounded-circle profile-pic pointer" (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)"
                          [src]="data.src[0]" *ngIf="data.src[0]" alt="profile" />
                        <h3 class="mb-0  rafting-details default-font-family pointer"
                          (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)">{{data.merchantname}} <img
                            src="assets/gg_link.svg" width="20px" class="location-icon" alt="gg_link"></h3>
                      </div>
                    </div>
                  </div>
                  <div class="text-center"><a href="javascript:void(0)"
                      (click)="selectBike(data.activityid, data.merchantid, data.merchantlocationid)">Click here for
                      more vehicles <img src="/assets/arrow.png" width="7px" alt="arrow"></a></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <ng-container *ngFor="let data of  filteredRentalMerchants; let j= index">
        <div class="p-2  col-md-6 col-12 pointer" (click)="searchRentalBikes(data.id)">
             <div class="d-flex justify-content-between align-items-center mb-1"> 
              <h1 class="activity-name mb-0 text-truncate">{{data.merchantshopname}}</h1>
              <div class="d-flex gap-1 me-3 align-items-center">
                <img src="/assets/star.gif" width="40px" alt="">
                <h4 class="mb-0 text-gray fw-bold">{{data.review_count}}</h4>
              </div>
              </div>
          <div>
            <div class="position-relative">
              <img class="w-100 activity-img"
              [src]="data.shopsrc.length > 0 ? data.shopsrc[0] : 'assets/default-profile-pic.svg'" alt="">
              <img class="position-absolute info"
                (click)="openInfoDialog($event, data.merchantdescription, data.merchantid, 'bikerental')"
                src="../../assets/home/info.png" alt="">
            </div>
            <div class="my-2 me-3 d-flex justify-content-between">
              <div>
                <h4 class="mb-1 fw-bold">Managed by</h4>
                <div class="activity-details text-truncate pointer" (click)="openMerchantPage(data.id)">
                  <img src="/assets/user-profile.svg" width="25px" class="location-icon" alt="">
                  <span>{{data.merchantname}}</span>
                  <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="">
                </div>
                <div class="activity-title text-truncate" (click)="dataService.openMap(data.merchantlatitude, data.merchantlongitude)"  style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" class="location-icon" width="30px"
                  class="location-icon" alt=""> <span class="text-decoration "><u>{{data.distanceAway}}</u></span>
                <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="">
              </div>
              </div>
              <div>
                <h2 class="mb-0 text-gray fw-bold">₹{{data.price}}</h2>
                <span class="text-secondary">Per Vehicle</span>
              </div>
            </div>
          </div>
        <div class="border-line m-auto"></div>
        </div>
      </ng-container> -->
        </div>
      </div>
      <div #filteredBikeDiv class="px-2"
        *ngIf="!showFilteredRentalMerchants && showFilteredBikes && !loadingFilteredBikes">
        <!-- <h4 class="w-50 mx-auto search-result mb-1 mobile-screen">{{selectedRentalMerchant.shopname}}</h4>
      <div class="w-50 d-flex mx-auto mobile-screen mb-2">
        <h4 class="default-font-family search-bike mb-0"> <img src="/assets/location-b.svg" alt="">{{selectedRentalMerchant.locationname}}</h4>
        <div class="d-flex align-items-center">
          <img src="/assets/home/fire.png" class="ms-3 me-1" width="17px" alt=""> <span class="default-font-family search-bike">{{selectedRentalMerchant.rating}}</span>
        </div>
      </div> -->
        <div class="w-50 m-auto mobile-screen mt-3" *ngIf="filteredBikeCard">
          <h1 class="default-font-family search-bike mb-0">Selected Vehicle</h1>
        </div>
        <div class="w-50 m-auto mobile-screen" *ngIf="filteredBikeCard">
          <div class="d-flex mt-3 justify-content-between pe-2 ps-3">
            <div style="width:25%; border-radius: 10px; overflow: hidden;">
              <img [src]="(filteredBikeCard.src[0])? filteredBikeCard.src[0] : ''" class="w-100 h-100"
                style="object-fit: cover;" alt="Bike">
            </div>
            <div class="ps-3 " style="width: calc(75% - 50px);">
              <h4 class="text-truncate mb-0 default-font-family bike-name">{{filteredBikeCard.value}}</h4>
              <h4 class="mb-0 bike-availability default-font-family"
                [ngClass]="{'text-danger': !filteredBikeCard.availability}">{{filteredBikeCard.availability ?
                'Available' : 'Not Available'}}</h4>
              <small class="bike-starts-from">Starts From</small>
              <h3 class="mb-0  default-font-family price">₹{{filteredBikeCard.price}}</h3>
              <div  *ngIf="rentalOfferAvailable">
                <div class=" offer" style="width: fit-content;">
                  <label class="pointer px-2 offer-text mobile-offer-text">OFFER AVAILABLE</label>
                </div>
              </div>
            </div>
            <div style="width: 50px;" class="d-flex align-items-center ">
              <img src="/assets/right-arrow.svg" class="pointer"
                (click)="navigateRentalMerchants(filteredBikeCard.id, filteredBikeCard.availability, 'filteredCard')"
                alt="rightArrow">
            </div>
          </div>
          <div class="border-line-bike my-3 m-auto"></div>
        </div>
        <div class="w-50 m-auto mobile-screen mt-3" *ngIf="filteredBikeCard">
          <h1 class="default-font-family search-bike mb-0">Other Vehicles</h1>
        </div>
        <div class="w-50 m-auto mobile-screen mt-3" *ngIf="!filteredBikeCard">
          <h1 class="default-font-family search-bike mb-0">Available Vehicle</h1>
        </div>
        <ng-container *ngFor="let data of bikeCard; let j= index">
          <div class="w-50 m-auto mobile-screen" *ngIf="data.id != filteredBikeCard?.id">
            <div class="d-flex mt-3 justify-content-between pe-2 ps-3">
              <div style="width:25%; border-radius: 10px; overflow: hidden;">
                <img [src]="(data.src[0])? data.src[0] : ''" class="w-100 h-100" style="object-fit: cover;" alt="Bike">
              </div>
              <div class="ps-3" style="width: calc(75% - 50px);">
                <h4 class="text-truncate mb-0 default-font-family bike-name">{{data.value}}</h4>
                <h4 class="mb-0 bike-availability default-font-family" [ngClass]="{'text-danger': !data.availability}">
                  {{data.availability ? 'Available' : 'Not Available'}}</h4>
                <small class="bike-starts-from">Starts From</small>
                <h3 class="mb-0  default-font-family price">₹{{data.price}}</h3>
                <div  *ngIf="rentalOfferAvailable">
                  <div class="offer" style="width: fit-content;">
                    <label class="pointer px-2 offer-text mobile-offer-text">OFFER AVAILABLE</label>
                  </div>
                </div>
              </div>
              <div style="width: 50px;" class="d-flex align-items-center ">
                <img src="/assets/right-arrow.svg" class="pointer"
                  (click)="navigateRentalMerchants(data.id, data.availability, 'allCard')" alt="rightArrow">
              </div>
            </div>
            <div class="border-line-bike my-3 m-auto"></div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="loadingFilteredBikes">
        <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
          <img src="/assets/skeleton-bike-card.svg" class="w-100" alt="skeleton">
        </div>
      </div>
    </div>
    <div class="loading-section"
      *ngIf="active_category === 'bike-rental' && (filterRentalMerchantsloading || rentalLoading)">
      <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
        <img src="/assets/skeleton-card.svg" class="w-100" alt="skeleton">
      </div>
    </div>
    <!-- camping -->
    <div class="camping-section mb-4" *ngIf="active_category === 'camping'">
      <div *ngIf="!viewSearchedCamping && !campingLoading">
        <div class="row mobile-screen m-auto">
            <div class="p-3 col-md-6 col-12 " *ngFor="let data of  campingData; let j= index">
               <app-activity-camping-card [data]="data"></app-activity-camping-card>
            </div>
        </div>
      </div>
      <div *ngIf="!viewSearchedCamping && campingLoading">
        <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
          <img src="/assets/skeleton-card.svg" class="w-100" alt="skeleton">
        </div>
      </div>
      <div *ngIf="viewSearchedCamping && !loadSearchCampingData">
        <!-- <h4 class="w-50 mx-auto search-result mb-0 mobile-screen px-2">Search Results</h4> -->
        <div class="row mobile-screen m-auto">
            <div class="p-3 col-md-6 col-12 " *ngFor="let data of  searchCampingData; let j= index">
              <app-activity-camping-card [data]="data"></app-activity-camping-card>
            </div>
        </div>
      </div>
      <div *ngIf="loadSearchCampingData">
        <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
          <img src="/assets/skeleton-card.svg" class="w-100" alt="skeleton">
        </div>
      </div>
    </div>
    <!-- topAdventurists -->
    <div class="adventure-section mb-4" *ngIf="active_category === 'topAdventurists'">
      <div class="" >
        <!-- <div class="border-line m-auto w-50"></div> -->
      </div>
      <div class="row mobile-screen m-auto">
          <div class="p-3 col-12 col-md-6 "  *ngFor="let data of  topAdventuristsData; let j= index" >
            <app-merchant-profile-card [data]="data"></app-merchant-profile-card>
          </div>
        </div>
    </div>
    <div *ngIf="active_category === 'topAdventurists' && adventuristsLoading">
      <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
        <img src="/assets/skeleton-card.svg" class="w-100" alt="skeleton">
      </div>
    </div>
    <!-- trekking -->
    <div class="trekking-section mb-4" *ngIf="active_category === 'trekking'&& trekkingData.length>0">
      <div class="row mobile-screen m-auto" *ngIf="!visibleFilteredTrekking">
          <div class=" col-md-6 col-12" *ngFor="let data of  trekkingData; let j= index">
             <app-other-activities-card [data]="data"></app-other-activities-card>
          </div>
      </div>
      <div class="row mobile-screen m-auto" *ngIf="visibleFilteredTrekking">
        <div class=" col-md-6 col-12" *ngFor="let data of  filterTrekkingData; let j= index">
          <app-other-activities-card [data]="data"></app-other-activities-card>
       </div>
      </div>
    </div>
    <div *ngIf="(active_category === 'trekking' && trekkingLoading) || (active_category === 'tour' && bikeTourLoading)">
      <div class="p-3 m-auto w-50 mobile-screen" *ngFor="let item of [1, 2, 3, 4]">
        <img src="/assets/skeleton-card.svg" class="w-100" alt="skeleton">
      </div>
    </div>
    <!-- tour -->
    <div class="tour-section mb-4" *ngIf="active_category === 'tour'&& bikeTourData.length>0">
      <div class="row mobile-screen m-auto" *ngIf="!visibleFilteredTour">
        <div class=" col-md-6 col-12" *ngFor="let data of  bikeTourData; let j= index">
          <app-other-activities-card [data]="data"></app-other-activities-card>
       </div>
      </div>
      <div class="row mobile-screen m-auto" *ngIf="visibleFilteredTour">
        <div class=" col-md-6 col-12" *ngFor="let data of  filteredTourData; let j= index">
          <app-other-activities-card [data]="data"></app-other-activities-card>
       </div>
      </div>
    </div>
    <div class="card-off" *ngIf="hasCoupon">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <div>
          <h3 class="card-off-content mb-0 text-center">{{couponPercentage}}%</h3>
          <h3 class="card-off-content mb-0 text-center">OFF</h3>
        </div>
      </div>
    </div>

    <!-- <div class="text-end" style="position: fixed; bottom: 25px; right: 25px;" [ngClass]="{ 'd-none': !showBackToTop }"
    id="back_to_top">
    <img src="../../assets/home/uparrow.png" (click)="back_to_top()" alt="">
  </div> -->
  </div>
  <app-footer></app-footer>
</section>