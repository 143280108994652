<div mat-dialog-content class="overflow-auto">
<div>
<button type="button" class="btn-close mt-3 ms-3" (click)="closePopUpDialog()" aria-label="Close"></button>
<h1 mat-dialog-title class="text-center" style="text-align: center;" class="full-screen-dialog">Booking Details</h1>
</div>
    <table>
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">Guest Name: </th>
                <td class="text-capitalize">{{(bookingDetails.userfirstname || '') + (bookingDetails.userlastname ? ' ' + bookingDetails.userlastname : '')}}</td>
            </tr>
            <tr>
                <th scope="row">Guest Email: </th>
                <td>{{bookingDetails.useremail}}</td>
            </tr>
            <tr>
                <th scope="row">Guest Phone Number: </th>
                <td>{{bookingDetails.usersphone}}</td>
            </tr>
            <tr>
                <th scope="row">Activity Type: </th>
                <td>{{bookingDetails.activitytypemastername}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category != 'bikerental'">
                <th scope="row">Number Of Guest: </th>
                <td>{{bookingDetails.guest}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'activity' || bookingDetails.category === 'rafting'">
                <th scope="row">Date: </th>
                <td>{{bookingDetails.date}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'activity' || bookingDetails.category === 'rafting'">
                <th scope="row">Time: </th>
                <td>{{bookingDetails.time}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'bikerental'">
                <th scope="row">Number of Days: </th>
                <td>{{bookingDetails.guest}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'bikerental'">
                <th scope="row">Pickup Date: </th>
                <td>{{bookingDetails.date}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'bikerental'">
                <th scope="row">Pickup Time: </th>
                <td>{{bookingDetails.time}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'bikerental'">
                <th scope="row">Drop Off Date: </th>
                <td>{{bookingDetails.dropdowndate}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'bikerental'">
                <th scope="row">Drop Off Time: </th>
                <td>{{bookingDetails.dropdowntime}}</td>
            </tr>
            <tr *ngIf="bookingDetails.category === 'camping'">
                <th scope="row">Check-In Date: </th>
                <td>{{bookingDetails.date}}</td>
            </tr>
            <!-- <tr *ngIf="bookingDetails.category === 'camping'">
                <th scope="row">Check-In Time: </th>
                <td>{{bookingDetails.time}}</td>
            </tr> -->
            <tr *ngIf="bookingDetails.category === 'camping'">
                <th scope="row">Check-Out Date: </th>
                <td>{{variations.checkoutdate}}</td>
            </tr>
            <!-- <tr *ngIf="bookingDetails.category === 'camping'">
                <th scope="row">Check-Out Time: </th>
                <td>{{bookingDetails.time}}</td>
            </tr> -->
            <tr  *ngFor="let variation of variations?.variations" >
                <th scope="row">{{variation.type}}: </th>
                <td>{{variation.variationvalue}}</td>
            </tr>
            <tr  *ngIf="drivingSrc && bookingDetails.category === 'bikerental'" >
                <th scope="row">Driving Licence: </th>
                <td><img [src]="drivingSrc" alt="driving" width="100px" ></td>
            </tr>
            <tr *ngIf="bookingDetails.datafrom != 'partner'">
                <th scope="row">Booking Source: </th>
                <td> {{data.bookingsource === 'agent' ? 'Agent' : (data.bookingsource === 'bymerchant' ? 'In House' : (data.bookingsource === 'partner' ? 'Partner' : 'Direct Booking'))}}</td>
            </tr>
            <tr>
                <th scope="row">Payment Amount: </th>
                <td>{{(bookingDetails.datafrom)? bookingDetails.netamount: bookingDetails.netamount}}</td>
            </tr>
            <tr>
                <th scope="row">Status: </th>
                <td>
                    <button class="btn btn-confirm status-btn" *ngIf="bookingDetails.status == 2">Confirmed</button>
                    <button class="btn btn-pending status-btn"  *ngIf="bookingDetails.status == 1">Pending</button>
                    <button class="btn btn-cancelled status-btn"  *ngIf="bookingDetails.status == 0">Cancelled</button>
                </td>
            </tr>
        </tbody>
    </table>
   
 </div>   