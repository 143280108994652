import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-providedby',
  templateUrl: './popup-providedby.component.html',
  styleUrls: ['./popup-providedby.component.css']
})
export class PopupProvidedbyComponent {

  merchantData: any;
  title: string = "";
  activityId: string = "";
  loadMoreMerchants: boolean = false;
  merchantPage: number = 1;
  name: string = "";
  userId: string = "";
  locationId:any=null;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog, private auth: AuthService, public PopupProvidedByDialogRef: MatDialogRef<PopupProvidedbyComponent>, private http: HttpClient, private router: Router, private snackBar: MatSnackBar) {
    this.title = data.activityName + " in " + data.activityLocation;
    this.activityId = data.activityId;
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.name = info.firstname;
        this.userId = info.id;
      }
    })
  }

  ngOnInit() {
    let location = localStorage.getItem('location');
    if(location != null && location != undefined && location != ""){
      this.locationId = JSON.parse(location).id;
    } 
    let filter:any = { count: 3, activityId: this.activityId, status: 1, page: 1 };
    if(this.locationId != null){
      filter.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/merchants/list`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          const merchantactivities = data.merchants.map((merchant: any) => this.loadMerchantActivity(merchant.id, this.activityId));
          const loadPromises = data.merchants.map((merchant: any) => this.loadImages('merchant', merchant.id));
          const loadRating = data.merchants.map((merchant: any) => this.loadRatings('merchant', merchant.id));
          const activityResult = await Promise.all(merchantactivities);
          const results = await Promise.all(loadPromises);
          const ratingsResult = await Promise.all(loadRating);
          data.merchants.forEach((merchant: any, index: number) => {
            merchant.src = results[index];
            merchant.timefrom = activityResult[index].timefrom;
            merchant.timeto = activityResult[index].timeto;
            merchant.price = activityResult[index].price;
            merchant.duration = activityResult[index].duration;
            merchant.reachby = activityResult[index].reachby;
            merchant.rating = ratingsResult[index].average || 0;
            merchant.ratingOf = ratingsResult[index].total || 0;
          });
          this.merchantData = data.merchants;
          if (Number(data.count) > data.merchants.length) {
            this.loadMoreMerchants = true;
            this.merchantPage += 1;
          }
        } else {
          this.loadMoreMerchants = false;
        }
      }
    })
  }

  closeProvidedByDialog() {
    this.PopupProvidedByDialogRef.close();
  }

  showMoreMerchants() {
    let filter:any = { count: 3, activityId: this.activityId, status: 1, page: this.merchantPage };
    if(this.locationId != null){
      filter.locationId = this.locationId;
    }
    this.loadMoreMerchants = false;
    this.http.post(environment.apiUrl + `/merchants/list`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        const merchantactivities = data.merchants.map((merchant: any) => this.loadMerchantActivity(merchant.id, this.activityId));
        const loadPromises = data.merchants.map((merchant: any) => this.loadImages('merchant', merchant.id));
        const loadRating = data.merchants.map((merchant: any) => this.loadRatings('merchant', merchant.id));
        const activityResult = await Promise.all(merchantactivities);
        const results = await Promise.all(loadPromises);
        const ratingsResult = await Promise.all(loadRating);
        data.merchants.forEach((merchant: any, index: number) => {
          merchant.src = results[index];
          merchant.timefrom = activityResult[index].timefrom;
          merchant.timeto = activityResult[index].timeto;
          merchant.price = activityResult[index].price;
          merchant.duration = activityResult[index].duration;
          merchant.reachby = activityResult[index].reachby;
          merchant.rating = ratingsResult[index].average || 0;
          merchant.ratingOf = ratingsResult[index].total || 0;
        });
        this.merchantData.push(...data.merchants);
        if (Number(data.count) > data.merchants.length) {
          this.loadMoreMerchants = true;
          this.merchantPage += 1;
        }
      }
    });
  }


  bookMerchant(id: any, activityid: any) {
    if (this.name) {
      this.PopupProvidedByDialogRef.close();
      this.router.navigate(['/book-merchant'], { queryParams: { merchantId: id, activityId: activityid, type: "User" } })
    } else {
      this.PopupProvidedByDialogRef.close();
      const isMobile = window.innerWidth < 768;
      let sizes={
        height:'90vh',
        width:'50%',
        maxWidth:'unset', 
        panelClass: 'bg-login-class'
      };
      if(isMobile){
        sizes={
          width:'100%',  
          maxWidth:'unset',
          height:'100%', 
          panelClass: 'bg-login-class'
        }
      }
      this.dialog.open(LoginDialogComponent, {
        ...sizes,
       disableClose: true,
       data: {
  
       },
     });
    }

  }

  openMerchant(ID: number) {
    this.PopupProvidedByDialogRef.close();
    this.router.navigate(['/merchant-profile'], { queryParams: { merchantid: ID } })
  };

  loadImages(datafor: any, id: any): Promise<any> {
    let src: any = [];
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=${datafor}&dataforid=${id}`).toPromise();

        if (data.status === "SUCCESS") {
          for (const upload of data.uploads) {
            let res: any = await this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).toPromise();
            if (res.status === "SUCCESS") {
              src.push(res.upload.thumbnail)
            }
          }
          resolve(src);
        } else {
          reject(src);
        }
      } catch (error) {
        reject(src);
      }
    });
  }

  loadMerchantActivity(merchantid: any, activityid: any) {
    let src: any = [];
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.post(environment.apiUrl + `/merchantactivities/list`, { merchantId: merchantid, activityId: activityid, status: "1" }).toPromise();
        if (data.status === "SUCCESS") {
          resolve(data.merchantactivities[0]);
        } else {
          reject(src);
        }
      } catch (error) {
        reject(src);
      }
    });
  }

  loadRatings(dataFor: string, dataForId: any) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor, dataForId }).toPromise();
        if (data.status === "SUCCESS") {
          resolve(data.reviews);
        } else {
          reject("");
        }
      } catch (error) {
        reject(error);
      }
    });
  }

}
