import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WriteReviewComponent } from '../write-review/write-review.component';
import { environment } from 'src/environments/environment';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import {catchError} from 'rxjs/operators';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { SurveyPopupComponent } from '../survey-popup/survey-popup.component';

@Component({
  selector: 'app-profile-bookings',
  templateUrl: './profile-bookings.component.html',
  styleUrls: ['./profile-bookings.component.css']
})
export class ProfileBookingsComponent {

  upcoming:boolean=true;
  completed:boolean= false;
  cancelled:boolean= false;
  pendingbooking:boolean= false;
  userId:string="";
  UserType:string="";
  upcomingBookings: any=[];
  completedBookings: any=[];
  cancelledBookings: any=[];
  pendingBookings: any=[];

  private dialogRef: MatDialogRef<SurveyPopupComponent> | null = null;
  @ViewChild('elementRef', { static: true }) elementRef!: ElementRef;
  constructor(public dialog: MatDialog, private http: HttpClient,  private route: ActivatedRoute, private router: Router,private auth:AuthService, private snackBar: MatSnackBar) {
    this.auth.userInfo.subscribe(info=>{
      if(info !== null){
        this.userId = info.id;
      }else{
        this.router.navigate(['/404-not-found']);
      }
    })
    this.auth.UserType.subscribe(type=>{
      this.UserType = type;
      if(type !== "user"){
        this.router.navigate(['/404-not-found']);
      }
    })
  }

  ngOnInit(){
    this.viewBookings();
    // window.scroll(0,0);
    let datafrom = localStorage.getItem('dataFrom');
    if(datafrom && datafrom == 'booking'){
      this.openPopup();
    }
  }

  viewBookings(){
    this.http.post(environment.apiUrl + `/bookings/list`, { status: "2", userId: this.userId, count: 500 }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          setTimeout(() => {
            const element = this.elementRef.nativeElement.querySelector('.booking-section');
            if (element) {
              element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
      });
          if (data.bookings.length > 0) {
            const currentDateTime = moment();
            const { upcomingDates, completedDates } = data.bookings.reduce(
              (acc: any, obj: any) => {

                const targetDateTime = moment(`${obj.date+ ' '+ obj.time}`, 'YYYY-MM-DD h:mm a');

                if (targetDateTime > currentDateTime) {
                  acc.upcomingDates.push(obj);
                } else {
                  acc.completedDates.push(obj);
                }

                return acc;
              },
              { upcomingDates: [], completedDates: [] }
            );
            upcomingDates.forEach((x:any)=>{
              if(x.category == 'bikerental'){
                let data= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = data?.dropdowndate || null;
                x.totime = data?.dropdowntime || null;
              }
              if(x.category == 'camping'){
                let data= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = data?.checkoutdate || null;
                x.totalguest = data?.guest || null;
              }
            })
            completedDates.forEach((x:any)=>{
              if(x.category == 'bikerental'){
                let data= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = data?.dropdowndate || null;
                x.totime = data?.dropdowntime || null;
              }
              if(x.category == 'camping'){
                let data= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = data?.checkoutdate || null;
                x.totalguest = data?.guest || null;
              }
            })
            this.upcomingBookings = upcomingDates;
            this.completedBookings = completedDates;
            this.upcomingBookings.sort((a:any, b:any) => {
              const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD h:mm a');
              const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD h:mm a');
              return dateTimeA.diff(dateTimeB);
            });
            this.completedBookings.sort((a:any, b:any) => {
              const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD h:mm a');
              const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD h:mm a');
              return dateTimeB.diff(dateTimeA);
            });
            this.route.queryParams.subscribe(params => {
              if(params['bookingid']){
                this.upcoming =false;
                this.completed= true;
                this.cancelled= false;
                this.pendingbooking= false;
                let data:any = this.completedBookings.find((x:any)=> x.id == params['bookingid']);
                this.writeReview(data.id, data.merchantid, data.activityid)
              }
            })
          }
        }
      }
    })
  }

  switchData(event:any){
    if(event.value === "upcoming"){
      this.upcoming =true;
      this.completed= false;
      this.cancelled= false;
      this.pendingbooking= false;
    }
    if(event.value === "completed"){
      this.upcoming =false;
      this.completed= true;
      this.cancelled= false;
      this.pendingbooking= false;
    }
    if(event.value === "cancelled"){
      this.upcoming =false;
      this.completed= false;
      this.cancelled= true;
      this.pendingbooking= false;
    }
    if(event.value === "pendingbooking"){
      this.upcoming =false;
      this.completed= false;
      this.cancelled= false;
      this.pendingbooking= true;
    }
  }

  timestampsToDate(timestamp: any): string {
    const date = new Date(Number(timestamp));

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours:any = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = (hours % 12) || 12;

    return `${year}/${month}/${day} ${formattedHours}:${minutes} ${period}`;
  }

  writeReview(id:any, merchantId:any, activityId:any){
    if(id && merchantId && activityId){
      const isMobile = window.innerWidth < 768;
      let sizes={
        height:'500px',
        width:'50%',
        maxWidth:'unset', 
      };
      if(isMobile){
        sizes={
          width:'100%',  
          maxWidth:'100%',
          height:'100%', 
        }
      }
      let data={bookingid:id, userid:this.userId, merchantid:merchantId, activityid: activityId};
      this.dialog.open(WriteReviewComponent, {
        ...sizes,
       disableClose: true,
       data: data,
     });
    }else{
      this.snackBar.open('Some error occured while adding review.', 'close', { duration: 3000, verticalPosition: 'top' },);
    }
  }

  cancellbooking(id:any){
    if (id === "") {
      return;
    }
    Swal.fire({
      title: '',
      text: 'Are you sure you want to cancel this booking? Please note that cancelling the booking will result in a non-refundable transaction.',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        let sizes = {
          height: 'fit-content',
          width: 'fit-content'
        };
        const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('accessToken'));
        this.http.post(`${environment.apiUrl}/bookings/usercancelbooking`,{id:id, status:"0", updatedBy:this.userId}, {headers}).pipe(
          catchError((_err: HttpErrorResponse)=> {
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title:'Some Error occurs',
                message: JSON.stringify(_err.error.errors),
                status:'danger'
              },
            })
            throw new Error("Error");
          })
        ).subscribe((data:any)=>{
          if(data.status === "SUCCESS"){
            this.upcomingBookings = this.upcomingBookings.filter((booking:any) => booking.id !== id);
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title:'Booking Cancelled',
                message: ``,
                status:'danger'
              },
            });
          }
        });
      } 
    })
  }

  viewCancellBooking(){
    this.http.post(environment.apiUrl + `/bookings/list`, { status: "0", userId: this.userId, count: 500 }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          if (data.bookings.length > 0) {
            data.bookings.forEach((x:any)=>{
              if(x.category == 'bikerental'){
                let bookingdetail= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = bookingdetail?.dropdowndate || null;
                x.totime = bookingdetail?.dropdowntime || null;
              }
              if(x.category == 'camping'){
                let bookingdetail= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = bookingdetail?.checkoutdate || null;
                x.totalguest = bookingdetail?.guest || null;
              }
            })
            this.cancelledBookings = data.bookings;
            this.cancelledBookings.sort((a:any, b:any) => {
              const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD h:mm a');
              const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD h:mm a');
              return dateTimeB.diff(dateTimeA);
            });
          }
        }
      }
    })
  }

  viewPendingBooking(){
    this.http.post(environment.apiUrl + `/bookings/list`, { status: "1", userId: this.userId, count: 500 }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          if (data.bookings.length > 0) {
            data.bookings.forEach((x:any)=>{
              if(x.category == 'bikerental'){
                let bookingdetail= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = bookingdetail?.dropdowndate || null;
                x.totime = bookingdetail?.dropdowntime || null;
              }
              if(x.category == 'camping'){
                let bookingdetail= JSON.parse(x.bookingdetailsjson) || null;
                x.todate = bookingdetail?.checkoutdate || null;
                x.totalguest = bookingdetail?.guest || null;
              }
            })
            this.pendingBookings=data.bookings;
            this.pendingBookings.sort((a:any, b:any) => {
              const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD h:mm a');
              const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD h:mm a');
              return dateTimeB.diff(dateTimeA);
            });
          }
        }
      }
    })
    this.http.post(environment.apiUrl + `/bookings/list`, { status: "3", userId: this.userId, count: 500 }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          if (data.bookings.length > 0) {
            data.bookings.forEach((x:any)=>{
              if(x.category == 'bikerental'){
                let data= JSON.parse(x.bookingdetailsjson);
                x.todate = data.dropdowndate;
                x.totime = data.dropdowntime;
              }
              if(x.category == 'camping'){
                let data= JSON.parse(x.bookingdetailsjson);
                x.todate = data.checkoutdate;
                x.totalguest = data.guest;
              }
            })
            this.pendingBookings=[...this.pendingBookings,...data.bookings];
            this.pendingBookings.sort((a:any, b:any) => {
              const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD h:mm a');
              const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD h:mm a');
              return dateTimeB.diff(dateTimeA);
            });
          }
        }
      }
    })
  }
  
  formatDate(date: any): any {
    if(date != null){
      return date.replace(/-/g, "/");
    }
      return ''
  }

  payNow(paymentid:any, type:string){
    if(type == 'paymentid'){
      this.router.navigate(['/paynow'],{queryParams:{paymentid: paymentid}})
    }else if(type == "referralbookingid"){
      this.router.navigate(['/paynow'],{queryParams:{referralbookingid: paymentid}})
    }
  }
  
  openPopup() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '90%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }
    if (!this.dialogRef || this.dialogRef.getState() === MatDialogState.CLOSED) {
        this.dialog.open(SurveyPopupComponent, {
          ...sizes,
          disableClose: true,
          data: {
            userId: this.userId, userType: this.UserType
            
          },
        });
        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = null;
        });
    }
  }
}
