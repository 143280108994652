import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.css']
})
export class ProfileManagementComponent  {

  userId: string = ""


constructor(public router: Router, private auth: AuthService,private http: HttpClient) {
  this.isRouteActive("/ProfileEdit")
  this.auth.userInfo.subscribe(info => {
    if (info !== null) {
      this.userId = info.id;
    }
  })
}

isRouteActive(route: string): boolean {
  return this.router.url.includes(route);
}

location: string | undefined;
  onLocationChange() {
    if (this.location && this.location.trim() !== '') {
      this.http.post(environment.apiUrl + `/usersearch/create`, {userId: (this.userId)? this.userId : 0, page:'profile',searchData:this.location}, { responseType: 'json' }).subscribe(async (data: any) => {})
      this.router.navigate(['/search'], { queryParams: { q: this.location, type:'location' } });
    }
  }
}
