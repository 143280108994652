import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CouponService } from '../coupon.service';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { DateConversionService } from '../services/date-conversion.service';

@Component({
  selector: 'app-coupons-popup',
  templateUrl: './coupons-popup.component.html',
  styleUrls: ['./coupons-popup.component.css']
})
export class CouponsPopupComponent {
  activityId: string = '';
  merchantId: string = '';
  validto: any;
  couponsData: any[] = [];


  constructor(private http: HttpClient, public dialog: MatDialog, private modalRef: MatDialogRef<CouponsPopupComponent>, @Inject(MAT_DIALOG_DATA) data: {
    coupons: any; data: any}, private couponService: CouponService, private dateService: DateConversionService) {
    this.couponsData = data.coupons;
    this.couponsData.forEach((coupon) => {
      let dateValid = Number(coupon.validto);
      this.validto = this.dateService.convertTimestampToDate(dateValid)

    })


  }

  getValidForFirstMessage(validforfirst: string): string {
    if (validforfirst === 'all') {
      return '*Applicable for all bookings';
    } else {
      return `*Applicable for first ${validforfirst} bookings`;
    }
  }

  // async ngOnInit(): Promise<void> {
  //   // this.http.post(environment.apiUrl + `/coupons/view-coupon-list`,{status:1,locationId:this.locationId, merchantId: this.merchantId,activityId:this.activityId ,currentTimeStamp: new Date().getTime()},{ responseType: 'json' }).subscribe( (data: any) => {
  //   // // console.log(data,"data");
  //   //  this.couponsData = data.coupons;

  //   // })
  //   let data: any = await this.couponService.Coupon( this.locationId,this.merchantId ,this.activityId) 
  //   if (data.status === "SUCCESS"){

  // };

  // }


  closeCoupon() {
    this.modalRef.close();
  }
  apply(id: any) {
    const selectedData = this.couponsData.filter((item: any) => item.id === id);
    this.modalRef.close(selectedData);

    // Swal.fire({
    //   title: 'Coupon Code Applied!!',
    //   customClass: {
    //     popup: 'custom-swal-popup',
    //     title:'custom-swal-title',
    //     actions:'custom-swal-actions-button'
    //   }
    // });
  }
}
