<div class="px-2 m-auto pointer" (click)="openMerchant(data.locationid,data.id,data.locationname,data.name)">
    <h1 class="default-font-family title mb-2 mt-1 ms-md-0 ms-2 text-truncate">{{data.shopname}}</h1>
    <div>
        <div class="position-relative">
            <img class="w-100 card-img-div"
                [src]="data.shopsrc.length > 0 ? data.shopsrc[0] : 'assets/default-profile-pic.svg'" alt="profile">
            <div *ngIf="data.hasCoupon">
                <div class="position-absolute offer ">
                    <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
                </div>
            </div>
        </div>
        <div class="me-3 my-2 ms-1 d-flex justify-content-between">
            <div style="width: calc(100% - 40px);">
                <h4 class="mb-1 fw-bold">Managed by</h4>
                <div class="profile-font d-flex align-items-center  text-truncate pointer">
                    <img [src]="data.src.length > 0 ? data.src[0] : 'assets/default-profile-pic.svg'"
                        class="rounded-circle" alt="profile">
                    <span class="mb-0 ms-1 title">{{data.name}}</span>
                </div>
            </div>
            <div class="d-inline-flex align-items-baseline ms-1 icon-size">
                <img src="/assets/home/like_icon.svg" class="like-icon" alt="like icon">
                <h3 class="mb-0 ms-2 avg-review fw-bold">{{ data.averagestar !== undefined && data.averagestar !== null ? data.averagestar : data.rating }}</h3>
            </div>
        </div>
    </div>
    <div class="border-line m-auto"></div>
</div>



