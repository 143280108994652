import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';

@Component({
  selector: 'app-survey-popup',
  templateUrl: './survey-popup.component.html',
  styleUrls: ['./survey-popup.component.css']
})
export class SurveyPopupComponent {
  selectedValue: string | number = '';
  userId: string = "";
  type: string = "";
  
  constructor(public dialog: MatDialog, private modalSurveyRef: MatDialogRef<SurveyPopupComponent>, private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private elementRef: ElementRef,) {

    this.type = data.userType;
    this.userId = data.userId;
  }

  onValueSelected(value: string | number) {
    this.selectedValue = value;
  }

  submitFeedback() {
    if (this.selectedValue === '') {
      // this.selectedValue = 0; 
      this.snackBar.open("Please provide your FEED BACK!.", 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    } else if (this.selectedValue === '10') {
      this.selectedValue = 10;
    }
    this.http.post(environment.apiUrl + `/survey/create`, { createdBy: this.userId, userId: this.userId, feedBack: "", rating: this.selectedValue }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        localStorage.removeItem('dataFrom');
        // window.scroll(0,0);
        setTimeout(() => {
          const element = this.elementRef.nativeElement.querySelector('.confirm-container');
          if (element) {
            element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        });
        this.modalSurveyRef.close();
        let sizes = {
          height: 'fit-content',
          width: 'fit-content',
        };
        this.dialog.open(ModalAlertComponent, {
          ...sizes,
          disableClose: true,
          data: {
            title: 'Thank you!',
            message: 'Your response has been submitted',
            status: 'success'
          },
        })
      }
    })
    
  }

  // closeSurvey() {
  //   this.modalSurveyRef.close();
  // }
}
