<div *ngIf="exploreData !== 'landingPage'">
<div class=" border-0  ">
  <div class="card-body">
    <div class="d-flex justify-content-between"
      (click)="openCamping(data.activitieslocationid,data.id, $event,data.activitieslocationname,data.campingName)">
      <div style="width: calc(100% - 45%);" class="pointer">
        <h1 class=" default-font-family activity-name text-truncate mb-0">{{data.campingName}}</h1>
        <h1 class="default-font-family mb-2 text-truncate name">{{data.activitiesaddressline1}}</h1>
      </div>
      <div class="d-flex mt-1 ms-1 me-3">
        <ng-template #t let-fill="fill">
          <app-svg-icon [fill]="fill === 100 ? 'orange' : 'gray'" class="ms-1" icon="likeIcon"></app-svg-icon>
        </ng-template>
        <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [rate]="likeRating" 
          class="camp-rating"></ngb-rating>
      </div>
    </div>



    <div class="position-relative pointer"
      (click)="openCamping(data.activitieslocationid,data.id, $event,data.activitieslocationname,data.campingName)">
      <ngb-carousel *ngIf="data.src" class=" carousel-height w-100">
        <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
          <!-- <div class="card-img-div position-relative d-flex">
                  <img class="w-100 activity-img " [src]="src" alt="image">
                </div> -->
          <div class="wrapper">
            <img [src]="src.img" alt="image" class="card-img-div ">
          </div>
        </ng-template>
      </ngb-carousel>
      <img class="position-absolute info"
        (click)="openInfoDialog(data.activitieslocationid,$event, data.description, data.id, 'camping')"
        src="../../assets/home/info.png" alt="info">
      <div *ngIf="data.hasCoupon">
        <div class="position-absolute offer ">
          <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
        </div>
      </div>
    </div>
    <div class="px-2 mt-md-3 d-flex justify-content-between">
      <div style="width:57%;">
        <h4 class="mb-1 fw-bold">Managed by</h4>
        <div class="activity-type text-truncate pointer"
          (click)="openMerchantPage(data.activitieslocationid,data.merchantid,data.activitieslocationname,data.merchantname)">
          <img src="/assets/user-profile.svg" width="25px" class="location-icon" alt="profile">
          <span>{{data.merchantname}}</span>
          <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
        </div>
        <div class="activity-title text-truncate fw-bold pointer"
          (click)="openMap($event, data.campingLatitude, data.campingLongitude)"
          style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" width="25px" class="location-icon"
            alt="location"> <span class="text-decoration "><u>{{data.distanceAway}}</u></span>
          <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
        </div>
      </div>
      <div *ngIf=" data.min_price == '0' || data.min_price == null || data.min_price == undefined" class="callback"
        (click)="$event.stopPropagation()">
        <label class="pointer px-sm-3" (click)="openEnquireDialog(data.campingName)"> Enquire</label>
      </div>
      <div *ngIf=" data.min_price != '0' &&  data.min_price != null && data.min_price != undefined">
        <span class="text-secondary mb-0">Starts From</span>
        <h2 class="mb-0 text-gray fw-bold">₹{{data.min_price}}</h2>
        <span class="text-secondary">Per Night</span>
      </div>
    </div>
  </div>


  <div class="border-line mx-auto"></div>

</div>
</div>


<div *ngIf="exploreData === 'landingPage'">
<div class="d-flex justify-content-between"  (click)="openData(data.id, data.activitieslocationid,data.activitiescategory ,$event,data.activitieslocationname,data.activitiesaddressline1 ,data.campingName,data.merchantid)">
  <div class="text-truncate"  *ngIf="data.activitiescategory === 'camping'">
    <h1 class="default-font-family title mb-2 text-truncate">{{data.campingName}}</h1>
  </div>
  <div class="d-flex ms-1 me-3" *ngIf="data.activitiescategory === 'camping'">
    <ng-template #t let-fill="fill">
      <app-svg-icon [fill]="fill === 100 ? 'orange' : 'gray'" class="ms-1" icon="likeIcon"></app-svg-icon>
    </ng-template>
    <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [rate]="likeRating" class="camp-rating"></ngb-rating>
  </div>
</div>
<div>
  <div class="position-relative pointer"  (click)="openData(data.id , data.activitieslocationid,data.activitiescategory  ,$event, data.activitieslocationname,data.activitiesaddressline1,data.campingName,data.merchantid)">
    <ngb-carousel *ngIf="data.activitiescategory === 'camping' && data.src " class=" carousel-height"  >
      <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
        <div class="wrapper  w-100">
          <img [src]="src.img" alt="image" class="card-img-div">
        </div>
      </ng-template>
    </ngb-carousel>
  
    <img class="position-absolute info pointer" *ngIf="data.activitiescategory === 'camping'"
      (click)="openInfoDialog(data.activitieslocationid,$event, data.description, data.id, 'camping',data.locationname, data.activitiesaddressline1)"
      src="../../assets/home/info.png" alt="info">
    <div *ngIf="data.hasCoupon">
      <div class="position-absolute offer ">
        <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
      </div>
    </div>
  </div>
 
  <div class="px-2 mt-md-3 d-flex justify-content-between" *ngIf="data.activitiescategory === 'camping'">
    <div style="width: 65%;">
      <h2 class="mb-2 activity-type ">{{data.activitiesaddressline1}}</h2>
      <div class="activity-title text-truncate fw-bold mt-3"
        (click)="openMap($event, data.campingLatitude, data.campingLongitude)"
        style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" width="25px" class="location-icon"
          alt="location"> <span class="text-decoration "><u>{{data.distanceAway}}</u></span>
        <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
      </div>
    </div>
    <div *ngIf="data.min_price == '0' || data.min_price == null == undefined" class="callback mt-3"
      (click)="$event.stopPropagation()">
      <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitiesaddressline1)"> Enquire</label>
    </div>
    <div *ngIf="data.min_price != '0' && data.min_price != null && data.min_price != undefined" class="text-start">
      <span class="text-secondary mb-0">Starts From</span>
      <h2 class="mb-0 text-gray fw-bold">₹ {{ data.min_price}}</h2>
      <span class="text-secondary">Per Night</span>
    </div>
  </div>

</div>
<div class="border-line m-auto w-100"></div>
</div>