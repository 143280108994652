
<div mat-dialog-content class="overflow-auto">
  <div class="d-flex align-items-center pb-md-3 pb-2">
    <button type="button" class="btn-close mt-2 ms-2" (click)="closeLoginDialog()" aria-label="Close"></button>
    <h1 mat-dialog-title id="form-login" class="text-center full-screen-dialog mb-0 heading pb-0 d-none d-md-block" >Login</h1>
  </div>
  <div class="logo d-block d-md-none mb-4">
    <img src="/assets/new-logo.svg" alt="Logo">
  </div>
  <form class="h-100" (submit)="onLogin()" [formGroup]="loginForm">
    <!-- Email input -->
    <div class="form-outline mb-2 mt-2">
      <input type="email" id="form2Example1" [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }" formControlName="email" class="form-control email"
        placeholder=" Email/ Phone Number" />
        <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']">Email/Phone Number is required</div>
        </div>
    </div>


    <!-- Password input -->


    <div class="form-outline mb-2 mt-3 position-relative">
      <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
        {{ passwordVisible ? 'visibility' : 'visibility_off ' }}
      </mat-icon>
      <input type="{{ passwordVisible ? 'text' : 'password' }}" id="form2Example2" formControlName="password"
        class="form-control password" placeholder="Password" [ngClass]="{ 'is-invalid': isSubmitting && f['password'].errors }" />
        <div *ngIf="isSubmitting && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors['required']">Password is required</div>
        </div>
    </div>

        <!-- 2 column grid layout for inline styling -->
        <div class="row mb-2" style="margin:0 10% 0 10%;">
          <div class="col text-end pe-0">
              <!-- Simple link -->
              <a href="javascript:void(0)" class="text-decoration-none font-size-12 forget-password" (click)="openForgetPasswordDialog()">Forgot password?</a>
          </div>
      </div>

    <!-- Submit button -->
    <div class=" text-center mt-2 ">
      <button type="submit" class="login-btn btn text-light mb-2 px-5 py-2">Login</button>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <hr class="hr-or my-1">
      <span class="mx-2 text-dark">or</span>
      <hr class="hr-or my-1">
    </div>
      <div class="g_id_signin mt-3 mx-auto" *ngIf="!ifMobileApp()" style="width: fit-content;" id="g_id_onload" data-type="standard" data-text="signingoogle" data-shape="" data-client_id="189004330207-8ubso558a7ns63388uhgnl9fjjq1621b.apps.googleusercontent.com" data-callback="handleCredentialResponse"></div>
      <!-- <div class="mt-2 mx-auto border rounded pointer p-1 d-flex align-items-center" *ngIf="ifMobileApp()  && !ifIosApp()" (click)="loginGoogle()" style="width: fit-content;">
        <img src="/assets/google.svg" class="ms-2" width="30px"  />
       <span class="px-2" style="font-size: 14px;">Sign in with Google</span>
      </div> -->
      <div class="mt-2 mx-auto border rounded pointer p-1 d-flex align-items-center" *ngIf="ifMobileApp() && ifIosApp()" (click)="loginMobileApple()" style="width: fit-content;">
        <img src="/assets/apple.svg" class="ms-2" width="30px"  alt="appleIcon"/>
       <span class="px-2" style="font-size: 14px;">Sign in with Apple</span>
      </div>
      <div class="mt-2 mx-auto border rounded pointer p-1 d-flex align-items-center bg-white" *ngIf="!ifMobileApp()" (click)="clickWebLoginApple()" style="width: fit-content;">
        <img src="/assets/apple.svg" class="ms-2" width="30px"  alt="appleIcon"/>
       <span class="px-2" style="font-size: 14px;">Sign in with Apple</span>
      </div>
     
    <!-- Register buttons -->

    <!-- <div class="d-flex">
      <hr class="" width="49.5%"><span class="mt-1">or</span>
      <hr class="" width="49.5%">
    </div>
    <div class="text-center mt-3">
      <a href="#">
        <img class=" fb-buttons " src="../../../assets/fb.png"></a>
      <a href="#">
        <img class=" fb-buttons ms-4" src="../../../assets/google.svg">
      </a>
      <a href="#">
        <img class=" fb-buttons ms-4" src="../../../assets/iphone.png">
      </a>
    </div> -->

      <div class="my-2 text-center">
        <p class="skip-login mb-1"><span class="text-light me-2 "> New here?</span> <a class="text-decoration-none pointer text-dark" (click)="openSignInDialog()">Create new account</a></p>
        <h4 class="text-light skip-login pointer mb-1" (click)="closeLoginDialog()">Skip Login</h4>
        <hr class="hr-custom mx-auto opacity-50 border-light">
        <button type="button" class="other-logins btn d-block mx-auto px-md-5 py-1" (click)="openMerchantLoginDialogue()">Merchant Log in</button>
        <button type="button" class="other-logins btn mt-2 d-block mx-auto px-md-5 py-1" (click)="loginPartner()">Partner Log in</button>
      </div>
  </form>
</div>
