<div mat-dialog-content class="overflow-auto">
  <div class="d-flex align-items-center pb-3 ">
    <button type="button" class="btn-close mt-2 ms-2" (click)="closeMerchantSignUpDialog()" aria-label="Close"></button>
    <h1 mat-dialog-title id="form-login" class="text-center signup mb-0 pb-0 heading">Register as Merchant</h1>
  </div>
  <form (submit)="onMerchantSignUp()" [formGroup]="merchantSignUpForm">
    <div class="d-flex justify-content-around p-3">
      <h4 [ngClass]="contactDetails ? 'rounded-content ' : 'rounded-content active'">
        <span [ngClass]="
            contactDetails
              ? 'rounded-number me-2'
              : 'rounded-number active  me-2'
          ">1</span>Account
      </h4>
      <h4 [ngClass]="contactDetails ? 'rounded-content active' : 'rounded-content'">
        <span [ngClass]="
            contactDetails
              ? 'rounded-number active me-2'
              : 'rounded-number  me-2'
          ">2</span>Contact details
      </h4>
    </div>
    <div *ngIf="!contactDetails">
      <div class="row mx-auto" style="width: 85%; ">
        <div class="col">
          <div class="d-flex align-items-center">
            <img [src]="operatorProfile != '' ? operatorProfile : '/assets/upload.svg'" class="rounded pointer"
              (click)="triggerUpload('operatorProfileInput')" width="50px" alt="upload" />
            <input type="file" accept="image/*" (change)="handlePicChange($event, 'operatorProfileInput')"
              #operatorProfileInput class="d-none" />
            <h6 class="mb-0 ms-2 upload-img">Upload Operator Profile image</h6>
          </div>
        </div>
        <div class="col">
          <div class="d-flex align-items-center">
            <img [src]="businessProfile != '' ? businessProfile : '/assets/upload.svg'" class="rounded pointer"
              (click)="triggerUpload('businessProfileInput')" width="50px" alt="upload" />
            <input type="file" accept="image/*" (change)="handlePicChange($event, 'businessProfileInput')"
              #businessProfileInput class="d-none">
            <h6 class="mb-0 ms-2 upload-img">Upload Business Profile image</h6>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-around">
        <label>
          <input type="radio" name="radioOptions" [checked]="individualGuideSelected"
            (change)="individualGuideSelected = true; companySelected = false" />
          Individual Guide
        </label>

        <label>
          <input type="radio" name="radioOptions" [checked]="companySelected"
            (change)="companySelected = true; individualGuideSelected = false" />
          Company
        </label>
      </div> -->
      <div class="row mt-3 mx-auto gap-2" style="width: 85%;">
        <div class="col px-0">
          <input type="text" class="form-control" [ngClass]="{ 'is-invalid': isSubmitting && f['name'].errors }"
            formControlName="name" placeholder="Operator name" />
          <div *ngIf="isSubmitting && f['name'].errors" class="invalid-feedback">
            <div *ngIf="f['name'].errors['required']">
              Operator name is required
            </div>
          </div>
        </div>
        <div class="col px-0">
          <input type="text" class="form-control" [ngClass]="{ 'is-invalid': isSubmitting && f['shopName'].errors }"
            formControlName="shopName" placeholder="Business name" />
          <div *ngIf="isSubmitting && f['shopName'].errors" class="invalid-feedback">
            <div *ngIf="f['shopName'].errors['required']">
              Business name is required
            </div>
          </div>
        </div>
      </div>
      <div class="form-outline mt-3">
        <textarea name="description" id="description" class="form-control"
          [ngClass]="{ 'is-invalid': isSubmitting && f['description'].errors }" formControlName="description"
          placeholder="Description" rows="4" style="resize: none"></textarea>
        <div *ngIf="isSubmitting && f['description'].errors" class="invalid-feedback">
          <div *ngIf="f['description'].errors['required']">
            Description is required
          </div>
        </div>
      </div>
      <!-- Email input -->
      <div class="form-outline mt-3">
        <input type="email" id="form3Example3" class="form-control inputbox2"
          [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }" formControlName="email"
          placeholder="Email address" />
        <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']">Email is required</div>
          <div *ngIf="f['email'].errors['email']">Email is invalid</div>
        </div>
      </div>
      <!-- phone number -->
      <div class="row mt-3 mx-auto gap-2" style="width: 85%;">
        <div class="col px-0">
          <ngx-intl-tel-input class="d-block w-100 phone" [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors != null }" 
            [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [inputId]="my-input-id"
            name="phone" formControlName="phone" [placeholder]="'Personal Number'"></ngx-intl-tel-input>
          <!-- <input type="text" class="form-control" [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }"
            formControlName="phone" placeholder="Personal Number" maxlength="10"
            oninput="this.value = this.value.replace(/[^0-9 ]/,'')" /> -->
          <div *ngIf="isSubmitting && f['phone'].errors != null" class="invalid-feedback">
            <div *ngIf="f['phone'].errors['required']">Phone Number is required</div>
            <div *ngIf="!f['phone'].errors['required']">Invalid Phone number.</div>
          </div>
        </div>
        <div class="col px-0">
          <ngx-intl-tel-input class="d-block w-100 bussinessphone" [ngClass]="{ 'is-invalid': isSubmitting && f['alternatephoneno'].errors != null }" 
            [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [inputId]="my-input-id"
            name="alternatephoneno" formControlName="alternatephoneno" [placeholder]="'Business Number'"></ngx-intl-tel-input>
          <!-- <input type="text" class="form-control"
            [ngClass]="{ 'is-invalid': isSubmitting && f['alternatephoneno'].errors }" formControlName="alternatephoneno"
            placeholder="Business Number" maxlength="10" oninput="this.value = this.value.replace(/[^0-9 ]/,'')" /> -->
          <div *ngIf="isSubmitting && f['alternatephoneno'].errors != null" class="invalid-feedback">
            <div *ngIf="f['alternatephoneno'].errors['required']">Phone Number is required</div>
            <div *ngIf="!f['alternatephoneno'].errors['required']">Invalid Phone number.</div>
          </div>
        </div>
      </div>
      <button class="login-btn btn text-light mb-2 mt-3" (click)="onChangeContactPage()">
        Next
      </button>
    </div>
    <div *ngIf="contactDetails">
      <div class="form-outline mt-3">
        <select name="location" id="location" class="form-control inputbox2"
          [ngClass]="{ 'is-invalid': isSubmitting && f['location'].errors }" (change)="onChangeLocation()"
          formControlName="location" placeholder="Location">
          <option value="">Location...</option>
          <option *ngFor="let location of locations" [value]="location.id">{{ location.locationname }}</option>
        </select>
        <div *ngIf="isSubmitting && f['location'].errors" class="invalid-feedback">
          <div *ngIf="f['location'].errors['required']">Location is required</div>
        </div>
      </div>
      <div class="form-outline mt-3">
        <textarea name="address" id="address" class="form-control" placeholder="Address" rows="4"
          [ngClass]="{ 'is-invalid': isSubmitting && f['address'].errors }" formControlName="address"
          style="resize: none"></textarea>
        <div *ngIf="isSubmitting && f['address'].errors" class="invalid-feedback">
          <div *ngIf="f['address'].errors['required']">Address is required</div>
        </div>
      </div>
      <div class="form-outline mt-3">
        <input type="text" class="form-control inputbox2" placeholder="city" formControlName="city" />
      </div>
      <div class="form-outline mt-3">
        <select name="state" id="state" [(ngModel)]="locationStateid" class="form-control inputbox2"
          [ngClass]="{ 'is-invalid': isSubmitting && f['state'].errors }" formControlName="state"
          (change)="onChangeState()">
          <option value="">State...</option>
          <option *ngFor="let state of states" [value]="state.id">{{ state.name }}</option>
        </select>
        <div *ngIf="isSubmitting && f['state'].errors" class="invalid-feedback">
          <div *ngIf="f['state'].errors['required']">State is required</div>
        </div>
      </div>
      <div class="form-outline mt-3">
        <select name="district" id="district" [(ngModel)]="districtid" class="form-control inputbox2"
          [ngClass]="{ 'is-invalid': isSubmitting && f['district'].errors }" formControlName="district">
          <option value="">District...</option>
          <option *ngFor="let dist of districts" [value]="dist.id">{{ dist.name }}</option>
        </select>
        <div *ngIf="isSubmitting && f['district'].errors" class="invalid-feedback">
          <div *ngIf="f['district'].errors['required']">District is required</div>
        </div>
      </div>
      <div class="form-outline mt-3">
        <input type="text" class="form-control inputbox2" placeholder="Postal code"
          [ngClass]="{ 'is-invalid': isSubmitting && f['pincode'].errors }" formControlName="pincode" />
        <div *ngIf="isSubmitting && f['pincode'].errors" class="invalid-feedback">
          <div *ngIf="f['pincode'].errors['required']">Postal code is required</div>
          <div *ngIf="f['pincode'].errors['pattern']">Invalid Postal code.</div>
        </div>
      </div>
      <div class="form-outline mt-3">
        <input type="text" class="form-control inputbox2" placeholder="website" formControlName="webiste" />
      </div>
      <div class="form-outline mt-3">
        <mat-form-field class="mx-auto d-block" style="width: 85%;">
          <mat-label>Search Tags</mat-label>
          <mat-chip-grid #chipGrid aria-label="Enter search tags" formControlName="tags">
            <mat-chip-row *ngFor="let tag of customSearchTags" (removed)="removeSearchTag(tag)" [editable]="true">
              {{tag}}
              <button matChipRemove [attr.aria-label]="'remove ' + tag">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input type="search" placeholder="New Search Tag..." [matChipInputFor]="chipGrid"
              (matChipInputTokenEnd)="addSearchTag($event)" />
          </mat-chip-grid>
        </mat-form-field>
      </div>
      <div class="form-outline mt-3">
        <div id="map" style="width:85%; height:200px; margin:auto;"></div>
      </div>
      <div class="form-group mt-3" style="width:85%; margin: auto;">
        <input type="checkbox" [ngClass]="{ 'is-invalid': isSubmitting && f['agree'].errors }" formControlName="agree"
          id="checkbox" class="form-check-input" />
        <label for="checkbox" class="form-label ms-2">I agree to the <a class="text-decoration-none text-light pointer"
            (click)="openMerchantAgreement()">Terms of service</a></label>
        <div *ngIf="isSubmitting && f['agree'].errors" class="invalid-feedback">
          <div *ngIf="f['agree'].errors">Please agree terms of services</div>
        </div>

      </div>

      <button type="submit" class="login-btn btn text-light mb-2 mt-3">
        Review
      </button>
      <h4 class="fw-bold mx-auto pointer" style="width: fit-content" (click)="contactDetails = false">
        Go Back
      </h4>
    </div>
  </form>
  <div class="my-3">
    <p class="skip-login"><span class="text-light">By creating account, you agree to our </span> <a
        href="/terms-condition" class="text-decoration-none pointer text-dark"> Terms of service</a><span
        class="text-light me-2 "> and </span><a href="/privacy-policy" class="text-decoration-none pointer text-dark">
        Privacy policy</a></p>
    <p class="skip-login"><span class="text-light me-2 ">Already have an account?</span> <a
        class="text-decoration-none pointer text-dark" (click)="openMerchantLoginDialogue()">Login</a></p>
  </div>
</div>