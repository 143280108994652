import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[closeNavbar]'
})
export class CloseNavbarDirective {
  constructor(private el: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    const navbar = this.el.nativeElement.querySelector('.navbar-collapse');

    if (!navbar.contains(targetElement)) {
      navbar.classList.remove('show');
    }
  }
}
