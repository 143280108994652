  <div class="d-flex justify-content-between"  (click)="openData(data.activityid|| data.id, data.locationid || data.activitieslocationid,data.activitiescategory || data.category ,$event,data.locationname || data.activitieslocationname,data.activitiesaddressline1 || data.addressline1,data.campingName,data.merchantid || data.id)">
    <div class="text-truncate" *ngIf="categoryData !== 'bikerental'">
      <h1 class="default-font-family title mb-2 text-truncate">{{data.activitiesaddressline1 || data.addressline1}}</h1>
    </div>
    <div  class="text-truncate" style="width: calc(100% - 37%);"  *ngIf="categoryData === 'bikerental'">
      <h1 class="default-font-family title mb-2 text-truncate">{{data.shopname}}</h1>
      </div>
    <!-- <div class="text-truncate"  *ngIf="data.activitiescategory === 'camping'">
      <h1 class="default-font-family title mb-2 text-truncate">{{data.campingName}}</h1>
    </div> -->
    <div class="d-flex mt-1 ms-1 me-3"
    *ngIf="(data.activitiescategory !== 'camping' || exploreData === 'searchPage')">
      <ng-template #t let-fill="fill">
        <app-svg-icon [fill]="fill === 100 ? '#FF7A00' : 'gray'" class="" icon="fireIconRating"></app-svg-icon>
      </ng-template>
      <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [rate]="fireRating"></ngb-rating>
    </div>
    <!-- <div class="d-flex mt-1 ms-1 me-3" *ngIf="data.activitiescategory === 'camping'">
      <ng-template #t let-fill="fill">
        <app-svg-icon [fill]="fill === 100 ? 'orange' : 'gray'" class="ms-1" icon="likeIcon"></app-svg-icon>
      </ng-template>
      <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [(rate)]="fireRating" class="camp-rating"></ngb-rating>
    </div> -->
  </div>
  <div>
    <div class="position-relative pointer"  (click)="openData(data.activityid || data.id, data.locationid || data.activitieslocationid,data.activitiescategory || data.category ,$event,data.locationname || data.activitieslocationname,data.activitiesaddressline1 || data.addressline1,data.campingName,data.merchantid || data.id)">
      <ngb-carousel *ngIf=" data.activitiescategory !== 'camping' && data.src && categoryData !== 'bikerental'" class="carousel-height"  >
        <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
          <div class="wrapper">
            <img class="w-100 card-img-div" [src]="src" alt="image">
          </div>
        </ng-template>
      </ngb-carousel>
      <ngb-carousel *ngIf="data.activitiescategory === 'camping' && data.src  && categoryData !== 'bikerental'" class=" carousel-height"  >
        <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
          <div class="wrapper">
            <img [src]="src.img" alt="image" class="card-img-div">
          </div>
        </ng-template>
      </ngb-carousel>
      <div  *ngIf ="categoryData === 'bikerental'">
        <img class="w-100 card-img-div"
        [src]="data.shopsrc.length > 0 ? data.shopsrc[0] : 'assets/default-profile-pic.svg'" alt="profile">
      </div>
          
      <img class="position-absolute info pointer" *ngIf
      ="(data.activitiescategory || categoryData) !== 'bikerental'"
        (click)="openInfoDialog(data.locationid || data.activitieslocationid,$event, data.activitiesdescription || data.description , data.activityid || data.id , data.activitiescategory || data.category ,data.locationname, data.activitiesaddressline1 || data.addressline1 )"
        src="../../assets/home/info.png" alt="info">
      <div *ngIf="data.hasCoupon">
        <div class="position-absolute offer ">
          <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
        </div>
      </div>
    </div>
    <div class="px-2 mt-3 d-flex justify-content-between" *ngIf="(data.activitiescategory || data.category) === 'rafting'"  (click)="openData(data.activityid || data.id, data.locationid || data.activitieslocationid,data.activitiescategory || data.category ,$event,data.locationname || data.activitieslocationname,data.activitiesaddressline1  || data.addressline1,data.campingName,data.merchantid|| data.id)">
      <div style="width:57%;">
        <h2 class="mb-2 ms-1 mb-0 activity-type" *ngIf="data.raftingkm && data.raftingkm =='9 Km'">Easy</h2>
        <h2 class="mb-2 ms-1 mb-0 activity-type" *ngIf="data.raftingkm && data.raftingkm =='16 Km'">Easy
          Medium</h2>
        <h2 class="mb-2 ms-1 mb-0 activity-type" *ngIf="data.raftingkm && data.raftingkm =='26 Km'">Medium
        </h2>
        <h2 class="mb-2 ms-1 mb-0 activity-type" *ngIf="data.raftingkm && data.raftingkm =='35 Km'">Hard
        </h2>
        <div *ngIf="exploreData === 'homePage'">
        <div class="mt-4 d-flex ms-1">
          <img src="../../assets/home/map-marker.svg" width="20px" height="20px" alt="altitude">
          <span class=" px-1 fw-bold data-font">{{data.raftingkm}} </span>
        </div>
      </div>
      
      <div *ngIf="exploreData === 'searchPage'">
        <div class="activity-title text-truncate  mt-3 fontColor"
          (click)="openActivitie(data.activityid, data.locationid,$event)"><img src="assets/location-b.svg"
            class="location-icon" alt="location"><span>{{data.locationname}}</span>
        </div>
      </div>
      </div>
      <div *ngIf="(data.price == '0' || data.price == null || data.price == undefined) &&
      (data.min_price == '0' || data.min_price == null == undefined)" class="callback mt-3"
        (click)="$event.stopPropagation()">
        <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitiesaddressline1  || data.addressline1)"> Enquire</label>
      </div>
      <div *ngIf="(data.price != '0' && data.price != null && data.price != undefined) ||
      (data.min_price != '0' && data.min_price != null && data.min_price != undefined)" class="text-start">
        <span class="text-secondary">Starts From</span>
        <h2 class="mb-0 text-gray fw-bold">₹ {{data.price || data.min_price}}</h2>
        <span class="text-secondary">Per person</span>
      </div>
    </div>
    <div *ngIf="(data.activitiescategory || data.category) === 'activity'"
     (click)="openData(data.activityid || data.id, data.locationid || data.activitieslocationid,data.activitiescategory || data.category ,$event,data.locationname || data.activitieslocationname,data.activitiesaddressline1 || data.addressline1 ,data.campingName,data.merchantid || data.id)">
    <div class="px-2 mt-3 d-flex justify-content-between">
      <div style="width:65%;">
        <h2 class="mb-2 activity-type ">{{data.difficulty}}</h2>
        <div *ngIf="exploreData === 'homePage'">
          <div class="mt-1 d-flex ">
          </div>
        </div>
        <div *ngIf="exploreData === 'searchPage'">
          <div class="activity-title text-truncate  mt-3 fontColor"
            (click)="openActivitie(data.activityid, data.locationid,$event)"><img src="assets/location-b.svg"
              class="location-icon" alt="location"><span>{{data.locationname}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="(data.price == '0' || data.price == null || data.price == undefined) &&
      (data.min_price == '0' || data.min_price == null == undefined)" class="callback mt-3"
        (click)="$event.stopPropagation()">
        <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitiesaddressline1  || data.addressline1)"> Enquire</label>
      </div>
      <div *ngIf="(data.price != '0' && data.price != null && data.price != undefined) ||
      (data.min_price != '0' && data.min_price != null && data.min_price != undefined)" class="text-start">
        <span class="text-secondary">Starts From</span>
        <h2 class="mb-0 text-gray fw-bold">₹ {{data.price || data.min_price}}</h2>
        <span class="text-secondary">Per person</span>
      </div>
    </div>
  </div>
    <!-- <div class="px-2 mt-3 d-flex justify-content-between" *ngIf="data.activitiescategory === 'camping'">
      <div style="width: 65%;">
        <h2 class="mb-2 activity-type ">{{data.activitiesaddressline1}}</h2>
        <div class="activity-title text-truncate fw-bold mt-3"
          (click)="openMap($event, data.campingLatitude, data.campingLongitude)"
          style="color:#3E8DC6; cursor: pointer;"><img src="assets/location-b.svg" width="25px" class="location-icon"
            alt="location"> <span class="text-decoration "><u>{{data.distanceAway}}</u></span>
          <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
        </div>
      </div>
      <div *ngIf="data.min_price == '0' || data.min_price == null == undefined" class="callback mt-3"
        (click)="$event.stopPropagation()">
        <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitiesaddressline1)"> Enquire</label>
      </div>
      <div *ngIf="data.min_price != '0' && data.min_price != null && data.min_price != undefined" class="text-start">
        <span class="text-secondary mb-0">Starts From</span>
        <h2 class="mb-0 text-gray fw-bold">₹ {{ data.min_price}}</h2>
        <span class="text-secondary">Per Night</span>
      </div>
    </div> -->
    <div class=" border-0  h-100 px-2 mt-3" *ngIf="(data.activitiescategory || data.category) === 'trekking'"
      (click)="openTrekking(data.activityid || data.id, data.activitieslocationid,$event,data.locationname,data.activitiesaddressline1)">
      <div class=" d-flex justify-content-between">
        <div style="width:57%;">
          <h2 class="mb-2 activity-type ">{{data.difficulty}}</h2>
          <div *ngIf="exploreData === 'homePage'">
          <span class="d-flex text-truncate mt-4">
            <img src="../../assets/home/altitude.svg" width="20px" height="20px" alt="fire">
            <span class=" px-1 fw-bold data-font">{{data.totalaltitude}} </span>
            <img src="../../assets/home/dayicon.svg" alt="dayicon" width="20px" height="20px">
            <span class=" px-1 fw-bold text-truncate data-font"
              [matTooltip]="data.numberofdays + 'Days'">{{data.numberofdays}}Days</span>
          </span>
        </div>
          <div *ngIf="exploreData === 'searchPage'">
          <div class="activity-title text-truncate mt-3 "
            (click)="openTrekking(data.activityid, data.activitieslocationid,$event,data.slot_available)"><img
              src="assets/location-b.svg" class="location-icon" alt="location">
            <span>{{data.locationname}}</span>
          </div>
        </div>
        </div>
        <div *ngIf="data.min_price == '0' || data.min_price == null || !data.slot_available" class="callback mt-3"
          (click)="$event.stopPropagation()">
          <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitiesaddressline1  || data.addressline1)"> Enquire</label>
        </div>
        <div *ngIf="data.min_price != '0' && data.min_price != null && data.min_price != undefined && data.slot_available"
          class="text-start">
          <span class="text-secondary">Starts From</span>
          <h2 class="mb-0 text-gray fw-bold">₹ {{data.price || data.min_price}}</h2>
          <span class="text-secondary">Per person</span>
        </div>
      </div>
    </div>
    <div *ngIf="(data.activitiescategory || data.category) === 'tour'"
      (click)="openTour(data.activityid || data.id, data.activitieslocationid,$event,data.locationname,data.activitiesaddressline1)">
      <div class=" border-0  h-100  px-2 mt-3 d-flex justify-content-between">
        <div style="width:57%;">
          <h2 class="mb-2 activity-type ">{{data.difficulty}}</h2>
          <div *ngIf="exploreData === 'homePage'">
          <span class="d-flex text-truncate mt-4">
            <img src="../../assets/home/map-marker.svg" width="20px" height="20px" alt="altitude">
            <span class=" px-1 fw-bold data-font">{{data.totaldistance}} </span>
            <img src="../../assets/home/dayicon.svg" alt="dayicon" width="20px" height="20px">
            <span class="px-1 fw-bold text-truncate data-font"
              [matTooltip]="data.numberofdays + 'Days'">{{data.numberofdays}}Days</span>
          </span>
        </div>
        <div *ngIf="exploreData === 'searchPage'">
          <div class="activity-title text-truncate mt-3 "
            (click)="openTrekking(data.activityid, data.activitieslocationid,$event,data.slot_available)"><img
              src="assets/location-b.svg" class="location-icon" alt="location">
            <span>{{data.locationname}}</span>
          </div>
        </div>
        </div>
        <div *ngIf="data.min_price == '0' || data.min_price == null || !data.slot_available" class="callback mt-3"
          (click)="$event.stopPropagation()">
          <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitiesaddressline1  || data.addressline1)"> Enquire</label>
        </div>
        <div *ngIf="(data.price != '0' && data.price != null && data.price != undefined) ||
        (data.min_price != '0' && data.min_price != null && data.min_price != undefined && data.slot_available)"
          class="text-start mt-1">
          <span class="text-secondary">Starts From</span>
          <h2 class="mb-0 text-gray fw-bold mt-3">₹ {{data.price || data.min_price}}</h2>
        </div>
      </div>
    </div>
  
    <div class="mt-md-3 mt-4 px-2 d-flex justify-content-between" *ngIf="(data.activitiescategory  || categoryData ) === 'bikerental'" >
      <div>
        <h4 class="mb-1 fw-bold">Managed by</h4>
        <div class="activity-type text-truncate pointer mt-3  mb-2"
          (click)="openMerchantPage(data.locationid,data.merchantid || data.id,data.locationname,data.merchantname || data.name)">
          <img src="/assets/user-profile.svg" width="20px" class="location-icon" alt="profile">
          <span>{{data.merchantname || data.name}}</span>
          <img src="assets/gg_link.svg" width="20px" class="location-icon" alt="gglink">
        </div>
      </div>
      <div *ngIf="data.min_price == '0' || data.min_price == null || data.min_price == undefined" class="callback mt-3 pointer"
        (click)="$event.stopPropagation()">
        <label class="pointer px-sm-3" (click)="openEnquireDialog(data.activitytypemastername || data.name)">
          Enquire</label>
      </div>
      <div *ngIf="  (data.min_price !== '0' && data.min_price !== null  && data.min_price !== undefined)" class="text-start" (click)="selectBike(data.activityid,data.merchantid || data.id,data.locationid)">

        <span class="text-secondary">Starts From</span>
        <h2 class="mb-0 text-gray fw-bold price mt-3">₹{{ data.min_price || data.price}}</h2>
      </div>
    </div>
  </div>
  <div class="border-line m-auto w-100"></div>
