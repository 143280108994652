import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';
import { InfoComponent } from 'src/app/info/info.component';
import { environment } from 'src/environments/environment';


interface ExploreData {
  activitiesaddressline1: any;
  activitytypemastername: any;
  id: any;
  src: any;
  description: any;
  activitieslocationid: any;
  activitieslocationname: any;
  avgReview: any;
  hasCoupon: any;
  activitiescategory: any;
  total_star: any;
  activityid: any;
  locationid: any;
  min_price: any
  slot_available: any;
  difficulty: any;
  activitiesdescription: any;
  totalaltitude: any;
  numberofdays: any;
  locationname: any;
  campingName: any;
  totaldistance:any;
  merchantid:any;
  raftingkm	:any;
  addressline1:any;
  category: any;
  shopsrc:any;
  shopname:any
}
@Component({
  selector: 'app-explore-activitie-card',
  templateUrl: './explore-activitie-card.component.html',
  styleUrls: ['./explore-activitie-card.component.css']
})
export class ExploreActivitieCardComponent {
  locationId: any = null;
  isDragging = false;

  @Input() data: ExploreData;
  @Input()  exploreData :any;
  @Input()  categoryData:any;

  constructor(private router: Router, public dialog: MatDialog, public dataService: DataService, private snackBar: MatSnackBar, private http: HttpClient,) {
  }
  ngOnInit() {
    let location = localStorage.getItem('location');
    if (location != null && location != undefined && location != "") {
      this.locationId = JSON.parse(location).id;
    }
  }

  isLocationNameValid(name: string | null | undefined): boolean {
    return name != null && name.trim().length > 0;
  }

  openInfoDialog(locationid: any, event: any, description: any, id: string, datafrom: string,locationname:any,addressline1:any) {
    
    event.stopPropagation();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(InfoComponent, {
      ...sizes,
      disableClose: true,
      data: {
        locationid, id, description, datafrom,locationname,addressline1
      },
    });
  }

  openEnquireDialog(addressline: any) {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: addressline,
        activityType: "0",
      },
    });

  }
  openMerchantPage(locationid: any, id: any,city:any,name:any) {
    // this.router.navigate(['/merchant-profile', 'location', locationid, 'merchantid', id])
    city = city.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    name = name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',locationid,'merchantid',id,city,name])
  }
  openCamping(id: any, locationId: any, e: any, locationname: any, campingName: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    if (locationId != this.locationId) {
      this.http.get(environment.apiUrl + `/locations/get?id=${locationId}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          localStorage.setItem('location', JSON.stringify(data.location));
          this.dataService.changelocationSource(JSON.stringify(data.location));
          this.snackBar.open(`The location is changed to ${data.location.locationname}.`, 'close', { duration: 3000, verticalPosition: 'top' });
        }
      })
    }
    campingName = campingName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/location', locationId, 'camping-details', id, locationname, campingName])
  }

  openTrekking(id: any, locationId: any, e: any, locationname: any, activitiesaddressline1: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    if (locationId != this.locationId) {
      this.http.get(environment.apiUrl + `/locations/get?id=${locationId}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          localStorage.setItem('location', JSON.stringify(data.location));
          this.dataService.changelocationSource(JSON.stringify(data.location));
          this.snackBar.open(`The location is changed to ${data.location.locationname}.`, 'close', { duration: 3000, verticalPosition: 'top' });
        }
      })
    }
    activitiesaddressline1 = activitiesaddressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/location', locationId, 'trekking-detail', id, locationname, activitiesaddressline1])

  }

  openTour(id: any, locationId: any, e: any, locationname: any, activitiesaddressline1: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    if (locationId != this.locationId) {
      this.http.get(environment.apiUrl + `/locations/get?id=${locationId}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          localStorage.setItem('location', JSON.stringify(data.location));
          this.dataService.changelocationSource(JSON.stringify(data.location));
          this.snackBar.open(`The location is changed to ${data.location.locationname}.`, 'close', { duration: 3000, verticalPosition: 'top' });
        }
      })
    }
    activitiesaddressline1 = activitiesaddressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/location', locationId, 'package-tour-detail', id, locationname, activitiesaddressline1])
  }


  openActivitie(id: number, locationId: any, e: any, locationname: any, activitiesaddressline1: any) {
    if (!this.isDragging)
      if (locationId != this.locationId) {
        this.http.get(environment.apiUrl + `/locations/get?id=${locationId}`).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            localStorage.setItem('location', JSON.stringify(data.location));
            this.dataService.changelocationSource(JSON.stringify(data.location));
            this.snackBar.open(`The location is changed to ${data.location.locationname}.`, 'close', { duration: 3000, verticalPosition: 'top' });
          }
        })
      }
    activitiesaddressline1 = activitiesaddressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/location', locationId, 'activity-details', id, locationname, activitiesaddressline1])
  };

  selectBike(activityid:any, merchantid:any, locationid:any){
    this.router.navigate(['/selectbike'], {
      state: { dataToPass: {activityId:activityid, merchantId:merchantid, locationId:locationid} },
    });
  }

  openData(ID: number, locationId: any, datafrom: any, e: any,locationname: any, activitiesaddressline1: any, campingName: any,merchantid:any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }

    if (datafrom === 'rafting') {
      this.openActivitie(ID, locationId, e, locationname, activitiesaddressline1)
    }
    if (datafrom === 'activity') {
      this.openActivitie(ID, locationId, e, locationname, activitiesaddressline1)
    }
    if (datafrom === 'trekking') {
      this.openTrekking(ID, locationId, e,  locationname, activitiesaddressline1)

    }
    if (datafrom === 'tour') {
      this.openTour(ID, locationId, e,  locationname, activitiesaddressline1)
    }
    if (this.categoryData === 'bikerental') {
      this.selectBike(ID, merchantid,locationId)
    }
  }

  get fireRating() {
    return this.data.avgReview ?? this.data.total_star;
  }
}


