import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.css']
})
export class VerificationCodeComponent {
  verificationForm: FormGroup = new FormGroup({
    firstNum: new FormControl('', Validators.required),
    secondNum: new FormControl('', Validators.required),
    thirdNum: new FormControl('', Validators.required),
    fourNum: new FormControl('', Validators.required),
  });
  email: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public VerificationDialogRef: MatDialogRef<VerificationCodeComponent>, public dialog: MatDialog, private authService: AuthService, private snackBar: MatSnackBar, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    const inputs = document.querySelectorAll('input[type="number"]');
    inputs.forEach((input, index) => {
      input.addEventListener('input', (event: Event) => {
        const currentInput = event.target as HTMLInputElement;
        const maxLength = parseInt(currentInput.getAttribute('maxlength') || '1', 10);
        if (currentInput.value.length >= maxLength) {
          if (index < inputs.length - 1) {
            (inputs[index + 1] as HTMLInputElement).focus();
          }
        }
      });
    });
}

  closeLoginDialog() {
    this.VerificationDialogRef.close();
  }


  resendotp() {
      this.verificationForm.get('firstNum')?.setValue('');
      this.verificationForm.get('secondNum')?.setValue('');
      this.verificationForm.get('thirdNum')?.setValue('');
      this.verificationForm.get('fourNum')?.setValue('');
        this.http.post(environment.apiUrl + `/users/phoneSendCode`,{ phone:this.data }).subscribe((data:any)=>{
        this.snackBar.open('Verification Code has been sent to the phone number.', 'close', { duration: 3000, verticalPosition: 'top' });
        });
  }

  onSignIn() {
    if (this.verificationForm.valid) {
      const verificationCode = this.verificationForm.get('firstNum')?.value.toString() +
        this.verificationForm.get('secondNum')?.value.toString() +
        this.verificationForm.get('thirdNum')?.value.toString() + this.verificationForm.get('fourNum')?.value.toString();
      const requestbody = {
        phoneCode: verificationCode,
        phone: this.data,
      }
      this.http.post(environment.apiUrl + `/users/phoneValidateCode`, requestbody).pipe(
        catchError((_err: HttpErrorResponse)=> {
          this.snackBar.open('Invalid Verification Code', 'close', { duration: 3000, verticalPosition: 'top' });
          throw new Error("Error");
        })
      ).subscribe((data: any) => {
        localStorage.setItem('accessToken', data.token);
        let user: any = this.authService.decodeToken(data.token);
        this.authService.userInfo.next(user);
        this.authService.accessToken.next(data.token);
        this.authService.userName.next(user?.firstname);
        this.authService.userInfo.next(user);
        localStorage.setItem('userName', user?.firstname);
        localStorage.setItem('UserType',"user");
        this.snackBar.open('Submitted Successfully', 'close', { duration: 3000, verticalPosition: 'top' });
        this.closeLoginDialog();
        window.location.href = '/index.html';
      });
    } else {
      this.snackBar.open('Invalid Verification Code', 'close', { duration: 3000, verticalPosition: 'top' });
    }
  
  }






}







