<div (click)="openActivitie(data.locationid,data.id, $event,data.category,data.addressline1,data.locationname, data.activitieslocationname,data.activitiesaddressline1 )">
  <div class=" border-0 pointer h-100 px-2">
    <div class="card-body popularspots py-3">
     
      <div class="d-flex justify-content-between" *ngIf="(data.category === 'trekking')|| (data.category === 'tour')">
        <div style="width: calc(100% - 39%);">
          <h1 class="default-font-family title  text-truncate mb-2 mt-1 ms-md-0 ms-2" >
            {{data.addressline1}}</h1>
        </div>
        <div class="d-flex gif mb-2">
          <!-- <img class="" src="/assets/thrill-factor.gif" width="30px" height="30px" alt="thrillFactor">
            <h3 class="mb-0   mt-2 fw-bold">{{data.ratingOf}}</h3> -->
          <div class="mt-1 d-flex">
            <ng-template #t let-fill="fill">
              <app-svg-icon [fill]="fill === 100 ? '#FF7A00' : 'gray'" class="" icon="fireIconRating"></app-svg-icon>
            </ng-template>
            <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [rate]="fireRating" class="mt-1"></ngb-rating>
          </div>
        </div>
      </div>
      <div *ngIf="(data.category ==='activity')|| (data.category ==='rafting')">
        <h1 class="title text-truncate mb-2">{{data.addressline1}}</h1>
        <div *ngIf="data.category === 'rafting'">
          <div class="card-img-div  position-relative" [ngStyle]="{'background-image':'url(' + data.src[0] + ')'}">
            <img class="position-absolute info"
              (click)="openInfoDialog(data.locationid,$event, data.description, data.id, 'rafting',data.locationname, data.addressline1)"
              src="../../assets/home/info.png" alt="info">
            <div *ngIf="data.hasCoupon">
              <div class="position-absolute offer ">
                <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="data.category === 'activity'">
          <div class="card-img-div position-relative">
            <ngb-carousel *ngIf="data.src" class="h-100">
              <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
                <div class="wrapper">
                  <img [src]="src" alt="image" class="imageSrc">
                </div>
              </ng-template>
            </ngb-carousel>
            <img class="position-absolute info"
              (click)="openInfoDialog(data.locationid,$event, data.description, data.id, 'activity',data.locationname, data.addressline1)"
              src="../../assets/home/info.png" alt="info">
            <div *ngIf="data.hasCoupon">
              <div class="position-absolute offer ">
                <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="me-3 ms-2 mt-3 d-flex justify-content-between"
        *ngIf="(data.category ==='activity')|| (data.category ==='rafting')">
        <div>
          <h2 class="mb-2 default-font-family title" *ngIf="data.category ==='activity'"> {{ data.difficulty}}</h2>
          <h2 class="mb-2 activity-type" *ngIf="data.raftingkm && data.raftingkm =='9 Km'">Easy</h2>
          <h2 class="mb-2 activity-type" *ngIf="data.raftingkm && data.raftingkm =='16 Km'">Easy Medium
          </h2>
          <h2 class="mb-2 activity-type" *ngIf="data.raftingkm && data.raftingkm =='26 Km'">Medium</h2>
          <h2 class="mb-2 activity-type" *ngIf="data.raftingkm && data.raftingkm =='35 Km'">Hard</h2>
          <!-- <div class="d-flex">
              <img src="/assets/thrill-factor.gif" width="30px" height="30px" alt="fire">
              <h4 class="mb-0 activity-type mt-2">{{data.rating}}</h4>
            </div> -->
          <div class="mt-1 d-flex ">
            <ng-template #t let-fill="fill">
              <app-svg-icon [fill]="fill === 100 ? '#FF7A00' : 'gray'" class="" icon="fireIconRating"></app-svg-icon>
            </ng-template>
            <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [(rate)]="fireRating"
              class="raft-rating"></ngb-rating>
          </div>
        </div>
        <div *ngIf=" data.min_price == '0' || data.min_price == null || data.min_price == undefined" class="callback"
          (click)="$event.stopPropagation()">
          <label class="pointer px-sm-3" (click)="openEnquireDialog(data.addressline1)"> Enquire</label>
        </div>
        <div *ngIf=" data.min_price != '0' &&  data.min_price != null && data.min_price != undefined"
          class="text-start">
          <span class="text-secondary">Starts From</span>
          <h2 class="mb-0 text-gray fw-bold">₹{{data.min_price}}</h2>
          <span class="text-secondary">Per person</span>
        </div>

        <!-- <div *ngIf="(data.category === 'trekking')|| (data.category === 'tour')">
          <div *ngIf=" data.min_price != '0' &&  data.min_price != null && data.min_price != undefined && data.slot_available" class="text-start">
            <span class="text-secondary">Starts From</span>
            <h2 class="mb-0 text-gray fw-bold">₹{{data.min_price}}</h2>
            <span class="text-secondary" *ngIf="data.category === 'trekking'">Per person</span>
            </div>
          </div> -->
      </div>
      <div *ngIf="data.category === 'trekking'">
        <div class="position-relative ">
          <ngb-carousel *ngIf="data.src" class="carousel-height ">
            <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
              <div class="card-img-div position-relative d-flex">
                <img class="w-100 activity-img " [src]="src" alt="image">
              </div>
            </ng-template>
          </ngb-carousel>
          <img class="position-absolute info"
            (click)="openInfoDialog(data.locationid,$event, data.description, data.id, 'trekking',data.locationname, data.addressline1)"
            src="../../assets/home/info.png" alt="info">
          <div *ngIf="data.hasCoupon">
            <div class="position-absolute offer " style="left: 0;">
              <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
            </div>
          </div>
        </div>
        <div class=" border-0 pointer h-100 px-2 mt-3">
          <div class="card-body ">
            <div class=" d-flex justify-content-between">
              <div style="width:57%;">
                <h2 class="mb-2 activity-type ">{{data.difficulty}}</h2>
                <span class="d-flex text-truncate">
                  <img src="../../assets/home/altitude.svg" width="30px" height="30px" alt="fire">
                  <span class="mt-2 px-1 fw-bold data-font">{{data.totalaltitude}} </span>
                  <img src="../../assets/home/dayicon.svg" alt="dayicon">
                  <span class="mt-2 px-1 fw-bold text-truncate data-font"
                    [matTooltip]="data.numberofdays + 'Days'">{{data.numberofdays}}Days</span>
                </span>
              </div>
              <div *ngIf=" data.min_price == '0' || data.min_price == null || !data.slot_available"
                class="callback mt-3" (click)="$event.stopPropagation()">
                <label class="pointer px-sm-3" (click)="openEnquireDialog(data.addressline1)"> Enquire</label>
              </div>
              <div
                *ngIf=" data.min_price != '0' &&  data.min_price != null && data.min_price != undefined && data.slot_available"
                class="text-start">
                <span class="text-secondary">Starts From</span>
                <h2 class="mb-0 text-gray fw-bold">₹{{data.min_price}}</h2>
                <span class="text-secondary">Per person</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="data.category === 'tour'">
        <div class="position-relative">
          <ngb-carousel *ngIf="data.src" class="carousel-height">
            <ng-template *ngFor="let src of data.src" ngbSlide (click)="$event.stopPropagation()">
              <div class="card-img-div position-relative d-flex">
                <img class="w-100 activity-img " [src]="src" alt="image">
              </div>
            </ng-template>
          </ngb-carousel>
          <img class="position-absolute info"
            (click)="openInfoDialog(data.locationid,$event, data.description, data.id, 'tour',data.locationname, data.addressline1)"
            src="../../assets/home/info.png" alt="info">
          <div *ngIf="data.hasCoupon">
            <div class="position-absolute offer " style="left: 0;">
              <label class="pointer px-2 offer-text">OFFER AVAILABLE</label>
            </div>
          </div>
        </div>
        <div class=" border-0 pointer h-100 px-2 mt-3">
          <div class="card-body ">
            <div class="d-flex justify-content-between">
              <div style="width: 57%;">
                <h2 class="mb-2 activity-type">{{ data.difficulty }}</h2>
                <span class="d-flex text-truncate">
                  <img src="../../assets/home/map-marker.svg" alt="Map Marker Icon">
                  <span class="mt-2 px-1 fw-bold data-font">{{ data.totaldistance }} </span>
                  <img src="../../assets/home/dayicon.svg" alt="Day Icon">
                  <span class="mt-2 px-1 fw-bold data-font text-truncate" [matTooltip]="data.numberofdays + 'Days'">{{
                    data.numberofdays }} Days</span>
                </span>
              </div>
              <div *ngIf=" data.min_price == '0' || data.min_price == null || !data.slot_available"
                class="callback mt-3" (click)="$event.stopPropagation()">
                <label class="pointer px-sm-3" (click)="openEnquireDialog(data.addressline1)"> Enquire</label>
              </div>
              <div
                *ngIf=" data.min_price != '0' &&  data.min_price != null && data.min_price != undefined && data.slot_available"
                class="text-srart">
                <span class="text-secondary">Starts From</span>
                <h2 class="mb-0 text-gray fw-bold mt-3">₹ {{data.min_price}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <!-- <button class="btn book-now w-100 text-white">Explore</button> -->
    </div>
  </div>

</div>