import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent {
  newpasswordForm!: FormGroup;
  isSubmitting: boolean = false;
  type: string = "";
  userId:string="";
   passwordVisible: boolean = false;
   passwordNewVisible: boolean = false;
   confirmPasswordVisible: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService, private http: HttpClient, public dialog: MatDialog, public newPasswordDialogRef: MatDialogRef<NewPasswordComponent>, private formBuilder: FormBuilder) {
    this.type = data.userType;
    this.userId = data.userId;
  }

 
ngOnInit() {
  this.newpasswordForm = this.formBuilder.group({
    currentpassword: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirmpassword: new FormControl('', [Validators.required, this.passwordMatchValidator.bind(this)])
  });
}

passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const password = this.newpasswordForm?.get('password')?.value;
  const confirmPassword = control.value;
  if (password !== confirmPassword) {
    return { passwordMismatch: true };
  }

  return null;
}

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  
  toggleNewPasswordVisibility() {
    this.passwordNewVisible = !this.passwordNewVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }

  closeForgetPasswordDialog() {
    this.newPasswordDialogRef.close();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.newpasswordForm.controls;
  }

  onSubmit() {
    this.isSubmitting = true;
    if (this.newpasswordForm.invalid) {
      return;
    }
    if (this.newpasswordForm.valid) {
      let sizes = {
        height: 'fit-content',
        width: 'fit-content'
      };
      if (this.type === "user") {
        this.http.post(`${environment.apiUrl}/users/changepassword`, { id: this.userId, currentPassword:this.newpasswordForm.value.currentpassword,newPassword:this.newpasswordForm.value.password }).pipe(
          catchError((_err: HttpErrorResponse) => {
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title: 'Some Error occurs',
                message: JSON.stringify(_err.error.errors),
                status: 'danger'
              },
            })
            throw new Error("Error");
          })
        ).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            localStorage.setItem('accessToken', data.token);
            let user: any = this.authService.decodeToken(data.token);
            this.authService.userInfo.next(user);
            this.authService.accessToken.next(data.token);
            this.authService.userName.next(user?.firstname);
            this.authService.userInfo.next(user);
            localStorage.setItem('userName', user?.firstname);
            localStorage.setItem('UserType',"user");
            this.newPasswordDialogRef.close();
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title: 'Change Password',
                message: `${data.message}`,
                status: 'success'
              },
            });
          }
        });
      } else if (this.type === "merchant") {
        this.http.post(`${environment.apiUrl}/merchants/changepassword`,  { id: this.userId, currentPassword:this.newpasswordForm.value.currentpassword,newPassword:this.newpasswordForm.value.password }).pipe(
          catchError((_err: HttpErrorResponse) => {
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title: 'Some Error occurs',
                message: JSON.stringify(_err.error.errors),
                status: 'danger'
              },
            })
            throw new Error("Error");
          })
        ).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            localStorage.setItem('accessToken', data.token);
            let user: any = this.authService.decodeToken(data.token);
            this.authService.userInfo.next(user);
            this.authService.accessToken.next(data.token);
            this.authService.userName.next(user?.name);
            this.authService.userInfo.next(user);
            localStorage.setItem('userName', user?.name);
            localStorage.setItem('UserType',"merchant");
            this.newPasswordDialogRef.close();
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title: 'Password Changed',
                message: `${data.message}`,
                status: 'success'
              },
            });
          }
        });
      } else if (this.type === "partner") {
        this.http.post(`${environment.apiUrl}/partners/changepassword`,  { id: this.userId, currentPassword:this.newpasswordForm.value.currentpassword,newPassword:this.newpasswordForm.value.password }).pipe(
          catchError((_err: HttpErrorResponse) => {
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title: 'Some Error occurs',
                message: JSON.stringify(_err.error.errors),
                status: 'danger'
              },
            })
            throw new Error("Error");
          })
        ).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            localStorage.setItem('accessToken', data.token);
            let user: any = this.authService.decodeToken(data.token);
            this.authService.userInfo.next(user);
            this.authService.accessToken.next(data.token);
            this.authService.userName.next(user?.name);
            this.authService.userInfo.next(user);
            localStorage.setItem('userName', user?.name);
            localStorage.setItem('UserType',"partner");
            this.newPasswordDialogRef.close();
            this.dialog.open(ModalAlertComponent, {
              ...sizes,
              disableClose: true,
              data: {
                title: 'Password Changed',
                message: `${data.message}`,
                status: 'success'
              },
            });
          }
        });
      }
    }
  }

}
