import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';


interface MerchantData {
  id: any;
  locationid: any;
  shopname: any;
  shopsrc: any;
  src: any;
  name: any;
  averagestar: any;
  hasCoupon: any;
  rating:any;
  city:any;
  locationname:any;
}

@Component({
  selector: 'app-merchant-profile-card',
  templateUrl: './merchant-profile-card.component.html',
  styleUrls: ['./merchant-profile-card.component.css']
})
export class MerchantProfileCardComponent {
  @Input() data: MerchantData;

  constructor(private router:Router) { 
  }

  openMerchant(locationid:any, id:any,locationname:any,name:any){
    locationname = locationname.trimEnd().replace(/\s+/g, '-').toLowerCase();
    name = name.trimEnd().replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',locationid,'merchantid',id,locationname,name])
  }
}
