
<div mat-dialog-content class="overflow-auto">
<div class="d-flex align-items-center pb-3 ">
  <button type="button" class="btn-close mt-2 ms-2" (click)="closeLoginDialog()" aria-label="Close"></button>
  <h1 mat-dialog-title id="form-login" class="text-center signup mb-0 pb-0  d-none d-md-block heading">Sign Up</h1>
</div>
<div class="logo d-block d-md-none">
  <img src="/assets/new-logo.svg" alt="Logo">
</div>
  <form class="h-100" (submit)="onSignIn()" [formGroup]="SignInForm">
    <div class="row mb-0 mt-3">
      <div class="col">
        <div class="form-outline ">
          <input type="text" id="form3Example1" class="form-control inputbox" formControlName="firstName"
            placeholder="First name"   [ngClass]="{ 'is-invalid': isSubmitting && f['firstName'].errors }" />
            <div *ngIf="isSubmitting && f['firstName'].errors" class="invalid-feedback" style="margin-left: 15.5%;">
              <div *ngIf="f['firstName'].errors['required']">
                First name is required
              </div>
            </div>
        </div>
      </div>
      <div class="col">
        <div class="form-outline ">
          <input type="text" id="form3Example2" class="form-control inputbox1" formControlName="lastName"
            placeholder="Last name"   [ngClass]="{ 'is-invalid': isSubmitting && f['lastName'].errors }"/>
            <div *ngIf="isSubmitting && f['lastName'].errors" class="invalid-feedback">
              <div *ngIf="f['lastName'].errors['required']">
                last name is required
              </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Email input -->
    <div class="form-outline mt-3 ">
      <input type="email" id="form3Example3" class="form-control inputbox2" formControlName="email"
        placeholder="Email address" [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }" />
        <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']">Email is required</div>
          <div *ngIf="f['email'].errors['email']">Email is invalid</div>
        </div>

    </div>
    <!-- phone number -->
    <div class="form-outline mt-3 ">
      <input type="text" id="form3Example4" class="form-control inputbox2" formControlName="phone" [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }"
        placeholder="Phone Number" maxlength="10" oninput="this.value = this.value.replace(/[^0-9 ]/,'')" />
        <div *ngIf="isSubmitting && f['phone'].errors" class="invalid-feedback">
          <div *ngIf="f['phone'].errors['required']">Phone Number is required</div>
          <div *ngIf="f['phone'].errors['invalidPhoneNumber']">Invalid Phone number.</div>
        </div>
    </div>
    <!-- Password input -->
    <div class="form-outline mt-3 position-relative">
      <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
        {{ passwordVisible ? 'visibility' : 'visibility_off ' }}
      </mat-icon>
      <input type="{{ passwordVisible ? 'text' : 'password' }}"
        style="border-radius:10px; width: 85%; height: 2.5rem; margin: auto;background-color: #F1F5FE;" id="form2Example2"
        formControlName="password" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }" />
        <div *ngIf="isSubmitting && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors['required']">Password is required</div>
        </div>
    </div>

    <!-- <div class="d-flex mx-auto mt-2  ">
      <hr class="" width="49.5%"><span class="mt-2">or</span>
      <hr class="" width="49.5%">
    </div>

    <div class="d-flex fb mb- justify-content-center"> <a href="#!"><img width="40px" class="ms-2 me-2 "
          src="../../../assets/fb.png"></a> <a href="#!"><img width="40px" class="ms-3 me-2 "
          src="../../../assets/google.svg"></a> <a href="#!"><img width="40px" class="ms-3 me-2 "
          src="../../assets/iphone.png"></a>
    </div> -->

    <div class=" text-center mt-2 ">
      <a><button type="submit" class="btn create-btn btn-block ms-3 mt-2 text-light text-center">Create
          Account</button></a>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <hr class="hr-or">
      <span class="mx-2 text-dark">or</span>
      <hr class="hr-or">
    </div>
      <div *ngIf="!ifMobileApp()" class="g_id_signin mt-3 mx-auto" style="width: fit-content;" id="g_id_onload" data-type="standard" data-text="signingoogle" data-shape="" data-client_id="189004330207-8ubso558a7ns63388uhgnl9fjjq1621b.apps.googleusercontent.com" data-callback="handleCredentialResponse"></div>
      <!-- <div class="mt-2 mx-auto border rounded pointer p-1 d-flex align-items-center"*ngIf="ifMobileApp()  && !ifIosApp()" (click)="loginGoogle()" style="width: fit-content;">
        <img src="/assets/google.svg" class="ms-2" width="30px"  />
       <span class="px-2" style="font-size: 14px;">Sign in with Google</span>
      </div> -->
      <div class="mt-2 mx-auto border rounded pointer p-1 d-flex align-items-center" *ngIf="ifMobileApp() && ifIosApp()" (click)="loginMobileApple()" style="width: fit-content;">
        <img src="/assets/apple.svg" class="ms-2" width="30px"  />
       <span class="px-2" style="font-size: 14px;" alt="appleIcon">Sign in with Apple</span>
      </div>
      <div class="mt-2 mx-auto border rounded pointer p-1 d-flex align-items-center bg-white" *ngIf="!ifMobileApp()" (click)="clickWebLoginApple()" style="width: fit-content;">
        <img src="/assets/apple.svg" class="ms-2" width="30px"  />
       <span class="px-2" style="font-size: 14px;" alt="appleIcon">Sign in with Apple</span>
      </div>
    <div class="my-3">
      <p class="skip-login"><span class="text-light">By creating account, you agree to our </span> <a href="/terms-condition" class="text-decoration-none pointer text-dark"> Terms of service</a><span class="text-light me-2 "> and </span><a href="/privacy-policy" class="text-decoration-none pointer text-dark"> Privacy policy</a></p>
      <p class="skip-login"><span class="text-light me-2 ">Already have an account?</span> <a class="text-decoration-none pointer text-dark" (click)="openLoginDialog()">Login</a></p>
    </div>
  </form>
</div>