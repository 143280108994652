import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl, } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { AuthService } from '../auth.service';
import { MerchantLoginDialogueComponent } from '../merchant-login-dialogue/merchant-login-dialogue.component';
import { VerificationCodeComponent } from 'src/app/verification-code/verification-code.component';
import { ForgetPasswordPopupComponent } from 'src/app/forget-password-popup/forget-password-popup.component';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import Swal from 'sweetalert2';
import { PartnerLoginDialogueComponent } from '../partner-login-dialogue/partner-login-dialogue.component';

declare var AppleID: {
  auth: {
    init: (arg0: {
      clientId: string; scope: string; redirectURI: string; state: string; nonce: string; usePopup: boolean; //or false defaults to false
    }) => void; signIn: () => any;
  };
};

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent {
  public passwordVisible: boolean = false;
  isLoggedIn: boolean = false;
  isSubmitting: boolean = false;
  loginForm!: FormGroup;
  userAgent: string = "";
  mobileDeviceReady: boolean = false;
  isMobile: boolean = false;
  hostName:any="";


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private route: ActivatedRoute, private socialAuthService: SocialAuthService, public loginDialogRef: MatDialogRef<LoginDialogComponent>, public dialog: MatDialog, private authService: AuthService, private snackBar: MatSnackBar, private router: Router) {
    this.hostName = window.location.host;
    var raftingDetails = localStorage.getItem('raftingDetails');
    var campingDetails = localStorage.getItem('campingDetails');
    var rentalDetails = localStorage.getItem('rentalDetails');
    var locationDetails = localStorage.getItem('location');
    var linkFrom = localStorage.getItem('linkFrom');
    var appToken = localStorage.getItem('appToken');
    var trekkingDetails = localStorage.getItem('trekkingDetails');
    var tourDetails = localStorage.getItem('tourDetails');
    localStorage.clear();
    if(raftingDetails && raftingDetails != ""){
      localStorage.setItem('raftingDetails',raftingDetails);
      }
    if(campingDetails && campingDetails != ""){
    localStorage.setItem('campingDetails',campingDetails);
    }
    if(locationDetails && locationDetails != ""){
      localStorage.setItem('location',locationDetails);
    }
    if(rentalDetails && rentalDetails != ""){
      localStorage.setItem('rentalDetails',rentalDetails);
    }
    if(linkFrom && linkFrom != ""){
      localStorage.setItem('linkFrom',linkFrom);
    }
    if(trekkingDetails && trekkingDetails != ""){
      localStorage.setItem('trekkingDetails',trekkingDetails);
    }
    if(tourDetails && tourDetails != ""){
      localStorage.setItem('tourDetails',tourDetails);
    }
    localStorage.setItem('appToken', appToken);
  }

  ngOnInit(): void {
    document.addEventListener('deviceready', () => {
      this.mobileDeviceReady = true;
    }, false);

    this.userAgent = window.navigator.userAgent;
    this.loginForm = this.formBuilder.group({ email: new FormControl('', Validators.required), password: new FormControl('', Validators.required) });
    if (!this.ifMobileApp()) {
      this.socialAuthService.authState.subscribe((user) => {
        if (user.provider === 'GOOGLE') {
          this.authService.validateGoogleUserSignIn({ idToken: user.idToken }).subscribe({
            next: (res) => {
              this.loginDialogRef.close();
              const queryString = window.location.search;
              const searchParams = new URLSearchParams(queryString);
              const queryParams:any = Object.fromEntries(searchParams.entries());
              let currentUrl = this.router.url.split('?')[0];
              if (queryParams && queryParams.dialogue) {
                queryParams.dialogue = '0';
              }
              this.router.navigate([currentUrl], { queryParams: queryParams });
              this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
            },
            error: (err) => {
              this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          })
        }
      });

      (window as any).handleCredentialResponse = (response: any) => {
        if (response && response.credential) {
          this.authService.validateGoogleUserSignIn({ idToken: response.credential }).subscribe({
            next: (res) => {
              this.loginDialogRef.close();
              const queryString = window.location.search;
              const searchParams = new URLSearchParams(queryString);
              const queryParams:any = Object.fromEntries(searchParams.entries());
              let currentUrl = this.router.url.split('?')[0];
              if (queryParams && queryParams.dialogue) {
                queryParams.dialogue = '0';
              }
              this.router.navigate([currentUrl], { queryParams: queryParams });
              this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
            },
            error: (err) => {
              this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          })
        }
      }
    } else {
      this.isMobile = true;
    }
  }

  loginGoogle() {
    if (this.isMobile) {
      (window as any).plugins.googleplus.login(
        {
          // 'scopes': '... ', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
          'webClientId': '189004330207-8ubso558a7ns63388uhgnl9fjjq1621b.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
          // 'offline': true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
        },
        (obj: any) => {
          this.authService.validateGoogleUserSignIn({ mobileidToken: obj.idToken }).subscribe({
            next: (res) => {
              this.loginDialogRef.close();
              const queryString = window.location.search;
              const searchParams = new URLSearchParams(queryString);
              const queryParams:any = Object.fromEntries(searchParams.entries());
              let currentUrl = this.router.url.split('?')[0];
              if (queryParams && queryParams.dialogue) {
                queryParams.dialogue = '0';
              }
              this.router.navigate([currentUrl], { queryParams: queryParams });
              this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
            },
            error: (err) => {
              this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          })
        },
        (msg: string) => {
          this.snackBar.open('Some error occurs.' + msg, 'close', { duration: 3000, verticalPosition: 'top' },);
        }
      );
    }
  }

  loginMobileApple() {
    if (this.isMobile) {
      (window as any).cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        (data: any) => {
          const decodedToken = this.parseJwt(data.identityToken);
          if (decodedToken.is_private_email) {
            this.snackBar.open("Your Email Id is private. Please don't hide the email.", 'close', { duration: 3000, verticalPosition: 'top' },);
          } else {
            let requestData = {};
            if (data.fullName.givenName != "") {
              requestData = {
                "email": decodedToken.email,
                "firstName": data.fullName.givenName,
                "lastName": data.fullName.familyName
              };

            } else {
              requestData = {
                "email": decodedToken.email,
              };
            }
            this.authService.validateWebAppleLogin(requestData).subscribe({
              next: (res) => {
                this.loginDialogRef.close();
                const queryString = window.location.search;
                const searchParams = new URLSearchParams(queryString);
                const queryParams:any = Object.fromEntries(searchParams.entries());
                let currentUrl = this.router.url.split('?')[0];
                if (queryParams && queryParams.dialogue) {
                  queryParams.dialogue = '0';
                }
                this.router.navigate([currentUrl], { queryParams: queryParams });
                this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
              },
              error: (err) => {
                this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
              }
            })
          }
        },
        (err: any) => {
          this.snackBar.open('Some error occurs.' + JSON.stringify(err), 'close', { duration: 3000, verticalPosition: 'top' },);
        }
      )

    }
  }

  ifMobileApp() {
    if (window.navigator.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }
  ifIosApp() {
    if ((window as any).cordova.platformId === "ios") {
      return true;
    }
    return false;
  }

  openSignInDialog() {
    if (!this.ifMobileApp()) {
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const queryParams = Object.fromEntries(searchParams.entries());
      const currentUrl = this.router.url.split('?')[0];
      let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
      q = q !== '' ? q + '&dialogue=signupuser' : q + `dialogue=signupuser`
      window.location.href = `${currentUrl}?${q}`;
    } else {
      this.loginDialogRef.close();
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '90vh',
        width: '50%',
        maxWidth: 'unset',
        panelClass: 'bg-sign-up-class'
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          panelClass: 'bg-sign-up-class'
        }
      }
      this.dialog.open(SignUpComponent, {
        ...sizes,
        disableClose: true,
        data: {

        },
      });
    }
  }

  openForgetPasswordDialog() {
    this.loginDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(ForgetPasswordPopupComponent, {
      ...sizes,
      disableClose: true,
      data: {
        type: "user"
      },
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  closeLoginDialog() {
    this.loginDialogRef.close();
  }


  onLogin() {
    this.isSubmitting = true;
    if (this.loginForm.invalid) {
      return;
    }
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe({
        next: (res) => {
          this.isSubmitting = false;
          this.loginDialogRef.close();
          this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
          const queryString = window.location.search;
          const searchParams = new URLSearchParams(queryString);
          const queryParams:any = Object.fromEntries(searchParams.entries());
          let currentUrl = this.router.url.split('?')[0];
          if (queryParams && queryParams.dialogue) {
            queryParams.dialogue = '0';
          }
          this.router.navigate([currentUrl], { queryParams: queryParams });
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.error.errors.email !== undefined && err.error.errors.email[0] === "Email not verified, Please Verify Email") {
            this.loginDialogRef.close();
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);
            const queryParams:any = Object.fromEntries(searchParams.entries());
            let currentUrl = this.router.url.split('?')[0];
            if (queryParams && queryParams.dialogue) {
              queryParams.dialogue = '0';
            }
            this.router.navigate([currentUrl], { queryParams: queryParams });
            this.dialog.open(VerificationCodeComponent, {
              height: 'fit-content',
              width: 'fit-content',
              disableClose: true,
              data: this.loginForm.value.email

            });
          } else {
            this.snackBar.open('Username or Password is Incorrect ', 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        }
      });
    } else {

    }

  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  openMerchantLoginDialogue() {
    this.loginDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-merchant-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-merchant-login-class'
      }
    }
    this.dialog.open(MerchantLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  clickWebLoginApple() {
    Swal.fire({
      title: '',
      text: 'To ensure smooth operation of the application, it is recommended not to obfuscate or hide the email during the login process',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.apple()
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {

      // }
    })
  }
  async apple() {
    try {
      AppleID.auth.init({
        clientId: 'com.advenzone.service',
        scope: 'name email',
        redirectURI: 'https://'+this.hostName+'/applecallback',
        state: 'init',
        nonce: 'test',
        usePopup: true //or false defaults to false
      });
      const data = await AppleID.auth.signIn();
      const decodedToken = this.parseJwt(data.authorization.id_token);
      if (decodedToken.is_private_email) {
        this.snackBar.open("Your Email Id is private. Please don't hide the email.", 'close', { duration: 3000, verticalPosition: 'top' },);
      } else {
        let requestData = {};
        if (data.user) {
          const userName = data.user;
          requestData = {
            "email": decodedToken.email,
            "firstName": userName.name.firstName,
            "lastName": userName.name.lastName,
            "socialId": decodedToken.sub,
          };

        } else {
          requestData = {
            "email": decodedToken.email,
          };
        }
        this.authService.validateWebAppleLogin(requestData).subscribe({
          next: (res) => {
            this.loginDialogRef.close();
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);
            const queryParams:any = Object.fromEntries(searchParams.entries());
            let currentUrl = this.router.url.split('?')[0];
            if (queryParams && queryParams.dialogue) {
              queryParams.dialogue = '0';
            }
            this.router.navigate([currentUrl], { queryParams: queryParams });
            this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
          },
          error: (err) => {
            this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        })
      }

    } catch (error) {
      console.log('Some error occurs.' + JSON.stringify(error))
    }

  }

  loginPartner(){
    this.loginDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '400px',
      width: '50%',
      maxWidth: 'unset',
      panelClass: 'bg-partner-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-partner-class'
      }
    }
    this.dialog.open(PartnerLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {
  
      },
    });
  }

  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
}
